import React, { Component } from "react";
import { connect } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import { Button, Col, Container, Input, Modal, Row, Alert } from "reactstrap";
import i18n from "../../../i18next";

import {
    openStartOrderScreen, closeStartOrderScreen, saveBrandIdToStore, clearWizardData, clearState, clearMessageAlert, getOrderTypes, clearCurrentPathAfterUse,
    clearLastUsedPagePathAfterTimeStamp, clearShoppingCartState, allowTimeWarningPopup, saveFirstStamp, saveNavigationType, changeAppleGooglePayAvailability, comoMemberStartOrder
} from '../../../actions/index';
import PhoneNumberScreen from '../../login/PhoneNumberScreen';
import SmsConfirmationScreen from '../../login/SmsConfirmationScreen';
import UserCredentialsScreen from '../../login/UserCredentialsScreen';
import SelectOrderTypeScreen from "./SelectOrderTypeScreen";
import UserAddressScreen from './UserAddressScreen';
import ConfirmAddressScreen from './ConfirmAddressScreen';
import RestaurantSelectionScreen from './RestaurantSelectionScreen';
import SavedAddressesScreen from "./SavedAddressesScreen";
import OrderTimeScreen from './OrderTimeScreen';
import CarDetailsScreen from './CarDetailsScreen';
import { updateCSSVariables } from '../../../utils/ThemesSelector';

import { getQueryInfoFromUrl } from '../../../utils/CommonUtils';
import ForgottenEmailScreen from "../../login/ForgottenEmailScreen";

class StartOrderScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            brandId: null,
            isStartScreenOpened: false
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.props.allowTimeWarningPopup(false);
        let parsedNavTypeValue = parseInt(getQueryInfoFromUrl().navigationType);
        // let parsedNavTypeValue = parseInt(localStorage.getItem('navigationType'));
        this.props.saveNavigationType(parsedNavTypeValue);
        const authipayRefreshFlag = this.props.paymentProvider.authipayData && this.props.paymentProvider.authipayData.orderId;
        const totalWithGiftCardIsZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total === 0 : false;
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false; 

        //clear apple/google pay state
        if (!totalWithGiftCardIsZero && !isAppliedGiftCards){
            this.props.changeAppleGooglePayAvailability(null);
        } 

        // Avoid potential Brand ID mixing issues
        const urlParams = new URLSearchParams(window.location.search);
        const brandId = urlParams.get('brandId');

        const localStorageBrandID = this.props.storeLocalStorage.brandId;
        // console.log('SOS brandId ', brandId, ' localStorageBrandID ', localStorageBrandID);
        if (brandId && brandId !== localStorageBrandID) {
            this.props.clearState();
            this.props.clearCurrentPathAfterUse();
        }

        if (!this.props.pwaAppRunning) {
            this.props.clearLastUsedPagePathAfterTimeStamp();
        }

        if (this.props.temporaryOrderTime.screenBeforeRefresh && !this.props.pwaAppRunning && parsedNavTypeValue !== 0) {
            let timePeriod = 60 * 60 * 3 * 1000;
            let currentMoment = Date.now();
            let sum = parseInt(this.props.storeLocalStorage.firstStamp) + timePeriod;
            // console.log('currentMoment ', currentMoment, ' sum ', sum, ' diff ', currentMoment <= sum);
            // if current time is after the time we save the values delete ShoppingCart items + redirect path to MenuScreen
            if (sum <= currentMoment) {
                // console.log('inside ');
                this.props.clearShoppingCartState();
                this.props.clearCurrentPathAfterUse();
                this.props.saveFirstStamp(currentMoment);
                // A MUST to return to the screen refer refresh
                this.startOrder(brandId);
            } else if (authipayRefreshFlag) {
                const tempPath = '/checkout';
                this.props.clearCurrentPathAfterUse();

                this.props.history.push(tempPath);
                return;
            } else {
                // console.log('this.props.temporaryOrderTime.screenBeforeRefresh');
                const tempPath = this.props.temporaryOrderTime.screenBeforeRefresh;
                this.props.clearCurrentPathAfterUse();

                this.props.history.push(tempPath);
            }
        } else {
            if(!this.props.como.isRedirectFromComo && !this.props.como.isRestartedOrderFromComoUser) {
            
                this.props.clearState();

                if (brandId) {
                    // console.log('componentDidMount inside if brandId ', brandId);
                    this.props.getOrderTypes(brandId, () => {
                        this.startOrder(brandId);
                    });
                }
            } else if(this.props.como.isRestartedOrderFromComoUser) {

                this.props.comoMemberStartOrder(this.props.brandId, this.props.como.temporaryToken, () => {
                    this.startOrder(this.props.brandId)
                });
            }

            if (this.props.customerThemes.selectedTheme.colorsCss) {
                this.applyTheme();
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerThemes.selectedTheme.colorsCss !== prevProps.customerThemes.selectedTheme.colorsCss && this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps ', nextProps, ' thisProps ', this.props);
        if (nextProps !== this.props) {
            let that = this;
            this.setState({
                isStartScreenOpened: nextProps.isStartOrderScreenOpened
            }, () => {
                // In mobile view set the modal to be 100vh height
                const isMobileView = window.outerWidth <= 800;
                // console.log('componentWillReceiveProps callback ', that.state);
                if (this.state.isStartScreenOpened && isMobileView) {
                    let modalContainer = document.getElementsByClassName('modal')[0];
                    modalContainer.style.height = '100vh';
                };
            });
        }
    }

    changeState({ target }) {
        this.setState({
            [target.name]: target.value
        });
    }

    toggle(keepState) {
        // console.log('toggle keepState ');
        if (keepState === undefined || keepState === null || (typeof keepState !== 'boolean') || !keepState) {
            this.props.clearWizardData();
            this.props.history.push(`/start-order/order-type`);
        }

        const path = '/start-order';
        this.props.closeStartOrderScreen(path);

    }

    startOrder(brandId) {
        const currentBrandId = brandId ? brandId : this.state.brandId;
        // console.log('startOrder', this.props.orderTypes);
        this.props.saveBrandIdToStore(currentBrandId);
        const path = '/start-order/order-type';
        this.props.openStartOrderScreen(path);
    }

    renderSuccessAlert() {

        const successAlert = this.props.alertMessage.showSuccessAlert;

        if (successAlert) {
            setTimeout(() => this.props.clearMessageAlert('success'), 5000);
        }

        const dismiss = this.props.clearMessageAlert.bind(this, 'success');
        if (successAlert) {
            return (
                <div className='alertContainer'>
                    <Alert className='success-alert' color='success' toggle={dismiss}>
                        {i18n.t('common:alerts.accDeleted')}
                    </Alert>
                </div>
            );
        }
        return null;
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
    }

    render() {
        const errorMsgStyle = (this.props.alertMessage.showErrorAlert || this.props.alertMessage.showSuccessAlert) ? { top: '50%', left: '36%', position: 'fixed', zIndex: 2000000 } : {};

        // const brandId = localStorage.getItem('brandId');
        // const isBrandIdFoundInCode = brandId ? true : false;
        const urlParams = new URLSearchParams(window.location.search);
        const brandId = urlParams.get('brandId');
        // console.log('Render brandId, ', localStorage.getItem('brandId'), ' props.brandId ', this.props.brandId, ' isBrandIdFoundInCode ', isBrandIdFoundInCode);
        // console.log('param brandId ', brandId);
        const isBrandIdFoundInCode = brandId ? true : false;
        const isRedirectFromComo = this.props.como ? this.props.como.isRedirectFromComo : false;
        const isRestartedOrderFromComoUser = this.props.como ? this.props.como.isRestartedOrderFromComoUser : false;

        return (
            <Container fluid>
                <Row hidden={isBrandIdFoundInCode || isRedirectFromComo || isRestartedOrderFromComoUser} style={{ marginTop: 16 }}>
                    <Col xs={7} sm={8} md={2}>
                        <Input type="number" name="brandId" placeholder={i18n.t('screens:startOrderScreen.brandId')} value={this.state.brandId} onChange={this.changeState.bind(this)} />
                    </Col>
                    <Col xs={5} sm={4} md={4}>
                        <Button color="outline-danger" onClick={() => this.startOrder()}>{i18n.t('screens:startOrderScreen.startOrder')}</Button>
                    </Col>
                </Row>
                {/* Disable the modal closing when clicking outside of it toggle={this.toggle} */}
                <Modal className="modalStep startOrder" isOpen={this.state.isStartScreenOpened || isRestartedOrderFromComoUser } size='lg'>
                    <Switch>
                        {/* <Route path='/start-order/order-type' component={SelectOrderTypeScreen} toggle={this.toggle}/> */}
                        <Route path='/start-order/order-type'
                            render={(props) => (
                                <SelectOrderTypeScreen {...props} toggle={this.toggle} />
                            )} />
                        <Route path='/start-order/phone-number'
                            render={(props) => (
                                <PhoneNumberScreen  {...props} toggle={this.toggle} isOrderDone={false} />
                            )} />
                        <Route path='/start-order/sms-confirmation'
                            render={(props) => (
                                <SmsConfirmationScreen {...props} toggle={this.toggle} />
                            )} />
                        <Route path='/start-order/user-credentials'
                            render={(props) => (
                                <UserCredentialsScreen {...props} toggle={this.toggle} />
                            )} />
                        <Route path='/start-order/forgotten-email'
                            render={(props) => (
                                <ForgottenEmailScreen {...props} toggle={this.toggle} isOrderDone={false} />
                            )} />
                        <Route path='/start-order/saved-addresses'
                            render={(props) => (
                                <SavedAddressesScreen {...props} toggle={this.toggle} />
                            )} />
                        <Route path='/start-order/user-address'
                            render={(props) => (
                                <UserAddressScreen {...props} toggle={this.toggle} />
                            )} />
                        <Route path='/start-order/confirm-address'
                            render={(props) => (
                                <ConfirmAddressScreen {...props} toggle={this.toggle} />
                            )} />
                        <Route path='/start-order/restaurant-selection'
                            render={(props) => (
                                <RestaurantSelectionScreen {...props} toggle={this.toggle} />
                            )} />
                        <Route path='/start-order/order-time'
                            render={(props) => (
                                <OrderTimeScreen {...props} toggle={this.toggle} />
                            )} />
                        <Route path='/start-order/car-details'
                            render={(props) => (
                                <CarDetailsScreen {...props} toggle={this.toggle} />
                            )} />
                    </Switch>
                </Modal>
                <div style={errorMsgStyle}>
                    {this.renderSuccessAlert()}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isStartOrderScreenOpened: state.startOrder.startOrderScreenOpened,
        brandId: state.brand.id,
        customerThemes: state.customerThemes,
        alertMessage: state.alertMessage,
        orderTypes: state.orderTypes,
        temporaryOrderTime: state.temporaryOrderTime,
        pwaAppRunning: state.pwaAppRunning,
        paymentProvider: state.paymentProvider,
        storeLocalStorage: state.storeLocalStorage,
        como: state.como,
        giftCards: state.giftCards
    };
};

export default connect(mapStateToProps, {
    openStartOrderScreen, closeStartOrderScreen, saveBrandIdToStore, clearWizardData, clearState, clearMessageAlert, getOrderTypes, clearCurrentPathAfterUse,
    clearLastUsedPagePathAfterTimeStamp, clearShoppingCartState, allowTimeWarningPopup, saveFirstStamp, saveNavigationType, changeAppleGooglePayAvailability, comoMemberStartOrder
})(StartOrderScreen);