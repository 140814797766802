import { destructureTaxes } from '../../ShoppingCartUtils';
import { correctRounding } from '../../CommonUtils';

export const calculateAllDiscounts = (data) => {
    const { appliedPromotions, discountAmount, shoppingCart } = data;
    let total = 0;

    if (appliedPromotions) {
        appliedPromotions.map(promo => {

            total += promo.totalDiscount;
        });
    }

    if (discountAmount) {
        total += discountAmount;
    }

    if (total > shoppingCart.subtotal) {
        total = shoppingCart.subtotal;
    }

    return total;
};

export const filterPromotions = (data) => {


    if (data.redeemAssets) {
        data.redeemAssets = data.redeemAssets.filter(asset => {

            if (asset.redeemable === true) {
                return asset;
            }
        });
    }

    return data;
}

export const getAppliedPromotions = (data) => {

    const { deals, redeemAssets } = data;
    const appliedPromotions = [];

    if (deals) {
        deals.map(deal => {

            const appliedPromo = {
                id: deal.key,
                name: deal.name,
                promoCode: deal.benefits[0].code,
                totalDiscount: getAllBenefitsSum(deal.benefits)
            };

            appliedPromotions.push(appliedPromo);
        });
    }

    if (redeemAssets) {
        redeemAssets.map(asset => {

            if (asset.redeemable) {

                const appliedPromo = {
                    id: asset.key,
                    name: asset.name,
                    promoCode: asset.code,
                    totalDiscount: getAllBenefitsSum(asset.benefits)
                };

                appliedPromotions.push(appliedPromo);
            }
        });
    }

    return appliedPromotions;
};

const getAllBenefitsSum = (benefits) => {
    let total = 0;

    benefits.map(benefit => {
        total += Math.abs(benefit.sum / 100);
    });

    return total;
}

export const transformDataStructure = (data) => {

    const { getState, promotions, credits, cancelPayment, isPlaceOrder } = data;

    const transformedProducts = getPurchaseProducts(getState, data);
    const totalTax = getTotalTaxAmount(getState);
    const totalWithoutTax = getTotalAmountWithoutTax(transformedProducts);

    let dataObject = {
        purchase: {
            openTime: getState().como.userSavedData.openTime,
            transactionId: getState().como.userSavedData.transactionId,
            totalAmount: totalWithoutTax,
            orderType: getState().orderTypes.selectedOrderType.value,
            items: transformedProducts
        }
    };

    if (totalTax) {

        dataObject.purchase.totalTaxAmount = totalTax;
    }

    if (promotions) {

        dataObject = getBenefitsData(getState, promotions, dataObject);
    }

    if (credits != null) {

        dataObject = getPaymentData(getState, credits, dataObject);
    }

    if (cancelPayment && getState().como.userSavedData.paymentResponse) {

        dataObject = getCancelPaymentData(getState, dataObject);
    }
    // TODO refactor this before sending orders
    // split it in mini methods 
    // add better deal handling
    if (isPlaceOrder) {

        dataObject.purchase.meansOfPayment = [];

        if (getState().como.userSavedData.paymentResponse) {
            if (getState().como.userSavedData.paymentResponse.payments[0].paymentMethod.includes('meanOfPayment')) {
                dataObject.purchase.meansOfPayment = [{
                    type: getState().como.userSavedData.paymentResponse.type,
                    amount: Math.abs(getState().como.userSavedData.paymentResponse.payments[0].amount)
                }];
            } else if (getState().como.userSavedData.paymentResponse.payments[0].paymentMethod === 'discount') {
                dataObject.purchase.totalAmount -= Math.abs(getState().como.userSavedData.paymentResponse.payments[0].amount);
                dataObject.purchase.totalGeneralDiscount = Math.abs(getState().como.userSavedData.paymentResponse.payments[0].amount);
            }
        }

        dataObject.purchase.meansOfPayment.push({
            type: getState().payment.selectedPaymentType.id === 1 ? 'credit_card' : 'cash',
            amount: getDiffInTotal(getState, transformedProducts)
        });

        if (getState().como.userSavedData.getBenefitsResponse) {
            if (getState().como.userSavedData.getBenefitsResponse.deals) {
                dataObject.deals = [];

                if (getState().como.userSavedData.getBenefitsResponse.deals.length) {
                    getState().como.userSavedData.getBenefitsResponse.deals.map(deal => {
                        dataObject.deals.push({ key: deal.key, appliedAmount: deal.benefits[0].sum });
                    });
                }

            }

            if (getState().como.userSavedData.getBenefitsResponse.redeemAssets) {
                dataObject.redeemAssets = [];

                if (getState().como.userSavedData.getBenefitsResponse.redeemAssets.length) {
                    getState().como.userSavedData.getBenefitsResponse.redeemAssets.map(asset => {
                        if (asset.benefits) {
                            if (asset.code) {
                                dataObject.redeemAssets.push({ code: asset.code, appliedAmount: asset.benefits[0].type === 'itemCode' || asset.benefits[0].type === 'dealCode' ? 0 : asset.benefits[0].sum });
                            } else {
                                dataObject.redeemAssets.push({ key: asset.key, appliedAmount: asset.benefits[0].type === 'itemCode' || asset.benefits[0].type === 'dealCode' ? 0 : asset.benefits[0].sum });
                            }
                        }
                    });
                }
            }
        }

    }

    return dataObject
};

const getTotalAmountWithoutTax = (products) => {

    let total = 0;
    products.map(product => {

        total += product.netAmount;
    });

    return correctRounding(total, 0);
};

const getPurchaseProducts = (getState, data) => {

    const { orderProducts } = getState().shoppingCart;

    let transformedProducts = [];

    orderProducts.forEach((product) => {
        const categoryId = getState().restaurantProducts.find(restProduct => restProduct.id === product.id || restProduct.productSizesIds.find(id => id === product.id)).categoryId;
        const categoryName = getState().restaurantCategories.find(category => category.id === categoryId).name;
        const allowToppingSubstitution = getState().selectedRestaurant.allowToppingSubstitution;
        let ingredientsSum = 0;
        let sizePrice = 0;

        if (allowToppingSubstitution) {
           ingredientsSum = getToppingSubstitutions(product);
        }

        if (product.questions.length) {
            sizePrice = getSizePrice(product.questions);
            const productFreeToppings = getFreeToppingsOnProductLvl(product);
            getAnswersAsProducts(product.id, categoryId, categoryName, product.questions, transformedProducts, data, productFreeToppings, product.freeToppingsCount, ingredientsSum);
        }

        const grossAmount = correctRounding((product.price + sizePrice) * product.quantity * 100, 0); //𝑔𝑟𝑜𝑠𝑠𝐴𝑚𝑜𝑢𝑛𝑡=(𝑔𝑟𝑜𝑠𝑠𝑝𝑟𝑖𝑐𝑒𝑝𝑒𝑟𝑖𝑡𝑒𝑚)𝑥(𝑞𝑢𝑎𝑛𝑡𝑖𝑡𝑦𝑜𝑟𝑤𝑒𝑖𝑔ℎ𝑡)where gross price is the price before it’s discounted (in cents)
        const netAmount = getNetAmount(product, data, sizePrice, false, grossAmount); //𝑛𝑒𝑡𝐴𝑚𝑜𝑢𝑛𝑡=(𝑛𝑒𝑡𝑝𝑟𝑖𝑐𝑒𝑝𝑒𝑟𝑖𝑡𝑒𝑚)𝑥(𝑞𝑢𝑎𝑛𝑡𝑖𝑡𝑦𝑜𝑟𝑤𝑒𝑖𝑔ℎ𝑡)Total amount after any Como or other discounts were applied (in cents)

        const mainProduct = putProductsPartsTogether(transformedProducts.length + 1, product.id, product.name, categoryId, categoryName, product.quantity, grossAmount, netAmount, []);

        transformedProducts.push(mainProduct);
    });

    return transformedProducts;
};

const getNetAmount = (product, data, sizePrice, isFreeTopping, roundedAmount) => {

    const { getState, credits, cancelPayment, isPlaceOrder } = data;
    // let productPrice = correctRounding(((isFreeTopping ? product.modifyPrice : product.price) + sizePrice) * (product.quantity ? product.quantity : (product.count ? product.count : 1)) * 100, 0);
    let productPrice = roundedAmount;
    if (credits || cancelPayment || isPlaceOrder) {

        const deals = !getState().como.userSavedData.getBenefitsResponse ? null : getState().como.userSavedData.getBenefitsResponse.deals;
        const redeemAssets = !getState().como.userSavedData.getBenefitsResponse ? null : getState().como.userSavedData.getBenefitsResponse.redeemAssets;

        if (deals) {
            productPrice = getCurrentProductDiscountedPrice(deals, productPrice, product);
        }

        if (redeemAssets) {
            productPrice = getCurrentProductDiscountedPrice(redeemAssets, productPrice, product);
        }

        productPrice = correctRounding(productPrice, 0);
    }

    return productPrice;
};

const getSizePrice = (questions) => {
    let sizePrice = 0;

    questions.forEach(question => {
        if (question.id === -111) {
            if (question.selectedAnswers) {
                sizePrice = question.selectedAnswers[0].price
            }
        }
    })

    return sizePrice;
}

const getAnswersAsProducts = (productId, categoryId, categoryName, questions, transformedProducts, data, productFreeToppings, productFreeToppingsCount, ingredientsSum) => {
    let transformedAnswers = [];
    questions.forEach(question => {

        if (question.id !== -111 && !question.posDefault) {
            if (question.selectedAnswers) {

                let freeToppingsCount = productFreeToppings.length ? productFreeToppingsCount : question.freeToppingsCount;
                [...question.selectedAnswers].sort((e1, e2) => e2.price - e1.price).forEach((selectedAnswer) => {

                    let price = selectedAnswer.price;
                    let answerCount = selectedAnswer.count ? selectedAnswer.count : 1;
                    const isFreeTopping = (freeToppingsCount !== null || (productFreeToppings.some(topping => topping.id === selectedAnswer.id)));

                    if (isFreeTopping && freeToppingsCount > 0) {
                        price = selectedAnswer.modifyPrice
                        if (freeToppingsCount - answerCount < 0) {
                            answerCount -= freeToppingsCount;
                            price = selectedAnswer.price
                        }
                        freeToppingsCount -= answerCount;
                    }

                    const grossAmount = correctRounding(price * answerCount * 100, 0); //𝑔𝑟𝑜𝑠𝑠𝐴𝑚𝑜𝑢𝑛𝑡=(𝑔𝑟𝑜𝑠𝑠𝑝𝑟𝑖𝑐𝑒𝑝𝑒𝑟𝑖𝑡𝑒𝑚)𝑥(𝑞𝑢𝑎𝑛𝑡𝑖𝑡𝑦𝑜𝑟𝑤𝑒𝑖𝑔ℎ𝑡)where gross price is the price before it’s discounted (in cents)
                    const netAmount = getNetAmount(selectedAnswer, data, 0, isFreeTopping, grossAmount); //𝑛𝑒𝑡𝐴𝑚𝑜𝑢𝑛𝑡=(𝑛𝑒𝑡𝑝𝑟𝑖𝑐𝑒𝑝𝑒𝑟𝑖𝑡𝑒𝑚)𝑥(𝑞𝑢𝑎𝑛𝑡𝑖𝑡𝑦𝑜𝑟𝑤𝑒𝑖𝑔ℎ𝑡)Total amount after any Como or other discounts were applied (in cents)
                    const tags = ['Modifier', `${productId}`];

                    const subProduct = putProductsPartsTogether(transformedProducts.length + transformedAnswers.length + 1, selectedAnswer.id, selectedAnswer.name, categoryId, categoryName, selectedAnswer.count, grossAmount, netAmount, tags);

                    if (ingredientsSum) {
                        subProduct.originalPrice = selectedAnswer.price
                        transformedAnswers.push(subProduct);
                    } else {
                        transformedProducts.push(subProduct);
                    }
                })
            } else if (question.selectedProduct) {   // meal deal product
                const selectedAnswer = question.selectedProduct;

                if (selectedAnswer.subQuestions.length) {
                    const productFreeToppings = getFreeToppingsOnProductLvl(selectedAnswer);
                    getAnswersAsProducts(selectedAnswer.id, categoryId, categoryName, selectedAnswer.subQuestions, transformedProducts, data, productFreeToppings, selectedAnswer.freeToppingsCount);
                }

                const grossAmount = correctRounding((selectedAnswer.price) * (selectedAnswer.count ? selectedAnswer.count : 1) * 100, 0); //𝑔𝑟𝑜𝑠𝑠𝐴𝑚𝑜𝑢𝑛𝑡=(𝑔𝑟𝑜𝑠𝑠𝑝𝑟𝑖𝑐𝑒𝑝𝑒𝑟𝑖𝑡𝑒𝑚)𝑥(𝑞𝑢𝑎𝑛𝑡𝑖𝑡𝑦𝑜𝑟𝑤𝑒𝑖𝑔ℎ𝑡)where gross price is the price before it’s discounted (in cents)
                const netAmount = getNetAmount(selectedAnswer, data, 0, false, grossAmount); //𝑛𝑒𝑡𝐴𝑚𝑜𝑢𝑛𝑡=(𝑛𝑒𝑡𝑝𝑟𝑖𝑐𝑒𝑝𝑒𝑟𝑖𝑡𝑒𝑚)𝑥(𝑞𝑢𝑎𝑛𝑡𝑖𝑡𝑦𝑜𝑟𝑤𝑒𝑖𝑔ℎ𝑡)Total amount after any Como or other discounts were applied (in cents)
                const count = selectedAnswer.count ? selectedAnswer.count : 1;
                const tags = ['Modifier', `${productId}`];

                const subProduct = putProductsPartsTogether(transformedProducts.length + 1, selectedAnswer.id, selectedAnswer.name, categoryId, categoryName, count, grossAmount, netAmount, tags)

                transformedProducts.push(subProduct);
            }
        }
    })

    if (transformedAnswers.length && ingredientsSum) {
        ingredientsSum = correctRounding(ingredientsSum * 100, 0);
        [...transformedAnswers].sort((e1, e2) => e2.price - e1.price).forEach(answer => {
            if (answer.grossAmount - ingredientsSum <= 0) {
                ingredientsSum -= answer.grossAmount;
                answer.grossAmount = 0;
                answer.netAmount = 0;
            } else {
                answer.grossAmount -= ingredientsSum;
                answer.netAmount -= ingredientsSum;
                ingredientsSum = 0;
            }
            const { originalPrice, ...rest } = answer;
            transformedProducts.push(rest);
        })
    }
}

const getFreeToppingsOnProductLvl = (product) => {
    const questions = product.subQuestions ? product.subQuestions : product.questions;
    let result = [];
    for (let i = 0; i < questions.length; i++) {
        let question = product.subQuestions ? product.subQuestions[i] : product.questions[i];
        if (question.id !== -111 && !question.posDefault) {
            if (question.freeToppingsCount !== null) {
                return []; // free toppings on question lvl are with high priority
            }

            if (question.selectedAnswers) {
                question.selectedAnswers.forEach(selectedAnswer => {
                    result.push(selectedAnswer);
                })
            }
        }
    }

    return [...result].sort((e1, e2) => e2.price - e1.price).slice(0, product.freeToppingsCount);
}

const getToppingSubstitutions = (product) => {
    const questions = product.subQuestions ? product.subQuestions : product.questions;
    let ingredientsSum = 0;
    for (let i = 0; i < questions.length; i++) {
        let question = product.subQuestions ? product.subQuestions[i] : product.questions[i];
        if (question.id !== -111 && question.posDefault) {
            ingredientsSum = question.answers.filter(e1 => {
                return !question.selectedAnswers.some(e2 => {
                    return e1.id === e2.id
                })
            }).reduce((sum, e) => sum + e.price, 0);
        }
    }

    return ingredientsSum;
}

const putProductsPartsTogether = (lineId, code, name, departmentCode, departmentName, quantity, grossAmount, netAmount, tags) => {
    return {
        lineId,
        code,
        name,
        departmentCode,
        departmentName,
        quantity,
        grossAmount,
        netAmount,
        tags
    }
}

const getCurrentProductDiscountedPrice = (items, productPrice, product) => {

    items.map(item => {
        if (item.benefits) {
            item.benefits.map(benefit => {
                benefit.extendedData.map(extendedDatum => {
                    if (product.id === +extendedDatum.item.code) {
                        productPrice += extendedDatum.discount;
                    }
                });
            });
        }
    });

    return productPrice;
};

const getBenefitsData = (getState, promotions, dataObject) => {

    const transformedPromos = promotions.map(promo => {
        if (promo.key) {
            return { key: promo.key };
        } else {
            return { code: promo.code };
        }
    });

    dataObject.redeemAssets = transformedPromos;
    
    if (getState().promotionPartners.isUserLoggedInPartner) {
        dataObject.customers = [{ phoneNumber: getState().customer.phone }];
    }

    return dataObject;
};

const getPaymentData = (getState, credits, dataObject) => {

    dataObject.amount = +(Number(Math.round(credits * 100 + 'e2') + 'e-2').toFixed(0));
    dataObject.customer = { phoneNumber: getState().customer.phone };

    return dataObject;
};

const getCancelPaymentData = (getState, dataObject) => {

    dataObject.confirmation = getState().como.userSavedData.paymentResponse.confirmation;
    dataObject.meansOfPayment = {
        type: getState().como.userSavedData.paymentResponse.type,
        amount: getState().como.userSavedData.paymentResponse.payments[0].paymentMethod.amount
    };

    return dataObject;
};

const getTotalTaxAmount = (getState) => {

    let calculatedVatTotal = 0;

    if (!getState().estimateOrderTime.taxIncludeInPrice) {
        const products = getState().shoppingCart.orderProducts;
        const restaurant = getState().selectedRestaurant
        const taxes = destructureTaxes(products, restaurant);

        taxes.map(tax => {
            calculatedVatTotal += tax.total;
        });
    }

    return correctRounding(calculatedVatTotal * 100, 0);
}

const getDiffInTotal = (getState, transformedProducts) => {

    let comoPaymentsAmount = 0;
    let total = getTotalAmountWithoutTax(transformedProducts);
    let taxTotal = 0;

    getState().shoppingCart.vat.taxes.map(tax => {
        return taxTotal += tax.total;
    });

    taxTotal = correctRounding(taxTotal * 100, 0);

    if (getState().como.userSavedData.paymentResponse) {

        comoPaymentsAmount = getState().como.userSavedData.paymentResponse.payments[0].amount;
    }

    if (comoPaymentsAmount < 0) {
        total += comoPaymentsAmount;
    }

    if (taxTotal > 0 && !getState().estimateOrderTime.taxIncludeInPrice) {
        total += taxTotal
    }

    if (total <= 0) {
        return 0;
    }

    return correctRounding(total, 0);
}

export const isCodeAppliedSuccessfully = (promoCode, redeemedAssets) => {

    if (!redeemedAssets) return false;

    const codeFound = redeemedAssets.filter(asset => asset.code === promoCode && asset.redeemable);

    if (codeFound.length) {
        return true;
    }

    return false;
}