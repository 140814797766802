import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import i18n from '../../i18next';
import moment from 'moment/moment';
import { Col } from 'reactstrap';

import {
    DELIVERY_ID,
    IN_STORE_ID,
    COLLECTION_ID,
    CURBSIDE_ID,
    FRONT_END_URL,
    ARABIC
} from '../../utils/Constants';
import { clearWizardData, clearState, changeCodeManuallySet, logoutCustomer, clearGifCardsTotalDiscount } from '../../actions/index';
import { sendMessageToParentSite } from '../../utils/CrossOriginUtils'
import { transformProductPrice, getPriceWithCurrencySymbol } from '../../utils/CommonUtils';

import CommentsSVG from '../../pwa-app/components/common/svgComponents/CommentsSVG';
import SuccessIconOutlineSVG from '../../pwa-app/components/common/svgComponents/SuccessIconOutlineSVG';
import TickSVG from '../../pwa-app/components/common/svgComponents/TickSVG';
import LocationIconSVG from '../../pwa-app/components/common/svgComponents/LocationIconSVG';
import UserIconSVG from '../../pwa-app/components/common/svgComponents/UserIconSVG';
import ClockSVG from '../../pwa-app/components/common/svgComponents/ClockSVG';
import CardsSVG from '../../pwa-app/components/common/svgComponents/CardsSVG';
import MenuuLogoSVG from '../../pwa-app/components/common/svgComponents/MenuuLogoSVG';
import HomeSVG from '../../pwa-app/components/common/svgComponents/HomeSVG';
import { updateCSSVariables } from '../../utils/ThemesSelector';

class OrderConfirmationScreen extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

        this.applyTheme()
    }

    componentWillUnmount() {
        this.props.clearGifCardsTotalDiscount();
    }

    redirectToMenuuHomepage = () => {

        let integrationDetected = false;
        if (new URLSearchParams(window.location.search).get('brandId')) {
            integrationDetected = true;
        }

        if (integrationDetected) {
            sendMessageToParentSite('redirect');
        } else {
            window.location.href = "https://menuu.com/?utm_source=menuu&utm_medium=link&utm_campaign=powered-by-menuu";
        }
    };


    renderOrderTypeText(orderType, rowIndex) {

        switch (orderType) {
            case DELIVERY_ID:
                switch (rowIndex) {
                    case 1:
                        return i18n.t('screens:checkoutScreen.deliverTo');
                    case 2:
                        return i18n.t('screens:checkoutScreen.deliverFrom');
                    case 3:
                        return i18n.t('screens:checkoutScreen.deliverAt');
                    case 4:
                        return i18n.t('screens:checkoutScreen.deliveryInstructions');
                }
            case COLLECTION_ID:
                switch (rowIndex) {
                    case 1:
                        return i18n.t('screens:checkoutScreen.collectBy');
                    case 2:
                        return i18n.t('screens:checkoutScreen.collectFrom');
                    case 3:
                        return i18n.t('screens:checkoutScreen.collectAt');
                    case 4:
                        return i18n.t('screens:checkoutScreen.collectionInstructions');
                }
            case CURBSIDE_ID:
                switch (rowIndex) {
                    case 1:
                        return i18n.t('screens:checkoutScreen.curbsideBy');
                    case 2:
                        return i18n.t('screens:checkoutScreen.curbsideFrom');
                    case 3:
                        return i18n.t('screens:checkoutScreen.curbsideAt');
                    case 4:
                        return i18n.t('screens:checkoutScreen.curbsideInstructions');
                }
                return
            case IN_STORE_ID:
                switch (rowIndex) {
                    case 1:
                        return i18n.t('screens:checkoutScreen.orderBy');
                    case 3:
                        return i18n.t('screens:restaurantDetailsScreen.instoreTime');
                    case 4:
                        return i18n.t('screens:checkoutScreen.orderInstructions');
                }
            default:
                return orderType
        }
    }

    transformToLocalizeTenderTypes (value) {
        
        switch (value) {
            case "Credit card":
                return i18n.t('screens:checkoutScreen.creditcard');
            case "Cash":
                return i18n.t('screens:checkoutScreen.cash');
            default:
                return;
        }
    }

    renderPaymentType() {

        const { tenderType } = this.props.sendOrderModal.orderResponse;
        const { type, primaryColor, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const { sectionTitle } = styles;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <CardsSVG width={24} height={24} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;

        const tenderTypeValue = tenderType ? this.transformToLocalizeTenderTypes(tenderType.value) : null;

        return (
            <Col xs={12} style={{ display: 'flex', borderBottom: '1px solid #a7a7a7', margin: '20px auto' }}>
                <Col xs={1}>
                    {iconImage}
                </Col>
                <Col xs={9} style={{ textAlign: 'start', fontSize: '20px' }}>
                    <p style={sectionTitle}>{i18n.t('screens:checkoutScreen.paymentType')}</p>
                    <p style={{ color: primaryFontColor }}>{tenderTypeValue}</p>
                </Col>
                <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                    {secondIconImage}
                </Col>
            </Col>
        );
    }

    renderInstructions() {

        const { orderTypes } = this.props;
        const { generalInstruction } = this.props.sendOrderModal.orderResponse;
        const { type, primaryColor, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const { sectionTitle } = styles;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <CommentsSVG width={24} height={24} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;
        const sectionTitleText = this.renderOrderTypeText(orderTypes.selectedOrderType.id, 4);

        const generalInstructionNotes = generalInstruction ? generalInstruction.notes : null;

        return (
            <Col xs={12} style={{ display: 'flex', borderBottom: '1px solid #a7a7a7', margin: '20px auto' }}>
                <Col xs={1}>
                    {iconImage}
                </Col>
                <Col xs={9} style={{ textAlign: 'start', fontSize: '20px' }}>
                    <p style={sectionTitle}>{sectionTitleText}</p>
                    <p style={{ color: primaryFontColor }}>{generalInstructionNotes}</p>
                </Col>
                <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                    {secondIconImage}
                </Col>
            </Col>
        );
    }

    renderCustomerInfo() {

        const { orderTypes } = this.props;
        const { customer } = this.props.sendOrderModal.orderResponse;
        const { sectionTitle } = styles;
        const { type, primaryColor, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <UserIconSVG width={24} height={24} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;
        const sectionTitleText = this.renderOrderTypeText(orderTypes.selectedOrderType.id, 1);

        const customerName = customer ? customer.name : null;
        const customerPhone = customer ? customer.formattedPhone : null;
        const customerEmail = customer ? customer.email : null;

        return (
            <Col xs={12} style={{ display: 'flex', borderBottom: '1px solid #a7a7a7', margin: '20px auto' }}>
                <Col xs={1}>
                    {iconImage}
                </Col>
                <Col xs={9} style={{ textAlign: 'start', fontSize: '20px' }}>
                    <p style={sectionTitle}>{sectionTitleText} {customerName}</p>
                    <p style={{ color: primaryFontColor }}><span>{customerPhone}</span> | <span>{customerEmail}</span></p>
                </Col>
                <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                    {secondIconImage}
                </Col>
            </Col>
        );
    }

    renderAddressInfo() {
        const { orderAddress } = this.props.sendOrderModal.orderResponse;
        const { type, primaryColor, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const { sectionTitle } = styles;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <HomeSVG width={20} height={20} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;

        return (
            <Col xs={12} style={{ display: 'flex', borderBottom: '1px solid #a7a7a7', margin: '20px auto' }}>
                <Col xs={1}>
                    {iconImage}
                </Col>
                <Col xs={9} style={{ textAlign: 'start', fontSize: '20px' }}>
                    <p style={sectionTitle}>{i18n.t('screens:checkoutScreen.deliverTo')}</p>
                    <p style={{ color: primaryFontColor }}>{orderAddress.fullAddress}</p>
                </Col>
                <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                    {secondIconImage}
                </Col>
            </Col>
        );
    }

    renderRestaurantInfo() {

        const { restaurant, brandName, orderTypes } = this.props;
        const { orderInStoreInfo } = this.props.sendOrderModal.orderResponse;
        const { sectionTitle } = styles;
        const { type, primaryColor, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <LocationIconSVG width={24} height={24} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;
        const sectionTitleText = this.renderOrderTypeText(orderTypes.selectedOrderType.id, 2);
        const isDinePay = this.props.orderTypes.selectedOrderType.id === IN_STORE_ID;
        const { name, displayAddress } = restaurant;
        const restaurantInfo = isDinePay ? orderInStoreInfo.value : `${brandName} ${name} ${displayAddress}`

        return (
            <Col xs={12} style={{ display: 'flex', borderBottom: '1px solid #a7a7a7', margin: '20px auto' }}>
                <Col xs={1}>
                    {iconImage}
                </Col>
                <Col xs={9} style={{ textAlign: 'start', fontSize: '20px' }}>
                    <p style={sectionTitle}>{isDinePay ? orderInStoreInfo.label : sectionTitleText}</p>
                    {
                        isDinePay ?
                            <p style={{ color: primaryFontColor }}>{orderInStoreInfo.value} </p> :
                            <p style={{ color: primaryFontColor, display: 'flex', flexFlow: 'wrap' }}>
                                <span>{brandName}</span>
                                <span style={{ margin: '0 5px' }}>{name}</span>
                                <span>{displayAddress}</span>
                            </p>
                    }
                </Col>
                <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                    {secondIconImage}
                </Col>
            </Col>
        );
    }

    renderCustomerOrderTime() {

        const { orderTypes, restaurant } = this.props;
        const { orderResponse } = this.props.sendOrderModal;
        const { sectionTitle } = styles;
        const { type, primaryColor, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const isDinePay = this.props.orderTypes.selectedOrderType.id === IN_STORE_ID;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <ClockSVG width={24} height={24} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;
        const sectionTitleText = this.renderOrderTypeText(orderTypes.selectedOrderType.id, 3);
        const deliveryTime = `${orderResponse.estimatedDeliveryTime} ${i18n.t('screens:orderConfirmationScreen.minutes')}`;

        let orderTime = null;
        let day = moment(orderResponse.wantedTime, 'DD-MM-YYYY').format('d');
        let month = moment(orderResponse.wantedTime, 'DD-MM-YYYY').format('MM');
        let dateDay = moment(orderResponse.wantedTime, 'DD-MM-YYYY').format('D');
        day++;
        if (orderResponse.timeSlotEndTime) {
            let fromTime = moment(`${orderResponse.wantedTime}`, 'DD-MM-YYYY HH:mm').format('HH:mm');
            let toTime = moment(`${orderResponse.timeSlotEndTime}`, 'DD-MM-YYYY HH:mm').format('HH:mm');
            orderTime = `${i18n.t(`common:days.weekday_${day}`)} ${dateDay} ${i18n.t(`common:months.month_${month}`)} ${fromTime} - ${toTime}`;
        } else if (isDinePay && !restaurant.type.futureOrderEnabled) {
            orderTime = deliveryTime
        } else {
            let time = moment(`${orderResponse.wantedTime}`, 'DD-MM-YYYY HH:mm').format('HH:mm');
            orderTime = `${i18n.t(`common:days.weekday_${day}`)} ${dateDay} ${i18n.t(`common:months.month_${month}`)} ${time}`;
        }

        return (
            <Col xs={12} style={{ display: 'flex', borderBottom: '1px solid #a7a7a7', margin: '20px auto' }}>
                <Col xs={1}>
                    {iconImage}
                </Col>
                <Col xs={9} style={{ textAlign: 'start', fontSize: '20px' }}>
                    <p style={sectionTitle}>{sectionTitleText}</p>
                    <p style={{ color: primaryFontColor }}>{orderTime}</p>
                </Col>
                <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                    {secondIconImage}
                </Col>
            </Col>
        );
    }

    renderBrandImage() {

        const { brandLogoPath } = this.props.sendOrderModal.orderResponse;

        return (
            <Fragment>
                { brandLogoPath ?
                    <div style={{ width: '140px', height: '140px', margin: '0 auto' }}>
                        <img
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            src={`${FRONT_END_URL}/${brandLogoPath}`}
                        />
                    </div> : null
                }
            </Fragment>
        )
    }

    renderProducts() {

        const { mainTextColor } = this.props.customerThemes.selectedTheme;
        const { itemDescription, mealDealProductName, itemName } = styles;
        const products = this.props.sendOrderModal.orderResponse.orderProducts;
        const { currency } = this.props.sendOrderModal.orderResponse;
        const isArabic = this.props.language === ARABIC;

        return products ? products.map((product, index) => {
            const productPrice = getPriceWithCurrencySymbol(transformProductPrice(product.price), currency, isArabic);
            // Simple Product
            if (!product.mealDeal && !product.mealDealProduct) {
                return (
                    <div style={{ paddingBottom: '5px' }}>
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 10px 5px 10px' }}>
                            <p style={{ ...itemName, display: 'flex' }}>
                                <span>{product.quantity}</span>x
                                <span style={isArabic ? { marginRight: '5px' } : { marginLeft: '5px' }}>{product.name}</span></p>
                            <p style={itemName}>{productPrice}</p>
                        </div>
                        {
                            product.sizeName ?
                                <div key={index} style={isArabic ? { display: 'flex', justifyContent: 'flex-start', fontSize: '16px', color: mainTextColor, paddingRight: '30px' } : { display: 'flex', justifyContent: 'flex-start', fontSize: '16px', color: mainTextColor, paddingLeft: '30px' }}>
                                    <p style={itemDescription}>
                                        <span style={isArabic ? { marginLeft: '5px' } : { marginRight: '5px' }}>{i18n.t('screens:orderConfirmationScreen.size')}:</span>
                                        <span>{product.sizeName}</span>
                                        <span>{product.name}</span>
                                    </p>
                                </div> : null
                        }
                        {
                            // Product Questions
                            product.orderQuestions.length > 0 ? product.orderQuestions.map((question, index) => {
                                // Ingredients Answers
                                if (question.posDefault) {
                                    return question.removedAnswers.map((answer, index) => {
                                        return (
                                            <div key={index} style={isArabic ? { display: 'flex', justifyContent: 'flex-start', fontSize: '16px', color: mainTextColor, paddingRight: '30px' } : { display: 'flex', justifyContent: 'flex-start', fontSize: '16px', color: mainTextColor, paddingLeft: '30px' }}>
                                                <p style={itemDescription}>
                                                    <span style={isArabic ? { marginLeft: '5px' } : { marginRight: '5px' }}>{i18n.t('screens:orderConfirmationScreen.no')}:</span>
                                                    <span>{answer.name}</span>
                                                </p>
                                            </div>
                                        )
                                    })
                                    // Answers
                                } else {
                                    return question.orderAnswers.map((answer, index) => {
                                        return (
                                            <div key={index} style={isArabic ? { display: 'flex', justifyContent: 'flex-start', fontSize: '16px', color: mainTextColor, paddingRight: '30px' } : { display: 'flex', justifyContent: 'flex-start', fontSize: '16px', color: mainTextColor, paddingLeft: '30px' }}>
                                                <p style={itemDescription}>
                                                    <span style={isArabic ? { marginLeft: '5px' } : { marginRight: '5px' }}>{answer.name}</span>
                                                    {answer.quantity > 1 ?
                                                        <span>({answer.quantity})</span> : null
                                                    }
                                                </p>
                                            </div>
                                        )
                                    })
                                }

                            }) : null
                        }
                    </div>
                )
                // Meal Deal
            } else if (product.mealDeal && !product.mealDealProduct) {
                return (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 10px 0px 10px' }}>
                        <p style={{ ...itemName, display: 'flex' }}>
                            <span>{product.quantity}</span>x
                            <span style={isArabic ? { marginRight: '5px' } : { marginLeft: '5px' }}>{product.name}</span></p>
                        <p style={itemName}>{productPrice}</p>
                    </div>
                )
                // Meal Deal Product
            } else if (product.mealDeal && product.mealDealProduct) {
                return (
                    <div >
                        <div key={index} style={{ display: 'flex', justifyContent: 'flex-start', padding: '5px 10px 0px 10px' }}>
                            <p style={mealDealProductName}>{product.name}</p>
                        </div>
                        {
                            // Meal Deal Product Questions
                            product.orderQuestions.length > 0 ? product.orderQuestions.map((question, index) => {
                                // Ingredients Answers
                                if (question.posDefault) {
                                    return question.removedAnswers.map((answer, index) => {
                                        return (
                                            <div key={index} style={isArabic ? { display: 'flex', justifyContent: 'flex-start', color: mainTextColor, paddingRight: '35px' } : { display: 'flex', justifyContent: 'flex-start', color: mainTextColor, paddingLeft: '35px' }}>
                                                <p style={itemDescription}>
                                                    <span style={isArabic ? { marginLeft: '5px' } : { marginRight: '5px' }}>{i18n.t('screens:orderConfirmationScreen.no')}:</span>
                                                    <span>{answer.name}</span></p>
                                            </div>
                                        )
                                    })
                                    // Answers
                                } else {
                                    return question.orderAnswers.map((answer, index) => {
                                        return (
                                            <div key={index} style={isArabic ? { display: 'flex', justifyContent: 'flex-start', color: mainTextColor, paddingRight: '35px' } : { display: 'flex', justifyContent: 'flex-start', color: mainTextColor, paddingLeft: '35px' }}>
                                                <p style={itemDescription}>
                                                    <span style={isArabic ? { marginLeft: '5px' } : { marginRight: '5px' }}>{answer.name}</span>
                                                    {answer.quantity > 1 ?
                                                        <span>({answer.quantity})</span> : null
                                                    }
                                                </p>
                                            </div>
                                        )
                                    })
                                }

                            }) : null
                        }
                    </div>
                )
            }
        }) : null
    }

    renderCartInfo() {
        const { sectionTitle, menuuLogo, orderFooterParagraph } = styles;
        const { promotionPartner } = this.props.promotionPartners;
        const { primaryFontColor, mainTextColor, menuuLogoColor, primaryColor } = this.props.customerThemes.selectedTheme;
        const { tip, deliveryCharge, serviceFee, promotions, totalTax, total, currency, priceBeforeDiscount, externalDiscountAmount, orderDeposit, internalDiscountAmount, giftCardDiscount } = this.props.sendOrderModal.orderResponse;
        const isArabic = this.props.language === ARABIC;
        // const giftCardsTotalDiscount =  ? this.props.giftCards.giftCardsTotalDiscount : null;

        const promotionsDiscount = promotions ? promotions.length > 0 ? getPriceWithCurrencySymbol(transformProductPrice(promotions[0].totalDiscount), currency, isArabic) : null : null;
        const externalDiscount = externalDiscountAmount ? getPriceWithCurrencySymbol(transformProductPrice(externalDiscountAmount), currency, isArabic) : null;
        const internalDiscount = internalDiscountAmount ? getPriceWithCurrencySymbol(transformProductPrice(internalDiscountAmount), currency, isArabic) : null;
        const discount = externalDiscount ? externalDiscount : internalDiscount;
        let discountsText = '';
        if (promotionPartner && promotionPartner.id === 1) {
            discountsText = i18n.t('screens:shoppingCart.discount')
        } else if (promotionPartner && promotionPartner.id === 2) {
            discountsText = i18n.t('screens:como.discount');
        };

        const subtotalPrice = getPriceWithCurrencySymbol(transformProductPrice(priceBeforeDiscount), currency, isArabic);
        const tipPrice = getPriceWithCurrencySymbol(transformProductPrice(tip), currency, isArabic);
        const deliveryFeePrice = getPriceWithCurrencySymbol(transformProductPrice(deliveryCharge), currency, isArabic);
        const serviceFeePrice = getPriceWithCurrencySymbol(transformProductPrice(serviceFee), currency, isArabic);
        const taxPrice = getPriceWithCurrencySymbol(transformProductPrice(totalTax), currency, isArabic);
        const orderDepositPrice = getPriceWithCurrencySymbol(transformProductPrice(orderDeposit), currency, isArabic);
        const totalPrice = serviceFee ? total + serviceFee : total;
        const finalTotalPrice = getPriceWithCurrencySymbol(transformProductPrice(totalPrice), currency, isArabic);
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total >= 0 ? this.props.giftCards.giftCards.total : null : null;
        // When we have applied gift cards we use the Total from Gift Cards
        const totalPriceWithGiftCard = transformProductPrice(shoppingCartTotalWithGiftCard);
        const totalPriceWithGiftCardWithCurrency = getPriceWithCurrencySymbol(totalPriceWithGiftCard, currency, isArabic);
        const isAppliedGiftCards = this.props.giftCards.giftCards ? (this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0) : null;
       
        return (
            <Col xs={12} style={{ padding: '20px', textAlign: 'start' }}>
                <div style={Object.assign({}, sectionTitle, { textAlign: 'center', borderBottom: '1px solid #a7a7a7', paddingBottom: '5px' })}>{i18n.t('screens:orderConfirmationScreen.yourOrder')}</div>
                <div className="orderProductListContainer" style={{ maxHeight: '300px', overflow: 'hidden auto' }} >{this.renderProducts()}</div>
                <div style={{ borderBottom: '1px solid #a7a7a7', borderTop: '1px solid #a7a7a7', padding: '10px', marginTop: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={orderFooterParagraph}>{i18n.t('screens:shoppingCart.subtotal')}</p>
                        <p style={orderFooterParagraph}>{subtotalPrice}</p>
                    </div>
                    {
                        discount ?
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={orderFooterParagraph}>{discountsText}</p>
                                <p style={orderFooterParagraph}>- {discount}</p>
                            </div> : null
                    }
                    {
                        totalTax ?
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={orderFooterParagraph}>{i18n.t('screens:shoppingCart.vat')}</p>
                                <p style={orderFooterParagraph}>{taxPrice}</p>
                            </div> : null
                    }
                    {
                        deliveryCharge ?
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={orderFooterParagraph}>{i18n.t('screens:shoppingCart.deliveryFee')}</p>
                                <p style={orderFooterParagraph}>{deliveryFeePrice}</p>
                            </div> : null
                    }
                    {
                        serviceFee ?
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={orderFooterParagraph}>{i18n.t('screens:shoppingCart.serviceFee')}</p>
                                <p style={orderFooterParagraph}>{serviceFeePrice}</p>
                            </div> : null
                    }
                    {
                        orderDeposit ?
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={orderFooterParagraph}>{i18n.t('screens:shoppingCart.deposit')}</p>
                                <p style={orderFooterParagraph}>{orderDepositPrice}</p>
                            </div> : null
                    }
                    {
                        giftCardDiscount ?
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={orderFooterParagraph}>{i18n.t('screens:checkoutScreen.giftCard')}</p>
                                <p style={orderFooterParagraph}>{`${getPriceWithCurrencySymbol(transformProductPrice(giftCardDiscount), currency)}`}</p>
                        </div> : null
                    }
                </div>
                {
                    tip ?
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px 0px' }}>
                            <p style={orderFooterParagraph}>{i18n.t('screens:shoppingCart.tip')}</p>
                            <p style={orderFooterParagraph}>{tipPrice}</p>
                        </div> : null
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: tip ? '0px 10px 5px' : '5px 10px 5px' }}>
                    <p style={orderFooterParagraph}>{i18n.t('screens:shoppingCart.total')}</p>
                    <p style={orderFooterParagraph}>{shoppingCartTotalWithGiftCard && isAppliedGiftCards ? totalPriceWithGiftCardWithCurrency : finalTotalPrice}</p>
                    {/* (shoppingCartTotalWithGiftCard >= 0 && this.props.giftCards.giftCards.appliedGiftCards.length > 0) ? totalPriceWithGiftCardWithCurrency : totalPriceWithCurrency */}
                </div>
                <div style={menuuLogo}>
                    <MenuuLogoSVG width={100} height={35} fill={menuuLogoColor} style={{ cursor: 'pointer' }} onClick={this.redirectToMenuuHomepage} />
                </div>
            </Col>
        )
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss)
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, fontWeight, mainTextColor, fontFamilyType, fontBold, fontItalic, uppercase, checkoutScreenNames, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.sectionTitle.color = (this.props.customerThemes.selectedTheme.type === "LIGHT") ? checkoutScreenNames : secondaryColor;
        customizedStyles.sectionTitle.fontFamily = fontFamilyType;
        customizedStyles.sectionTitle.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.sectionTitle.fontStyle = fontItalic;
        customizedStyles.sectionTitle.textTransform = uppercase;
        customizedStyles.itemDescription.color = mainTextColor;
        customizedStyles.mealDealProductName.color = primaryFontColor;
        customizedStyles.itemName.fontWeight = fontBold === 'bold' ? fontBold : fontWeight;
        customizedStyles.itemName.color = primaryFontColor;
        customizedStyles.itemName.fontFamily = fontFamilyType;
        customizedStyles.itemName.fontStyle = fontItalic;
        customizedStyles.itemName.textTransform = uppercase;
        customizedStyles.orderFooterParagraph.color = mainTextColor;
        styles = customizedStyles;
        return styles;
    }

    renderHeader() {

        const { sectionTitle, orderStatusMessageStyle } = styles;
        const { brandName } = this.props;
        const { customer, id } = this.props.sendOrderModal.orderResponse;
        const { type, primaryColor, secondaryColor, menuItemsBackground, fontFamilyType } = this.props.customerThemes.selectedTheme;
        const imgSource = <SuccessIconOutlineSVG width={70} height={70} fill={type === 'DARK' ? secondaryColor : primaryColor} stroke={menuItemsBackground} strokeWidth={1} />
        const isArabic = this.props.language === ARABIC;

        return (
            <Col xs={12} sm={12} style={{ textAlign: 'center', margin: '0px auto 20px', paddingBottom: '20px', borderBottom: '1px solid #a7a7a7' }}>
                {imgSource}

                <p style={{...orderStatusMessageStyle, color: primaryColor}}>{i18n.t(`screens:shoppingCartActions.orderSent`)}</p>
                <p style={{ ...sectionTitle, display: 'flex', justifyContent: 'center' }}>
                    <span style={isArabic ? { marginLeft: '5px' } : { marginRight: '5px' }}>
                        {i18n.t('screens:orderConfirmationScreen.hi')}
                    </span>
                    <span style={isArabic ? { marginLeft: '3px', color: primaryColor } : { marginRight: '3px', color: primaryColor }}> {customer ? customer.name : null}
                    </span> ...
                </p>
                <p style={sectionTitle}>
                    {i18n.t('screens:orderConfirmationScreen.orderSentFrom')}
                    <span style={isArabic ? { marginRight: '3px', color: primaryColor } : { marginLeft: '3px', color: primaryColor }}>{brandName}
                    </span>
                    . {i18n.t('screens:orderConfirmationScreen.yourOrderReference')}:
                    <span style={isArabic ? { marginRight: '5px', fontWeight: 'bold' } : { marginLeft: '5px', fontWeight: 'bold' }}>{id}
                    </span>
                </p>
                <p style={{ color: primaryColor }}>{i18n.t('screens:orderConfirmationScreen.orderUpdates')}</p>
                {this.renderBrandImage()}
            </Col>
        )
    }

    renderBody() {

        const isMobileView = window.innerWidth <= 975
        const { orderResponse } = this.props.sendOrderModal;
        const isDelivery = this.props.orderTypes.selectedOrderType.id === DELIVERY_ID;

        return (
            <Col xs={12} md={12} lg={12} style={!isMobileView ? { display: 'flex', maxWidth: '1600px', margin: '0 auto' } : { padding: '0px' }}>
                <Col xs={12} md={12} lg={6} style={isMobileView ? { padding: '0px' } : null}>
                    {this.renderCustomerInfo()}
                    {this.renderRestaurantInfo()}
                    {this.renderCustomerOrderTime()}
                    {
                        isDelivery ?
                            <p>{this.renderAddressInfo()}</p> : null
                    }
                    {this.renderPaymentType()}
                    {
                        orderResponse.generalInstruction ?
                            <p>{this.renderInstructions()}</p> : null
                    }
                </Col>
                <Col xs={12} md={12} lg={4} style={{ margin: '0 auto' }}>
                    {this.renderCartInfo()}
                </Col>
            </Col>
        );
    }

    render() {
        const { menuBackground } = this.props.customerThemes.selectedTheme;

        return (
            <div style={{ backgroundColor: menuBackground, textAlign: 'center', padding: '20px 0px', minHeight: '100%' }}>
                {this.renderHeader()}
                {this.renderBody()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        customerThemes: state.customerThemes,
        sendOrderModal: state.sendOrderModal,
        brandName: state.brand.name,
        brandId: state.brand.id,
        restaurant: state.selectedRestaurant,
        orderTypes: state.orderTypes,
        promotionPartners: state.promotionPartners,
        language: state.storeLocalStorage.language,
        giftCards: state.giftCards
    };
};

export default connect(mapStateToProps, {
    clearWizardData,
    clearState,
    changeCodeManuallySet,
    logoutCustomer,
    clearGifCardsTotalDiscount
})(OrderConfirmationScreen);

let styles = {
    sectionTitle: {
        color: '#a7a7a7', fontSize: 18, fontFamily: 'Roboto',
        fontWeight: 300,
        marginBottom: 4,
        fontSize: '22px'
    },
    orderStatusMessageStyle: {
        fontSize: 30,
        fontFamily: 'Roboto',
        fontWeight: 300,
        margin: '0 auto 20px',
        textAlign: 'center',
    },
    menuuLogo: {
        display: 'flex',
        justifyContent: 'center',
        padding: '5px 0 0'
    },
    itemDescription: {
        display: 'flex',
        marginBottom: 0,
        fontSize: 14,
        color: '#a7a7a7'
    },
    mealDealProductName: {
        fontSize: 15,
        marginBottom: 0
    },
    itemName: {
        fontSize: 18,
        lineHeight: '20px',
        marginBottom: '0px'
    },
    orderFooterParagraph: {
        color: '#a7a7a7',
        marginBottom: 2,
        fontSize: 16
    },
};