import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import '../../../styles/MenuScreen.css';
import ApplicationStep from '../../common/ApplicationStep';
import DetailsScreen from './DetailsScreen';
import MenusAndProductsScreen from './MenusAndProductsScreen';

import {
    logOutCustomer, clearWizardData, clearState, clearTemporaryOrderTimeData, openChangeOrderTimeScreen, saveNewOrderTime, loadAllAvailableDiscounts,
    getRestaurantAvailableMenus, getSelectedProduct, getSelectedProductForEdit, clearMessageAlert, selectRestaurantMenu, openStartOrderScreen,
    selectedMenuArrayIndex, getOldestActiveLoyalty, dispatchPromoCodeToStore, getServiceMessages, clearServiceMessages, changeAuthipayBackBtnFlag,
    editProduct, getComoMemberDetails, addProductToCart, toggleSearchProductFocus, toggleShoppingCartModal, setProductsQty
} from '../../../../actions/index';
import { setLastOpenedPagePWA, 
    checkIfMenuIsAvailable, 
    checkIfCategoryIsAvailable, 
    checkIfProductIsAvailable 
} from "../../../../utils/CommonUtils";
import { calculateProductPrice } from '../../../../utils/ProductWizardUtils';
import { updateCSSVariables } from '../../../../utils/ThemesSelector';
import { pushDataToGoogleTagManager, getProductCategory } from '../../../../utils/AnalyticsUtils';
import ShoppingCartButton from '../../../../components/order/shoppingCart/shoppingCartBtn/ShoppingCartButton';
import CommonModal from '../../../../components/common/CommonModal';
import FreeProduct from '../../../../components/order/shoppingCart/FreeProduct';
import { ARABIC } from '../../../../utils/Constants';
import CartItemsButton from '../../../../components/order/menu/CartItemsButton';
import { Col } from 'reactstrap';
import ShoppingCart from '../../../../components/order/shoppingCart/ShoppingCart';
import { totalItemsCount } from '../../../../utils/ShoppingCartUtils';
import { isIOS } from 'mobile-device-detect';

class MenuScreenPwa extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedProduct: null,
            isFreeProductModalOpen: false,
            isCartModalOpen: false,
            currentProductsQty: 0
        }

        this.animateHeader = this.animateHeader.bind(this);
        this.editProductFromCart = this.editProductFromCart.bind(this);
        this.throttledFunction = _.throttle(this.animateHeader, 300);
        // this.autoAddToCart = this.autoAddToCart.bind(this);
    }

    componentDidMount() {
        this.props.clearServiceMessages();
        this.props.getServiceMessages(this.props.restaurant.restaurantId, this.props.selectedOrderType.value);

        const isComo = this.props.promotionPartners.promotionPartner && this.props.promotionPartners.promotionPartner.id === 2;
        if (isComo) {
            this.props.getComoMemberDetails();
        }

        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);

        const comingFromProductOrMealDealWizard = this.props.temporaryOrderTime.currentPagePath.includes('/order/product/') ||
            this.props.temporaryOrderTime.currentPagePath.includes('order/discounts');

        // If we are coming to the menu component from product wizard DO NOT reload the page data
        if (!comingFromProductOrMealDealWizard) {
            const { restaurantId } = this.props.restaurant;
            this.props.loadAllAvailableDiscounts(restaurantId);
            this.props.getRestaurantAvailableMenus();
        }

        // Hides initial render of rest and brand names in the actionBar
        let restAndBrandName = document.getElementById('restAndBrandName');
        restAndBrandName.style.cssText = "display: none";

        window.addEventListener('scroll', this.throttledFunction, false);

        setLastOpenedPagePWA(this.props.openChangeOrderTimeScreen);

        const { customer } = this.props;
        const appliedPromotions = this.props.shoppingCart ? this.props.shoppingCart.appliedPromotions : null;
        let isAppliedPromotionWithPromoCode = false;
        if(appliedPromotions && appliedPromotions.length) {
            appliedPromotions.map((promotion, index) => {
                if(promotion.promoCode) {
                    isAppliedPromotionWithPromoCode = true;
                }
            })
        }
        // Gets an active loyalty code whenever user is logged in
        if (customer.token && !this.props.storeLocalStorage.codeManuallySet && customer.autoApplyLoyalty && !isAppliedPromotionWithPromoCode) {
            this.props.getOldestActiveLoyalty();
        }

        if (this.props.paymentProvider.providerType && this.props.paymentProvider.providerType.id === 4) {
            //set menu screen flag to local storage when authipay is configured 
            this.props.changeAuthipayBackBtnFlag(true);
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.shoppingCart.orderProducts !== this.props.shoppingCart.orderProducts) && (!this.props.loyalty.oldestActiveCode || this.props.storeLocalStorage.codeManuallySet)) {
            this.checkHasFreeProduct(prevProps.shoppingCart.orderProducts, this.props.shoppingCart.orderProducts);
        }

        if(this.props.currentProductsQty > prevProps.currentProductsQty) {
            let cartItemsIcon = document.getElementById("cartItemsIcon");
            let cartItemsNumber = document.getElementById("cartItemsNumber");


            if (cartItemsIcon !== null && cartItemsIcon !== undefined) {
                if (cartItemsIcon.classList.value.includes("cartItemsIconAnimation") && cartItemsNumber.classList.value.includes("cartItemsNumberAnimation")) {
                    cartItemsIcon.classList.remove("cartItemsIconAnimation");
                    cartItemsNumber.classList.remove("cartItemsNumberAnimation");
                    cartItemsIcon.classList.add("enlargeAnimation");
                    cartItemsNumber.classList.add("flipAnimation")
                } else if (cartItemsIcon.classList.value.includes("enlargeAnimation") && cartItemsNumber.classList.value.includes("flipAnimation")) {
                    cartItemsIcon.classList.remove("enlargeAnimation");
                    cartItemsNumber.classList.remove("flipAnimation");
                    cartItemsIcon.classList.add("cartItemsIconAnimation");
                    cartItemsNumber.classList.add("cartItemsNumberAnimation");
                } else {
                    cartItemsIcon.classList.add("cartItemsIconAnimation");
                    cartItemsNumber.classList.add("cartItemsNumberAnimation");
                }
            }
        }

        if(prevProps.shoppingCart.orderProducts !== this.props.shoppingCart.orderProducts) {
            this.props.setProductsQty(totalItemsCount(this.props.shoppingCart))
        }
    }

    animateHeader() {
        let scrollPosition, header, restAndBrandName;
        scrollPosition = Math.round(window.scrollY);
        header = document.getElementById('headerContainerTransparent');
        restAndBrandName = document.getElementById('restAndBrandName');
        const { buttonsFont } = this.props.customerThemes.selectedTheme;

        if (scrollPosition > 1 && scrollPosition <= 100) {
            header.classList.add('sticky');
            header.classList.remove('sticky-100');
            header.classList.remove('sticky-200');
            restAndBrandName.style.cssText = "display: none";

        } else if (scrollPosition > 100 && scrollPosition < 200) {
            header.classList.add('sticky-100');
            header.classList.remove('sticky');
            header.classList.remove('sticky-200');
            restAndBrandName.style.cssText = "display: none";
        } else if (scrollPosition >= 200) {
            header.classList.add('sticky-200');
            header.classList.remove('sticky');
            header.classList.remove('sticky-100');
            restAndBrandName.style.cssText = "display: block; text-overflow: ellipis; overflow: hidden; white-space: nowrap";
        } else {
            if (header) {
                header.classList.remove('sticky');
                header.classList.remove('sticky-100');
                header.classList.remove('sticky-200');
                restAndBrandName.style.cssText = "display: none";
            }
        }
        restAndBrandName.style.color = buttonsFont;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.throttledFunction, false);
    }

    selectProduct(product) {
        const filteredProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            category: getProductCategory(product.id, this.props.products, this.props.categories)
        };
        pushDataToGoogleTagManager('event', 'view-item', this.props.pwaAppRunning, filteredProduct);

        const isMenuAvailable = checkIfMenuIsAvailable(this.props.menus, product.menuId);
        const isCategoryAvailable = checkIfCategoryIsAvailable(this.props.categories, this.props.products, product);
        const isProductAvailable = checkIfProductIsAvailable(this.props.products, product);
        const isAvailable = isMenuAvailable && isCategoryAvailable && isProductAvailable;

        // if (product.questionsSize === 0 && isAvailable) {
        //     this.props.getSelectedProduct(product.id, null, null, null, isAvailable);
        // } else {
            // Go to the route of the selected product
            this.props.history.push({ pathname: '/brand/' + this.props.brandId + '/order/product/' + product.id });
        // }
    }

    editProductFromCart(product, index) {
        const filteredProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            category: getProductCategory(product.id, this.props.products, this.props.categories)
        };
        pushDataToGoogleTagManager('event', 'view-item', this.props.pwaAppRunning, filteredProduct);

        if (product.promotionFreeProduct) {
            this.props.getSelectedProduct(product.id, (productUpdated) => {
                this.props.editProduct(productUpdated, index, (productUpdated) => {
                    this.props.history.push({
                        pathname: '/brand/' + this.props.brand.id + '/order/product/' + product.id,
                        editProduct: {
                            product: productUpdated
                        }
                    });
                }, product)
            }, product.quantity);
        } else {
            this.props.editProduct(product, index, (productUpdated) => {
                this.props.history.push({
                    pathname: '/brand/' + this.props.brand.id + '/order/product/' + product.id,
                    editProduct: {
                        product: productUpdated
                    }
                })
            })
        }
    }

    // autoAddToCart(itemToAdd) {
    //     const { selectedRestaurant, products, categories } = this.props;
    //     let currency = selectedRestaurant.currency;
        
    //     itemToAdd.instruction = null;

    //     if (!itemToAdd.quantity) {
    //         itemToAdd.quantity = 1;
    //     }

    //     // Google Analytics
    //     let filteredProduct = {
    //         id: itemToAdd.id,
    //         currency: currency,
    //         name: itemToAdd.name,
    //         price: calculateProductPrice(selectedRestaurant.allowToppingSubstitution, itemToAdd.price, itemToAdd.quantity, itemToAdd),
    //         quantity: itemToAdd.quantity,
    //         category: getProductCategory(itemToAdd.id, products, categories)
    //     };

    //     pushDataToGoogleTagManager('event', 'addToCart', this.props.pwaAppRunning, filteredProduct);

    //     this.props.addProductToCart(itemToAdd);
    // }

    toggleFreeProductModal = () => {
        this.setState({
            ...this.state,
            isFreeProductModalOpen: !this.state.isFreeProductModalOpen
        })
    }

    checkHasFreeProduct = (prevProducts, products) => {
        let currentFreeProduct = {};
        let prevFreeProduct = {};

        products.map((product) => {
            if (product.promotionFreeProduct) {
                currentFreeProduct = product;
            }
        })

        prevProducts.map(prevProduct => {

            if (prevProduct.promotionFreeProduct) {
                prevFreeProduct = prevProduct
            }
        })

        if (prevFreeProduct.id !== currentFreeProduct.id && Object.entries(currentFreeProduct).length) {
            this.toggleFreeProductModal();
        }
    }

    toggleCartModal() {

        this.setState({
            ...this.state,
            isCartModalOpen: !this.state.isCartModalOpen
        });

        let shoppingCartDesktopWrapper = document.getElementById("shoppingCartDesktopWrapper");
        const isArabic = this.props.language === ARABIC;

        if (isArabic) {
            if (shoppingCartDesktopWrapper !== null && shoppingCartDesktopWrapper.classList.value.includes("shoppingCartInactiveRTL")) {
                shoppingCartDesktopWrapper.classList.remove("shoppingCartInactiveRTL");
                shoppingCartDesktopWrapper.classList.add("shoppingCartActiveRTL");
            } else if (shoppingCartDesktopWrapper !== null && shoppingCartDesktopWrapper.classList.value.includes("shoppingCartActive")) {
                shoppingCartDesktopWrapper.classList.remove("shoppingCartActiveRTL");
                shoppingCartDesktopWrapper.classList.add("shoppingCartInactiveRTL");
            } else {
                shoppingCartDesktopWrapper.classList.add("shoppingCartActiveRTL");
            }
        } else {
            if (shoppingCartDesktopWrapper !== null && shoppingCartDesktopWrapper.classList.value.includes("shoppingCartInactive")) {
                shoppingCartDesktopWrapper.classList.remove("shoppingCartInactive");
                shoppingCartDesktopWrapper.classList.add("shoppingCartActive");
            } else if (shoppingCartDesktopWrapper !== null && shoppingCartDesktopWrapper.classList.value.includes("shoppingCartActive")) {
                shoppingCartDesktopWrapper.classList.remove("shoppingCartActive");
                shoppingCartDesktopWrapper.classList.add("shoppingCartInactive");
            } else {
                shoppingCartDesktopWrapper.classList.add("shoppingCartActive");
            }
        }
    }

    renderShoppingCart() {
        const isArabic = this.props.language === ARABIC;
        
        if (this.props.menus !== undefined && this.props.menus !== null && this.props.menus.length > 0) {
			return (
				<Col style={{ position: 'absolute', right: 0, zIndex: '10' }}>
					<div style={isArabic ? { display: 'flex', flexDirection: 'column', paddingLeft: '10px' } : { display: 'flex', flexDirection: 'column', paddingRight: '10px' }}>
						<div className="stickyProfile" style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
							<CartItemsButton
								{...this.props}
								isArabic={isArabic}
								toggleCartModal={this.toggleCartModal.bind(this)}
							/>
						</div>
					</div>
				</Col>
			);
        }
    }

    renderMenuScreenContent() {
        const isArabic = this.props.language === ARABIC;
        const enableLargeImageDesign = this.props.restaurant ? this.props.restaurant.enableLargeImageDesign : null;
        let shoppingCartDesktopWrapper = document.getElementById("shoppingCartDesktopWrapper");
        const shoppingCartWrapperStyle = shoppingCartDesktopWrapper && (shoppingCartDesktopWrapper.classList.value.includes("shoppingCartActive") || shoppingCartDesktopWrapper.classList.value.includes("shoppingCartActiveRTL")) ? { width: '100%' } : null;
        
        return (
            <Fragment>
                <DetailsScreen
                    history={this.props.history}
                    customer={this.props.customer}
                    brandName={this.props.brandName}
                    brand={this.props.brand}
                    restaurant={this.props.restaurant}
                    bannerImagePath={this.props.bannerImagePath}
                    orderTypes={this.props.orderTypes}
                    selectedOrderType={this.props.selectedOrderType}
                    estimateOrderTime={this.props.estimateOrderTime}
                    temporaryOrderTime={this.props.temporaryOrderTime}
                    changeOrderTimeScreenOpened={this.props.changeOrderTimeScreenOpened}
                    selectedDeliveryAddress={this.props.selectedDeliveryAddress}
                    menuPreviewMode={this.props.menuPreviewMode}
                    customerThemes={this.props.customerThemes}
                    shoppingCart={this.props.shoppingCart}
                    clearWizardData={this.props.clearWizardData}
                    clearState={this.props.clearState}
                    clearTemporaryOrderTimeData={this.props.clearTemporaryOrderTimeData}
                    availableDiscounts={this.props.availableDiscounts}
                    loyalty={this.props.loyalty}
                    isArabic={isArabic}
                />

                <MenusAndProductsScreen
                    loading={this.props.loading}
                    customer={this.props.customer}
                    restaurant={this.props.restaurant}
                    menus={this.props.menus}
                    menuPreviewMode={this.props.menuPreviewMode}
                    categories={this.props.categories}
                    products={this.props.products}
                    shoppingCart={this.props.shoppingCart}
                    selectedProduct={this.props.selectedProduct}
                    errorMessage={this.props.errorMessage}
                    alertMessage={this.props.alertMessage}
                    customerThemes={this.props.customerThemes}
                    startOrder={this.props.startOrder}
                    availableDiscounts={this.props.availableDiscounts}
                    isPwaApp={true}
                    selectRestaurantMenu={this.props.selectRestaurantMenu}
                    selectedMenuArrayIndex={this.props.selectedMenuArrayIndex}
                    currentMenuArrayIndex={this.props.currentMenuArrayIndex}
                    selectProduct={this.selectProduct.bind(this)}
                    clearMessageAlert={this.props.clearMessageAlert}
                    generalNote={this.props.menuDescription.generalNote}
                    isArabic={isArabic}
                    enableLargeImageDesign={enableLargeImageDesign}
                    toggleSearchProductFocus={this.props.toggleSearchProductFocus}
                    isShoppingCartModalOpen={this.props.isShoppingCartModalOpen}
                    toggleShoppingCartModal={this.props.toggleShoppingCartModal}
                    renderShoppingCart={this.renderShoppingCart.bind(this)}
                />

                {
                    this.state.isCartModalOpen ?
                        <div onClick={() => this.toggleCartModal()} style={{ width: '100wh', height: '100vh', position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, zIndex: 15, backgroundColor: 'rgba(0, 0, 0, 0.5)', paddingBottom: isIOS ? '12px' : '0' }}></div> : null
                }
                <div id="shoppingCartDesktopWrapper" className={isArabic ? "shoppingCartDesktopWrapperRTL" : "shoppingCartDesktopWrapper"} style={shoppingCartWrapperStyle}>
                    <ShoppingCart
                        isCheckout={false}
                        // orderButton={checkoutButton}
                        history={this.props.history}
                        editProductFromCart={this.editProductFromCart}
                        selectProduct={this.selectProduct.bind(this)}
                        serviceNote={this.props.menuDescription.serviceNote}
                        enableLargeImageDesign={enableLargeImageDesign}
                        toggleCartModal={this.toggleCartModal.bind(this)}
                        isCartModalOpen={this.state.isCartModalOpen}
                    />
                </div>
                {
                    this.state.isFreeProductModalOpen ?
                        <CommonModal isOpen={this.state.isFreeProductModalOpen} dialogName={`freeProduct`} toggle={this.toggleFreeProductModal} className='freeProduct'>
                            <FreeProduct
                                shoppingCart={this.props.shoppingCart}
                                onToggle={this.toggleFreeProductModal}
                                onEdit={this.editProductFromCart}
                                customerThemes={this.props.customerThemes}
                            />
                        </CommonModal> : null
                }
            </Fragment>
        );
    }

    render() {
        const isArabic = this.props.language === ARABIC;
        const headerText = isArabic ? `${this.props.restaurant.name} ${this.props.brand.name}` : `${this.props.brand.name} ${this.props.restaurant.name}`
        return (
            <ApplicationStep
                hideBackButton={true}
                transparentHeader={true}
                text={headerText}
                content={this.renderMenuScreenContent()}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer,
        brandName: state.brand.name,
        brandId: state.brand.id,
        restaurant: state.selectedRestaurant,
        bannerImagePath: state.orderTypes.bannerImagePath,
        orderTypes: state.orderTypes.orderTypes,
        selectedOrderType: state.orderTypes.selectedOrderType,
        estimateOrderTime: state.estimateOrderTime,
        temporaryOrderTime: state.temporaryOrderTime,
        changeOrderTimeScreenOpened: state.temporaryOrderTime.changeOrderTimeScreenOpened,
        selectedDeliveryAddress: state.customer.selectedDeliveryAddress,
        menuPreviewMode: state.menuPreviewMode.previewMode,
        customerThemes: state.customerThemes,
        loading: state.loading,
        menus: state.restaurantMenus.menus,
        currentMenuArrayIndex: state.restaurantMenus.selectedMenuArrayIndex,
        categories: state.restaurantCategories,
        products: state.restaurantProducts,
        selectedProduct: state.selectedProduct,
        errorMessage: state.errorMessage,
        alertMessage: state.alertMessage,
        startOrder: state.startOrder,
        brand: state.brand,
        availableDiscounts: state.availableDiscounts,
        loyalty: state.loyalty,
        pwaAppRunning: state.pwaAppRunning,
        menuDescription: state.menuDescription,
        shoppingCart: state.shoppingCart,
        payment: state.payment,
        selectedRestaurant: state.selectedRestaurant,
        paymentProvider: state.paymentProvider,
        storeLocalStorage: state.storeLocalStorage,
        promotionPartners: state.promotionPartners,
        language: state.storeLocalStorage.language,
        isShoppingCartModalOpen: state.commonReducer.isShoppingCartModalOpen,
        currentProductsQty: state.productsQty.currentProductsQty
    };
};

export default connect(mapStateToProps, {
    logOutCustomer, clearWizardData, clearState, clearTemporaryOrderTimeData, openChangeOrderTimeScreen, saveNewOrderTime, loadAllAvailableDiscounts,
    getRestaurantAvailableMenus, getSelectedProduct, getSelectedProductForEdit, clearMessageAlert, selectRestaurantMenu, openStartOrderScreen,
    selectedMenuArrayIndex, getOldestActiveLoyalty, dispatchPromoCodeToStore, getServiceMessages, clearServiceMessages, changeAuthipayBackBtnFlag,
    editProduct,
    getComoMemberDetails, addProductToCart, toggleSearchProductFocus, toggleShoppingCartModal, setProductsQty
})(MenuScreenPwa);