import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Button,
} from 'reactstrap';
import i18n from '../../../../i18next';
import {
    isIOS,
    isAndroid,
    isMobileOnly,
    isMacOs
} from 'mobile-device-detect';

import {
    setMenuOrOrderHistoryScreen,
    clickAppleGooglePay,
    togglePromoPartnerModal,
    togglePromotionsModal,
    toggleShoppingCartModal,
    placeOrder,
    openStartOrderScreen
} from '../../../../actions';

import ApplePaySVG from '../../../../pwa-app/components/common/svgComponents/ApplePaySVG';
import GooglePaySVG from '../../../../pwa-app/components/common/svgComponents/GooglePaySVG';
import MobileMenuScreenContent from './MobileMenuScreenContent';

class ShoppingCartButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCheckout: false,
            isCartScreen: false,
            isPromotionsScreen: false,
            isMenuScreen: false,
            isMenuScreenPwa: false
        }

        this.btnRef = React.createRef();
    }

    componentDidMount() {
        const { brandId } = this.props
        // Checks if rendered from MenuScreen or CheckoutScreen
        this.setState({
            ...this.state,
            isCheckout: window.location.href.includes('/checkout'),
            isCartScreen: window.location.href.includes(`/brand/${brandId}/order/cart`),
            isPromotionsScreen: window.location.href.includes(`/brand/${brandId}/order/promotions`),
            isMenuScreen: window.location.href.includes('menu-screen'),
            isMenuScreenPwa: window.location.href.includes(`/brand/${brandId}/order/menu`)
        });
    }

    componentDidUpdate(prevProps) {
        const { isCheckout } = this.state;
        const isIOSCheckoutTabletViewButton = (window.innerWidth >= 550) && (isIOS || isMacOs) && isCheckout && this.props.isAppleGooglePaySelected;
        if(prevProps.isAppleGooglePaySelected !== this.props.isAppleGooglePaySelected) {
            this.styles.stickedToBottomMenuScr.bottom = (this.props.isAppleGooglePaySelected && isAndroid) ? -30 : 0
            this.styles.stickedToBottomCheckout.width = (this.props.isAppleGooglePaySelected && isAndroid) ? 252 : window.innerWidth > 768 ? 'unset' : '100%'
            this.styles.mainBtnApplePayStyle.left = (isAndroid && isCheckout && this.props.isAppleGooglePaySelected)  ? '50%' : isIOSCheckoutTabletViewButton ? '0' : {}
            this.styles.mainBtnApplePayStyle.transform = (isAndroid && isCheckout && this.props.isAppleGooglePaySelected) ? 'translate(-50%, -50%)' : {}
        }
        
        if (this.props.customerThemes.selectedTheme.type !== prevProps.customerThemes.selectedTheme.type
            && window.location.href.includes('previewMode')) {
                // Update mainBtnStyle
                this.styles.mainBtnStyle.backgroundColor = this.props.customerThemes.selectedTheme.primaryColor;
                this.styles.mainBtnStyle.fontFamily = this.props.customerThemes.selectedTheme.fontFamilyType;
                this.styles.mainBtnStyle.fontWeight = this.props.customerThemes.selectedTheme.fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300;
                this.styles.mainBtnStyle.fontStyle = this.props.customerThemes.selectedTheme.fontItalic;
                this.styles.mainBtnStyle.textTransform = this.props.customerThemes.selectedTheme.uppercase;
                this.styles.mainBtnStyle.color = this.props.customerThemes.selectedTheme.buttonsFont;
                // Update mainMenuBtnStyle
                this.styles.mainMenuBtnStyle.backgroundColor = this.props.customerThemes.selectedTheme.primaryColor;
                this.styles.mainMenuBtnStyle.fontFamily = this.props.customerThemes.selectedTheme.fontFamilyType;
                this.styles.mainMenuBtnStyle.fontWeight = this.props.customerThemes.selectedTheme.fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300;
                this.styles.mainMenuBtnStyle.fontStyle = this.props.customerThemes.selectedTheme.fontItalic;
                this.styles.mainMenuBtnStyle.textTransform = this.props.customerThemes.selectedTheme.uppercase;
                this.styles.mainMenuBtnStyle.color = this.props.customerThemes.selectedTheme.buttonsFont;
                // Update disabled 
                this.styles.disabled.backgroundColor = this.props.customerThemes.selectedTheme.inactiveColor;
        }
    }

    renderBtnContent() {

        const { isCheckout, isMenuScreen, isCartScreen, isPromotionsScreen } = this.state;
        const { fontFamilyType, fontBold, fontItalic, uppercase, buttonsFont } = this.props.customerThemes.selectedTheme;
        const { isAppleGooglePaySelected, isCartModalOpen, isPromotionsModalOpen, promotionPartners, pwaAppRunning } = this.props;
        const isMobileView = window.innerWidth <= 865;
        const isPromotionProviders = promotionPartners.promotionPartner ? promotionPartners.promotionPartner.id !== 1 ? true : false : false;
        const btnStyles = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase,
            color: buttonsFont
        }

        // Mobile and Apps Menu Screen
        // if ((isMobileView || pwaAppRunning) && !isCheckout && !isCartScreen && !isPromotionsScreen && !isShoppingCartModalOpen && !isPromotionsModalOpen) {

        //     return <MobileMenuScreenContent {...this.props} />;

        //     // Mobile and Apps Shopping Cart
        if (((isMobileView || pwaAppRunning) && isCartModalOpen && !isCheckout)) {

            return (
                <div style={{ margin: '0 auto' }}>
                    <span style={btnStyles}>{i18n.t(`screens:shoppingCart.${isPromotionProviders ? 'confirm' : 'checkOutNow'}`)}</span>
                </div>
            )
        } else if (isPromotionsModalOpen || isPromotionsScreen) {

            return (
                <div style={{ margin: '0 auto' }}>
                    <span style={btnStyles}>{i18n.t('screens:shoppingCart.checkOutNow')}</span>
                </div>
            )
        } else if ((isMenuScreen && !isMobileView && !pwaAppRunning && !isPromotionsModalOpen)) {

            return (
                <div style={{ margin: '0 auto' }}>
                    <span style={btnStyles}>{i18n.t(`screens:shoppingCart.${isPromotionProviders ? 'confirm' : 'checkOutNow'}`)}</span>
                </div>
            )
        }

        if (isCheckout) {

            if (isAppleGooglePaySelected && (isIOS || isMacOs)) {

                return <ApplePaySVG
                    width={isMobileOnly ? 70 : 40}
                    height={isMobileOnly ? 50 : 30}
                    fill={"#000"} />
            }

            if (isAppleGooglePaySelected && isAndroid) {
                return <GooglePaySVG
                width={isMobileOnly ? 120 : 60}
                height={isMobileOnly ? 20 : 30} />
                // fill={"#606469"}
            }

            return i18n.t('screens:shoppingCart.placeOrder');
        }

        return i18n.t('screens:shoppingCart.checkOutNow');
    }

    addAttributesDynamically = () => {

        const { isCheckout, isPromotionsScreen, isMenuScreen, isMenuScreenPwa } = this.state;
        const { isAppleGooglePaySelected, shoppingCart, isPromotionsModalOpen, promotionPartners, pwaAppRunning } = this.props;

        const enabled = this.isBtnEnabledDisabled();
        const isMobileView = window.innerWidth <= 865;
        const isLoggedInPromotion = promotionPartners.isUserLoggedInPartner;
        
        let combinedStyles = isCheckout && isAppleGooglePaySelected ? { ...this.styles.mainBtnApplePayStyle } : (isPromotionsModalOpen || isPromotionsScreen) && !isLoggedInPromotion ? { ...this.styles.mainBtnPromotionsStyle } : !isPromotionsModalOpen && !isCheckout ? { ...this.styles.mainMenuBtnStyle } : { ...this.styles.mainBtnStyle };
        let attributes = {
            disabled: false,
            style: combinedStyles
        }

        if (!enabled) {
            attributes.disabled = true;
            attributes.style = { ...combinedStyles, ...this.styles.disabled };
        }

        if ((isMobileView || isMobileOnly) && !isMenuScreen && !isMenuScreenPwa ) {
           
            if (!this.props.isCartModalOpen) {
                attributes.style = { ...combinedStyles, ...this.styles.stickedToBottomMenuScr };
            }
        }
        // MenuScreen
        if (!isCheckout) {

            attributes = this.getMenuScreenAttributes(attributes);

        } else if (isCheckout) { // Checkout Screen

            attributes.style = { ...attributes.style, ...this.styles.stickedToBottomCheckout };
            attributes = this.getPaymentProviderAttributes(attributes);
            attributes = this.getPromoPartnerAttributes(attributes);
        }

        return attributes;
    }

    getMenuScreenAttributes = (attributes) => {

        const { isMenuScreen, isCartScreen, isPromotionsScreen, isMenuScreenPwa } = this.state;
        const { customer, pwaAppRunning, selectedRestaurant, brandId, promotionPartners, isPromotionsModalOpen, isCartModalOpen } = this.props;
        const isPromotionProviders = promotionPartners.promotionPartner ? promotionPartners.promotionPartner.id !== 1 ? true : false : false;
        const isMobileView = window.innerWidth <= 865;


        //  When Customer is Logged in (APP)
        if (pwaAppRunning && customer.token) {

            if (isMenuScreenPwa) {
                if (isPromotionProviders) {

                    attributes.onClick = () => this.props.history.push(`/brand/${brandId}/order/promotions`);

                } else {

                    attributes.onClick = () => this.props.history.push(`/brand/${brandId}/order/checkout`);

                    if (selectedRestaurant.deliveryTipEnabled) {

                        attributes.onClick = () => this.props.history.push(`/brand/${brandId}/order/tip`);

                    }
                } 
            } else if (isPromotionsScreen) {

                attributes.onClick = () => this.props.history.push(`/brand/${brandId}/order/checkout`);

                if (selectedRestaurant.deliveryTipEnabled) {

                    attributes.onClick = () => this.props.history.push(`/brand/${brandId}/order/tip`);

                }
            }
            // When Customer isn't Logged in (APP)
        } else if (pwaAppRunning && !customer.token) {

            attributes.onClick = () => this.props.history.push(`/brand/${brandId}/profile/login/phone-number`);

            // When Customer is Logged in (Desktop View)
        } else if (!pwaAppRunning && !isMobileView && isMenuScreen && customer.token) {

            if (isPromotionProviders && !isPromotionsModalOpen && !this.props.como.getMembershipDetailsInProgress) {

                attributes.onClick = () => {
                    this.props.togglePromotionsModal();
                    this.props.toggleCartModal();
                };

            } else if (isPromotionProviders && isPromotionsModalOpen) {

                attributes.onClick = () => {
                    this.props.setMenuOrOrderHistoryScreen(true);
                    this.props.history.push('/checkout');
                    this.props.togglePromotionsModal();
                };

            } else if (!isPromotionProviders) {

                attributes.onClick = () => {
                    this.props.setMenuOrOrderHistoryScreen(true);
                    this.props.history.push('/checkout');
                };
            }

            // When Customer is Logged in (Mobile View)
        } else if (!pwaAppRunning && isMobileView && isMenuScreen && customer.token) {

            if (isCartModalOpen && !isPromotionProviders) {

                attributes.onClick = () => {
                    this.props.setMenuOrOrderHistoryScreen(true);
                    this.props.history.push('/checkout');
                    // this.props.toggleShoppingCartModal();
                };

            } else if (isCartModalOpen && isPromotionProviders) {

                attributes.onClick = () => {
                    this.props.togglePromotionsModal();
                };


            } else if (isPromotionsModalOpen) {

                attributes.onClick = () => {
                    this.props.setMenuOrOrderHistoryScreen(true);
                    this.props.history.push('/checkout');
                    this.props.togglePromotionsModal();
                };
            }

            // When Customer isn't Logged in (Desktop and Mobile View)
        } else if (!pwaAppRunning && !customer.token) {

            attributes.onClick = () => {
                this.props.setMenuOrOrderHistoryScreen(true);
                this.props.openStartOrderScreen('/menu-screen');
            };

        } else if (Boolean(this.props.menuPreviewMode)) {

            attributes.onClick = () => this.props.history.push('/checkout');
        }

        return attributes;
    }

    getPaymentProviderAttributes = (attributes) => {

        const {
            payment,
            isCardValid,
            paymentProvider,
            isAppleGooglePaySelected
        } = this.props;

        if (isAppleGooglePaySelected) {

            return {
                ...attributes,
                onClick: () => this.props.clickAppleGooglePay()
            };
        }
        // selectedPaymentType.id === 1 - Card
        if (payment.selectedPaymentType.id === 1) {

            const hasProvider = paymentProvider.providerType ? true : false;
            // if card in the input field is valid or has saved card selected
            if (!isCardValid || (!payment.selectedPaymentCard.paymentCardId && !isCardValid)) {

                attributes.disabled = true;
                attributes.style = {
                    ...attributes.style,
                    ...this.styles.disabled
                };

                return attributes;
            }

            return {
                ...attributes,
                type: 'submit',
                form: !hasProvider ? '' : this.selectAttributeAccordingToProvider(paymentProvider.providerType.id).form,
                id: !hasProvider ? '' : this.selectAttributeAccordingToProvider(paymentProvider.providerType.id).id
            };
        }
        // Cash
        return {
            ...attributes,
            onClick: () => this.props.placeOrder(undefined, undefined)
        };
    }

    getPromoPartnerAttributes = (attributes) => {

        const { promotionPartners, como } = this.props;

        if (promotionPartners.promotionPartner
            && promotionPartners.promotionPartner.id === 2// Como
            && !como.userRegistrationTriggered
            && !promotionPartners.isUserLoggedInPartner && !promotionPartners.userAnswer && !this.state.isCheckout) {

            return {
                ...attributes,
                onClick: () => this.props.togglePromoPartnerModal(),
                type: '',
                id: '',
                form: ''
            }
        }

        return attributes;
    }

    isBtnEnabledDisabled = () => {

        const { shoppingCart, selectedRestaurant, payment, pwaAppRunning,isPromotionsModalOpen, isCartModalOpen } = this.props;
        const { discount, total } = this.props.shoppingCart;
        const { isMenuScreen, isMenuScreenPwa } = this.state;
        const isMobileView = window.innerWidth <= 865;
        let enabled = false;

        let discountTypeFreeProduct = false;
        shoppingCart.orderProducts.map(orderProduct => {
            if (orderProduct.promotionFreeProduct) {
                return discountTypeFreeProduct = true;
            }
        });

        const minOrderAmountHidden = selectedRestaurant.minimumOrderAmount === undefined || selectedRestaurant.minimumOrderAmount === null || selectedRestaurant.minimumOrderAmount === 0;
        const maxOrderAmountHidden = selectedRestaurant.maximumOrderAmount === undefined || selectedRestaurant.maximumOrderAmount === null || selectedRestaurant.mmaximumOrderAmount === 0;
        const hideOrderAmountMsg = minOrderAmountHidden && maxOrderAmountHidden;

        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;
        const isGiftCardsTotalZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total === 0 ? true  : false : false;

        let currentMinimumOrderAmount = shoppingCart.appliedPromotions.length ? 1 : selectedRestaurant.minimumOrderAmount;

        if (shoppingCart.orderProducts.length > 0 && !Boolean(this.props.menuPreviewMode)) {
            if (hideOrderAmountMsg) {
                enabled = true;
            } else if (!minOrderAmountHidden && maxOrderAmountHidden && currentMinimumOrderAmount <= total) {
                enabled = true;
            } else if (minOrderAmountHidden && !maxOrderAmountHidden && selectedRestaurant.maximumOrderAmount >= total) {
                enabled = true;
            } else if (!hideOrderAmountMsg && currentMinimumOrderAmount <= total && selectedRestaurant.maximumOrderAmount >= total) {
                enabled = true;
            } else if (discountTypeFreeProduct && !hideOrderAmountMsg && currentMinimumOrderAmount <= (total - discount) && selectedRestaurant.maximumOrderAmount >= (total - discount)) {
                enabled = true;
            } else {
                enabled = false;
            }
        } else {
            enabled = false;
        }

        if (this.props.shoppingCart.discount > 0 && this.props.shoppingCart.total === 0 && !Boolean(this.props.menuPreviewMode)) {
            enabled = true;
        }

        if (payment.selectedPaymentType.id === null && this.state.isCheckout) {
            enabled = false;
        } else if (this.props.paymentId === null) { // this check is when not have cash as option and total is less than discount
            enabled = false;
        }

        if (this.props.isAppleGooglePaySelected && !Boolean(this.props.menuPreviewMode)) {
            if (!total > 0) {
                enabled = false;
            } else {
                enabled = true;
            }
        }
        if (this.props.shoppingCart.promoCallsCount > 1) {
            enabled = false;
        }

        if (pwaAppRunning || isMobileView) {
           if ((isMenuScreenPwa || isMenuScreen) && this.props.shoppingCart.orderProducts.length > 0) {
                enabled = true;
            }
        }

        if(isAppliedGiftCards && isGiftCardsTotalZero) {
            enabled = true
        }

        return enabled;
    }

    selectAttributeAccordingToProvider = (providerID) => {

        let returnValue = {
            id: -1,
            form: ''
        };

        switch (providerID) {
            case 1:
                returnValue.form = 'payment-form';
                returnValue.id = 'stripeSubmitBtn';
                break;
            case 2:
                returnValue.form = 'braintreeForm';
                returnValue.id = 'braintreeSubmitBtn';
                break;
            case 3:
                returnValue.form = 'mollieForm';
                returnValue.id = 'mollieSubmitBtn';
                break;
            case 4:
                returnValue.form = 'authipayForm';
                returnValue.id = 'authipaySubmitBtn';
                break;
            case 5:
                returnValue.form = 'monerisForm';
                returnValue.id = 'monerisSubmitBtn';
                break;
            case 7:
                returnValue.form = 'worldPayForm';
                returnValue.id = 'worldPaySubmitBtn';
                break;
            case 8:
                returnValue.form = 'bamboraForm';
                returnValue.id = 'bamboraSubmitBtn';
                break;
            default:
                break;
        }

        return returnValue;
    }

    styles = {
        mainBtnStyle: {
            margin: 15,
            height: 50,
            backgroundColor: this.props.customerThemes.selectedTheme.primaryColor,
            border: 0,
            borderRadius: 0,
            zIndex: 1,
            flexShrink: 0,
            fontFamily: this.props.customerThemes.selectedTheme.fontFamilyType,
            fontWeight: this.props.customerThemes.selectedTheme.fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300,
            fontStyle: this.props.customerThemes.selectedTheme.fontItalic,
            textTransform: this.props.customerThemes.selectedTheme.uppercase,
            color: this.props.customerThemes.selectedTheme.buttonsFont,
        },

        mainMenuBtnStyle: {
            width: '85%',
            margin: 15,
            height: 50,
            backgroundColor: this.props.customerThemes.selectedTheme.primaryColor,
            border: 0,
            borderRadius: 0,
            zIndex: 1,
            flexShrink: 0,
            fontFamily: this.props.customerThemes.selectedTheme.fontFamilyType,
            fontWeight: this.props.customerThemes.selectedTheme.fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300,
            fontStyle: this.props.customerThemes.selectedTheme.fontItalic,
            textTransform: this.props.customerThemes.selectedTheme.uppercase,
            color: this.props.customerThemes.selectedTheme.buttonsFont,
        },

        mainBtnApplePayStyle: {
            margin: 15,
            height: "50pt",
            border: isAndroid ? "1.5px solid #747775" : "2.4pt solid #000",
            borderRadius: isAndroid ? "8vh" : "4px",
            backgroundColor: isAndroid ? "#000" : "#FFF",
            zIndex: 1,
            flexShrink: 0,
            fontFamily: this.props.customerThemes.selectedTheme.fontFamilyType,
            fontWeight: this.props.customerThemes.selectedTheme.fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300,
            fontStyle: this.props.customerThemes.selectedTheme.fontItalic,
            textTransform: this.props.customerThemes.selectedTheme.uppercase,
            color: this.props.customerThemes.selectedTheme.buttonsFont,
        },
        disabled: {
            backgroundColor: this.props.customerThemes.selectedTheme.inactiveColor,
            opacity: 1,
            zIndex: 5
        },
        stickedToBottomMenuScr: {
            position: 'fixed',
            bottom: 0,
            margin: 0,
            width: '100%',
            height: 65,
            fontSize: 22,
            color: this.props.customerThemes.selectedTheme.buttonsFont,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 1040,
            fontFamily: this.props.customerThemes.selectedTheme.fontFamilyType,
            fontWeight: this.props.customerThemes.selectedTheme.fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300,
            fontStyle: this.props.customerThemes.selectedTheme.fontItalic,
            textTransform: this.props.customerThemes.selectedTheme.uppercase,
        },
        stickedToBottomCheckout: {
            justifyContent: 'center'
        },
        mainBtnPromotionsStyle: {
            margin: 0,
            height: 50,
            width: '100%',
            backgroundColor: this.props.customerThemes.selectedTheme.primaryColor,
            border: 0,
            borderRadius: 0,
            zIndex: 1,
            flexShrink: 0,
            fontFamily: this.props.customerThemes.selectedTheme.fontFamilyType,
            fontWeight: this.props.customerThemes.selectedTheme.fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300,
            fontStyle: this.props.customerThemes.selectedTheme.fontItalic,
            textTransform: this.props.customerThemes.selectedTheme.uppercase,
            color: this.props.customerThemes.selectedTheme.buttonsFont,
        },
    }

    render = () => {
        const btnAttributes = this.addAttributesDynamically();

        return <Button
            id='shoppingCartButton'
            ref={this.btnRef}
            style={this.props.isPromotionsModalOpen ? { width: '100%', margin: 0 } : null}
            {...btnAttributes}>
            {this.renderBtnContent()}
        </Button>;
    }
}

const mapStateToProps = state => {
    return {
        selectedRestaurant: state.selectedRestaurant,
        promotionPartners: state.promotionPartners,
        como: state.como,
        customerThemes: state.customerThemes,
        pwaAppRunning: state.pwaAppRunning,
        shoppingCart: state.shoppingCart,
        isPromotionsModalOpen: state.commonReducer.isPromotionsModalOpen,
        customer: state.customer,
        brandId: state.brand.id,
        menuPreviewMode: state.menuPreviewMode.previewMode,
        payment: state.payment,
        paymentProvider: state.paymentProvider,
        temporaryOrderTime: state.temporaryOrderTime,
        language: state.storeLocalStorage.language,
        giftCards: state.giftCards
    }
}

export default connect(
    mapStateToProps,
    {
        setMenuOrOrderHistoryScreen,
        clickAppleGooglePay,
        togglePromoPartnerModal,
        togglePromotionsModal,
        toggleShoppingCartModal,
        placeOrder,
        openStartOrderScreen
    }
)(ShoppingCartButton);