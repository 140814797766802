import _ from 'lodash';
import axios from '../../utils/AxiosUtils';
import * as i18n from "i18next";
import { push } from "connected-react-router";
import { isIOS } from 'mobile-device-detect';
import moment from "moment/moment";

import {
    DELIVERY_ID,
    IN_STORE_ID,
    CURBSIDE_ID
} from '../../utils/Constants';
import {
    ADD_OR_SUBSTRACT_QTY_TO_CART,
    REMOVE_ITEM_FROM_CART,
    INCREASE_OR_DECREASE_TIP,
    ADD_PRODUCT_TO_CART,
    SAVE_GENERAL_INSTRUCTIONS,
    OPEN_SENDING_ORDER_MODAL,
    CLOSE_SENDING_ORDER_MODAL,
    CLEAR_SHOPPING_CART,
    CLEAR_STATE,
    APPLY_PROMOTION_SUCCESS,
    SHOW_ERROR_MESSAGE,
    DISPATCH_PROMO_CODE,
    LOADING_NO_BACKGROUND,
    CLEAR_CURRENT_PATH_AFTER_USE,
    DISPATCH_SERVICE_FEE,
    CLEAR_PAYMENT_REDUCER,
    MOLLIE_RESPONSE_STATUS,
    AUTHIPAY_RESPONSE_STATUS,
    TOGGLE_MENU_ORDER_HISTORY_SCREEN,
    INCREASE_OR_DECREASE_PROMO_SERVICE_CALLS_COUNT,
    APPLY_PROMO_CODE,
    VOID_PURCHASE,
    RESET_OLD_DISCOUNT_FLAG,
    SEND_RESPONSE_TO_ORDER_MODAL,
    CLOSE_SENDING_MODAL,
    PRODUCTS_QTY
} from '../types';
import {
    formatGeoLocatedCountryToCountryCode,
    getQueryInfoFromUrl,
    caclculateProductTax,
    getOrderSource,
    transformProductPrice
} from "../../utils/CommonUtils";

import {
    getAppliedPromotions,
    getPromotionProducts,
    transformShoppingCartProducts
} from '../../utils/DataTransformationUtils';
import { shoppingCartCalculations, handleItemToShoppingCart } from '../../utils/ShoppingCartUtils';
import { pushDataToGoogleTagManager } from '../../utils/AnalyticsUtils';
import { logoutCustomer, saveFirstStamp, changeCodeManuallySet } from '../index';
import { makeApplePayPaymentSuccess } from "../../pwa-app/utils/ApplePayUtils";
import { getComoBenefits } from '../index';
import PromotionPartnerUtils from '../../utils/promotions/PromotionPartnerUtils';

let dateBeforePlaceOrderRequestSent = null;
let dateAfterPlaceOrderResponseReceived = null;

export const calculatePromotion = () => {
    return (dispatch, getState) => {

        doCalculatePromotion(dispatch, getState);
    };
};

// export const doCalculatePromotion = (dispatch, getState) => {

export const doCalculatePromotion = (dispatch, getState) => {

    if (getState().promotionPartners.promotionPartner && getState().promotionPartners.promotionPartner.id !== 1) {
        return null;
    }
    let restaurantId;
    const { estimateOrderTime, selectedRestaurant, menuPreviewMode, shoppingCart, orderTypes, customer } = getState();

    if (selectedRestaurant.restaurantId) {
        restaurantId = selectedRestaurant.restaurantId
    } else {
        restaurantId = menuPreviewMode.restaurantId
    }

    let applyPromotionsDto = {}
    let copyOfOrderProducts = shoppingCart.orderProducts.slice();
    let productsToSendForApplyPromotion = transformShoppingCartProducts(copyOfOrderProducts, selectedRestaurant.allowToppingSubstitution);

    if (productsToSendForApplyPromotion.length > 0) {
        const currentDateAndTime = moment().add(selectedRestaurant.restaurantTimeOffset, 'milliseconds').format('DD-MM-YYYY HH:mm:ss');
        const wantedTime = (estimateOrderTime.wantedTime) ? estimateOrderTime.wantedTime : currentDateAndTime;

        applyPromotionsDto = {
            orderedProducts: productsToSendForApplyPromotion,
            total: Number(Math.round(shoppingCart.subtotal + 'e2') + 'e-2'),
            orderType: orderTypes.selectedOrderType,
            promoCode: shoppingCart.promoCode,
            wantedTime: wantedTime,
            customerId: customer.id ? customer.id : null
        }
        let copyOfDtoApplyPromotions = applyPromotionsDto.orderedProducts.slice();

        applyPromotionsDto.orderedProducts.map((orderedProduct, orderedProductIndex) => {
            if (orderedProduct.promotionFreeProduct) {
                orderedProduct.orderQuestions.map((orderQuestion, orderQuestionIndex) => {
                    if (orderQuestion.question.id === -111) {
                        copyOfDtoApplyPromotions[orderedProductIndex].orderQuestions.splice(orderQuestionIndex, 1);
                    }
                });
            }
        });
        applyPromotionsDto.orderedProducts = copyOfDtoApplyPromotions;

        applyPromotionsDto.orderedProducts.map(orderProduct => {
            orderProduct['taxAmount'] = caclculateProductTax(orderProduct);
        });

        const url = `/restaurant/${restaurantId}/order/promotion?previewMode=${menuPreviewMode.previewMode}`;

        dispatch({ type: INCREASE_OR_DECREASE_PROMO_SERVICE_CALLS_COUNT, payload: getState().shoppingCart.promoCallsCount + 1 });

        axios(getState).post(url, applyPromotionsDto, dispatch, getState)
            .then(response => doCalculatePromotionSuccess(dispatch, response, getState));
    } else { // It is neccessary - without this clear a lot of data stays when it should not 

        dispatch({ type: CLEAR_SHOPPING_CART });
    }
};

const doCalculatePromotionSuccess = (dispatch, response, getState) => {

    const responseData = response.data.data[0];

    const promotionData = {
        freeDelivery: responseData.freeDelivery,
        appliedPromotions: getAppliedPromotions(responseData.appliedPromotions),
        promoCode: responseData.promoCode,
        orderProducts: getPromotionProducts(responseData.orderedProducts, getState().shoppingCart.orderProducts) // handles applyPromotion response
    };

    if (responseData.appliedPromotions.length) {
        const filteredPromo = {
            id: responseData.appliedPromotions[0].promotionId,
            name: responseData.appliedPromotions[0].promotionName
        };
        pushDataToGoogleTagManager('event', 'promotionClick', getState().pwaAppRunning, filteredPromo);
    }

    dispatch({ type: APPLY_PROMOTION_SUCCESS, payload: promotionData });

    if (!promotionData.appliedPromotions.length) {
        dispatch({ type: RESET_OLD_DISCOUNT_FLAG })
    }

    dispatch({ type: SHOW_ERROR_MESSAGE, payload: response.data.userMessage });
    //TODO should be refactored when handeling slow server responses
    let updatedShopingCart = updateShoppingCartState(getState, undefined);
    dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart });

    // decrease INCREASE_OR_DECREASE_PROMO_SERVICE_CALLS_COUNT
    dispatch({ type: INCREASE_OR_DECREASE_PROMO_SERVICE_CALLS_COUNT, payload: getState().shoppingCart.promoCallsCount - 1 });
}

export const dispatchPromoCodeToStore = (promoCode, promoCodeDeletedFromUI, loyaltyCode, isPromoCode) => {

    return (dispatch, getState) => {

        if (getState().promotionPartners.promotionPartner && getState().promotionPartners.promotionPartner.id === 2) {

            if (!promoCode) return null;

            let redeemedAssets = getState().como.userSavedData.getBenefitsResponse && getState().como.userSavedData.getBenefitsResponse.redeemAssets ? getState().como.userSavedData.getBenefitsResponse.redeemAssets.filter(asset => asset.key) : [];

            let codeAsset = { code: promoCode };
            redeemedAssets.push(codeAsset);
            dispatch({ type: APPLY_PROMO_CODE, payload: promoCode });
            dispatch(getComoBenefits(redeemedAssets, isPromoCode));

        } else {

            dispatch({ type: DISPATCH_PROMO_CODE, payload: promoCode });
            // if promoCode is delete from UI or user has typed promoCode without enter and clicks Checkout Now
            if (promoCodeDeletedFromUI === null) {
                // dispatch({ type: SHOW_ERROR_MESSAGE, payload: promoCode});
                doCalculatePromotion(dispatch, getState);
                dispatch(changeCodeManuallySet(true));
            }
            // productLength check is needed so that the cart does not clear the promoCode when NO product
            let productsLength = getState().shoppingCart.orderProducts.length;
            if (loyaltyCode && productsLength > 0) {
                doCalculatePromotion(dispatch, getState);
            }
        }
    }
};

export const dispatchServiceFeeToStore = (serviceFee) => {
    return (dispatch, getState) => {

        dispatch({ type: DISPATCH_SERVICE_FEE, payload: serviceFee });
        let updatedShopingCart = updateShoppingCartState(getState, undefined);
        dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart });
    }
};

export const addProductToCart = (item) => {
    return (dispatch, getState) => {

        let existingProductsInCart = getState().shoppingCart.orderProducts.slice();
        existingProductsInCart = handleItemToShoppingCart(existingProductsInCart, item); // handles adding items to shopping cart state
        dispatch({ type: ADD_PRODUCT_TO_CART, payload: existingProductsInCart });
        let updatedShopingCart = updateShoppingCartState(getState, undefined);
        // TIME STAMP
        if (updatedShopingCart.orderProducts.length === 1 && !getState().pwaAppRunning) {
            // add stamp
            let firstStamp = Date.now();
            dispatch(saveFirstStamp(firstStamp));
        }

        dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart });

        doCalculatePromotion(dispatch, getState);
    }
};

export const updateShoppingCartState = (getState, updatedProducts) => {

    let updatedShoppingCartValues = shoppingCartCalculations(getState, updatedProducts);
    let updatedShopingCart = {
        orderProducts: updatedShoppingCartValues.orderProducts,
        subtotal: updatedShoppingCartValues.subtotal,
        deliveryFee: updatedShoppingCartValues.deliveryFee,
        generalInstruction: getState().shoppingCart.generalInstruction,
        tip: updatedShoppingCartValues.tip,
        bonusTip: getState().shoppingCart.bonusTip,
        total: updatedShoppingCartValues.total === null || updatedShoppingCartValues.total === undefined ? getState().shoppingCart.total : updatedShoppingCartValues.total,
        promoCode: getState().shoppingCart.promoCode,
        orderType: getState().orderTypes.selectedOrderType,
        freeDelivery: getState().shoppingCart.freeDelivery,
        appliedPromotions: getState().shoppingCart.appliedPromotions,
        discount: !updatedShoppingCartValues.discount ? getState().shoppingCart.discount : updatedShoppingCartValues.discount,
        vat: updatedShoppingCartValues.vat,
        errorMessage: getState().shoppingCart.errorMessage,
        serviceFee: getState().shoppingCart.serviceFee,
        feeObject: getState().shoppingCart.feeObject,
        deposits: updatedShoppingCartValues.deposits ? updatedShoppingCartValues.deposits : null,
        promoCallsCount: getState().shoppingCart.promoCallsCount
    }


    return updatedShopingCart;
};

export const addOrSubstractItem = (updatedProducts, restaurantId) => {
    return (dispatch, getState) => {

        let updatedShopingCart = updateShoppingCartState(getState, updatedProducts);
        dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart });

        doCalculatePromotion(dispatch, getState);
    };
};

export const removeItemFromCart = (updatedProducts, restaurantId) => {
    return (dispatch, getState) => {

        let currentMoment = Date.now();
        let updatedShopingCart = updateShoppingCartState(getState, updatedProducts);
        // TIME STAMP
        if (updatedShopingCart.orderProducts.length === 0 && !getState().pwaAppRunning) {
            // clear shopping cart and set stamp to 0
            dispatch(saveFirstStamp(currentMoment));
        }
        dispatch({ type: REMOVE_ITEM_FROM_CART, payload: updatedShopingCart });

        doCalculatePromotion(dispatch, getState);
    };
};

export const increaseOrDecreaseTip = (item, callback) => {
    return (dispatch, getState) => {

        dispatch({ type: INCREASE_OR_DECREASE_TIP, payload: item });

        let updatedShopingCart = updateShoppingCartState(getState, undefined);
        dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart });

        if(callback) {
            callback()
        }
    };
};

export const saveGeneralInstructions = (info) => {
    return (dispatch) => {

        dispatch({ type: SAVE_GENERAL_INSTRUCTIONS, payload: info });
    };
};

export const clearShoppingCart = () => {
    return (dispatch) => {

        dispatch({ type: CLEAR_SHOPPING_CART });
    };
};

export const placeOrder = (isAppleOrGooglePayment, paymentProviderData, closeBrowserWallet) => {
    return (dispatch, getState) => {
        const {
            customer,
            brand,
            orderTypes,
            selectedRestaurant,
            estimateOrderTime,
            shoppingCart,
            payment,
            paymentProvider,
            pwaAppRunning,
            promotionPartners,
            giftCards
        } = getState();

        const promoCode = shoppingCart.promoCode;
        const loggedInCustomer = {
            id: Number(customer.id),
            name: customer.name,
            phone: customer.phone,
            formattedPhone: customer.formattedPhone,
            countryCode: brand.countryCode,
            email: customer.email,
            addresses: customer.addresses
        };

        const customerDeliveryAddress = customer.selectedDeliveryAddress;

        const customerConditioOne = (customerDeliveryAddress !== undefined && customerDeliveryAddress !== null && Object.keys(customerDeliveryAddress).length > 0) ? true : false;
        const customerConditioTwo = (customerConditioOne && customerDeliveryAddress.id !== undefined && customerDeliveryAddress.id !== null && customerDeliveryAddress.id !== -1) ? true : false;
        const orderAddressId = (customerConditioOne && customerConditioTwo) ? customerDeliveryAddress.id : null;
        const selectedDeliveryAddress = (customerDeliveryAddress == undefined || customerDeliveryAddress == null) ? '' : customerDeliveryAddress;
        const fullAddress = (customerConditioOne && customerDeliveryAddress.fullAddress !== undefined && customerDeliveryAddress.fullAddress !== null) ? customerDeliveryAddress.fullAddress : null;
        const street = (selectedDeliveryAddress.street !== undefined && selectedDeliveryAddress.street !== null) ? selectedDeliveryAddress.street : fullAddress;

        const orderAddress = (customerDeliveryAddress !== undefined && customerDeliveryAddress !== null && Object.keys(customerDeliveryAddress).length > 0) ? {
            id: orderAddressId,
            fullAddress: fullAddress,
            street: street,
            streetNumber: customerDeliveryAddress.streetNumber,
            floor: customerDeliveryAddress.floor,
            apartment: customerDeliveryAddress.apartment,
            city: customerDeliveryAddress.city,
            country: formatGeoLocatedCountryToCountryCode(customerDeliveryAddress.country),
            longitude: customerDeliveryAddress.longitude,
            latitude: customerDeliveryAddress.latitude,
            details: customerDeliveryAddress.details,
            zipCode: customerDeliveryAddress.zipCode,
            placeId: customerDeliveryAddress.placeId
        } : null;

        const futureOrder = estimateOrderTime.futureOrder;
        const orderType = orderTypes.selectedOrderType;
        const cookTime = estimateOrderTime.cookTime;
        const orderTimeWithCookTime = moment().add(selectedRestaurant.restaurantTimeOffset, 'milliseconds').add(estimateOrderTime.estimateOrderTime, 'minutes').add(cookTime, 'minutes');
        let wantedTime = '';

        if (estimateOrderTime.wantedTime) {
            let estimateWantedTime = moment(estimateOrderTime.wantedTime, 'DD-MM-YYYY HH-mm-ss');
            const midnightTime = moment(`${selectedRestaurant.workingDay.date} 00:00:00`, 'DD-MM-YYYY HH-mm-ss');
            const eightoClock = moment(`${selectedRestaurant.workingDay.date} 08:00:00`, 'DD-MM-YYYY HH-mm-ss');

            if (estimateWantedTime.isBetween(midnightTime, eightoClock) && selectedRestaurant.workingDay.workingTimeInTwoDays) {
                estimateWantedTime.add(1, 'day');
            }

            if (orderTimeWithCookTime.isAfter(estimateWantedTime)) {
                wantedTime = orderTimeWithCookTime.format('DD-MM-YYYY HH:mm:ss');
            } else {
                wantedTime = estimateWantedTime.format('DD-MM-YYYY HH:mm:ss');
            }

        }

        if (estimateOrderTime.timeSlotBased) {
            wantedTime = `${estimateOrderTime.time.date} ${moment(`${estimateOrderTime.time.fromHour}:${estimateOrderTime.time.fromMinute}`, 'HH:mm').format('HH:mm')}:00`;
        }
        
        const orderEstimateTime = estimateOrderTime.estimateOrderTime;
        const deliveryZoneId = selectedRestaurant.deliveryZoneId;
        const deliveryChargeData = (orderTypes.selectedOrderType.id == DELIVERY_ID) ? shoppingCart.deliveryFee : null;
        const deliveryCharge = transformProductPrice(deliveryChargeData);
        console.log(deliveryCharge);
        const freeDelivery = (orderTypes.selectedOrderType.id == DELIVERY_ID) ? shoppingCart.freeDelivery : false;
        const promotions = [];
        shoppingCart.appliedPromotions.map(promotion => {
            let promoCopy = { ...promotion };
            delete promoCopy.name;
            promotions.push(promoCopy);
        });
        const tenderType = payment.selectedPaymentType;

        const tip = shoppingCart.tip;
        const totalTax = shoppingCart.vat.total;
        const tipInPercentage = selectedRestaurant.deliveryTipInPercentage;
        const serviceFee = shoppingCart.serviceFee;
        const generalInstruction = shoppingCart.generalInstruction;

        const currentTime = moment().add(selectedRestaurant.restaurantTimeOffset, 'milliseconds');
        const customerStartOrderTime = moment(customer.startOrderTime);

        const timeSpentToOrder = currentTime.diff(customerStartOrderTime, 'minutes');

        const orderedProducts = transformShoppingCartProducts(shoppingCart.orderProducts, selectedRestaurant.allowToppingSubstitution);
        const priceBeforeDiscount = shoppingCart.subtotal;

        const source = getOrderSource(pwaAppRunning);
        const currUserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        let customerOrder = {
            order: {
                orderType: orderType,
                source: source,
                serviceFee: serviceFee,
                feeInFavorOfRestaurant: shoppingCart.feeObject.inFavorOfRestaurant,
                customer: loggedInCustomer,
                orderAddress: orderAddress,
                futureOrder: futureOrder,
                wantedTime: wantedTime,
                estimatedDeliveryTime: orderEstimateTime,
                timeSpentToOrder: timeSpentToOrder,
                deliveryZoneId: deliveryZoneId,
                deliveryCharge: deliveryCharge,
                freeDelivery: freeDelivery,
                promotions: promotions,
                tip: Number(tip.toFixed(2)),
                totalTax: totalTax,
                tipInPercentage: tipInPercentage,
                priceIncludeTax: false, // backend does another check in data base and does not consider what this is set, it just needs it
                priceBeforeDiscount: Number(priceBeforeDiscount.toFixed(2)),
                orderProducts: orderedProducts,
                tenderType: !isAppleOrGooglePayment ? tenderType : { id: 1, value: 'Credit card' },
                generalInstruction: {
                    notes: generalInstruction
                },
                orderDeposit: shoppingCart.deposits,
                cookTime: cookTime,
                customerTimeZone: currUserTimezone,
                ...(estimateOrderTime.timeSlotBased &&
                    { timeSlotEndTime: `${estimateOrderTime.time.date} ${moment(`${estimateOrderTime.time.toHour}:${estimateOrderTime.time.toMinute}`, 'HH:mm').format('HH:mm')}:00` }),
                giftCardDiscount: giftCards.giftCards ? transformProductPrice(giftCards.giftCards.totalDiscount) : null
            }
        }
        // Adding taxes for US and Canada
        if (!estimateOrderTime.taxIncludeInPrice || estimateOrderTime.taxIncludeInPrice) {
            let totalTax = 0, taxBands = [];
            shoppingCart.vat.taxes.map(tax => {
                let newTaxObject = {
                    taxBand: {
                        id: tax.id
                    },
                    totalTax: +tax.total
                }
                taxBands.push(newTaxObject);
                totalTax += +tax.total;
            });
            customerOrder.order.totalTax = !estimateOrderTime.taxIncludeInPrice ? Number(totalTax.toFixed(2)) : 0;
            customerOrder.order.orderTaxes = taxBands;
        }

        if (paymentProvider.providerType) {
            if (Number(paymentProvider.providerType.id === 1)) {
                if (pwaAppRunning && isAppleOrGooglePayment) {
                    customerOrder.stripePaymentRequest = { tokenId: paymentProviderData };
                } else {
                    customerOrder.stripePaymentRequest = { paymentIntentId: paymentProviderData };
                }
            } else if (Number(paymentProvider.providerType.id) === 2) {
                customerOrder.brainTreePaymentRequest = paymentProviderData;
            } else if (Number(paymentProvider.providerType.id) === 3) {
                customerOrder.molliePaymentRequest = paymentProviderData;
            } else if (Number(paymentProvider.providerType.id) === 4) {
                customerOrder.authipayRequest = paymentProviderData;
            } else if (Number(paymentProvider.providerType.id) === 5) {
                customerOrder.monerisPaymentRequest = paymentProviderData;
            } else if (Number(paymentProvider.providerType.id) === 7) {
                customerOrder.worldPayUSPaymentRequest = paymentProviderData;
            } else if (Number(paymentProvider.providerType.id) === 8) {
                customerOrder.bamboraNaPaymentRequest = paymentProviderData;
            }
        }

        if (promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2) {
            const { como } = getState();
            const orderJson = PromotionPartnerUtils.transformDataIntoPartnerStructure(promotionPartners, { getState, isPlaceOrder: true });

            customerOrder.order.externalDiscountAmount = promotionPartners.promoPartnerDiscountTotal
            customerOrder.comoRequest = {
                idData: como.userSavedData.paymentResponse ? como.userSavedData.paymentResponse.confirmation : '',
                orderJson: JSON.stringify(orderJson),
                existingCustomer: getState().promotionPartners.isUserLoggedInPartner
            };
        }

        const giftCardProviderId = selectedRestaurant.giftCardProviderId;

        if (giftCardProviderId === 1) {
            customerOrder.giftCardPaymentRequest = {
                providerId: giftCardProviderId,
                appliedGiftCards: giftCards.giftCards ? giftCards.giftCards.appliedGiftCards : null
            }
        }

        // Adding tableNumber for In Store ordering
        // Adding Info for In Store ordering
        if (orderType.id === IN_STORE_ID) {
            customerOrder.order.orderInStoreInfo = selectedRestaurant.orderInStoreInfo;
        }
        // Adding car details for In Curbside ordering
        if (orderType.id === CURBSIDE_ID) {
            customerOrder.order.customer.carDetails = getState().storeLocalStorage.carDetails;
        }

        const sendingOrderModalData = {
            orderStatusMessage: i18n.t(`screens:shoppingCartActions.placingOrder`),
            customerInfoMessage: i18n.t(`screens:shoppingCartActions.pleaseWait`),
            orderSucceed: null
        }

        dispatch({ type: OPEN_SENDING_ORDER_MODAL, payload: sendingOrderModalData });
        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

        const restaurantId = selectedRestaurant.restaurantId;
        const url = `/restaurant/${restaurantId}/order`;
        // Adding timer to place order to seems like the order takes longer to be accepted by client requirement
        dateBeforePlaceOrderRequestSent = new Date().getTime() / 1000;

        axios(getState).post(url, customerOrder)
            .then(response => placeOrderSuccess(dispatch, response, getState, promoCode, closeBrowserWallet))
            .catch(error => placeOrderFailed(dispatch, error, getState));
    }
}

const placeOrderSuccess = (dispatch, response, getState, promoCode, closeBrowserWallet) => {

    pushDataToGoogleTagManager('event', 'purchase', getState().pwaAppRunning, response.data.data[0], undefined, undefined, undefined, promoCode);

    dateAfterPlaceOrderResponseReceived = new Date().getTime() / 1000;
    let serviceResponseTime = dateAfterPlaceOrderResponseReceived - dateBeforePlaceOrderRequestSent;

    const dispatchResponseDataToReducers = () => {
        // sets query params for the the IN STORE order type
        // needed for logging customers out and getting back to the first page
        const { selectedRestaurant, customerThemes, orderTypes } = getState();
        let queryParams = `?restaurantId=${selectedRestaurant.restaurantId}&theme=${customerThemes.selectedTheme.type}`;
        if (customerThemes.selectedTheme.type === 'CUSTOM') {
            let color = customerThemes.selectedTheme.primaryColor;
            if (customerThemes.selectedTheme.primaryColor.startsWith('#')) {
                color = color.slice(1);
            }
            queryParams += `&primaryColor=${color}`;
        }

        if (orderTypes.selectedOrderType.id === IN_STORE_ID) {
            queryParams += `&typeId=${selectedRestaurant.type.id}`;
        }

        // const sendingOrderModalData = {
        //     orderStatusMessage: i18n.t(`screens:shoppingCartActions.orderSent`),
        //     customerInfoMessage: null,
        //     orderSucceed: true
        // }
        dispatch({ type: SEND_RESPONSE_TO_ORDER_MODAL, payload: response.data.data[0] })
        dispatch({ type: OPEN_SENDING_ORDER_MODAL, payload: false });

        dispatch({ type: LOADING_NO_BACKGROUND, payload: false });

        // Redirect to the correct starting point
        // let isPwa, brandId, navigationType;
        const brandId = getState().brand.id;

        const isPwa = getState().temporaryOrderTime.currentPagePath.includes('brand');

        // navigationType = getState().storeLocalStorage.navigationType;

        if (isPwa) {
            if (isIOS) {
                makeApplePayPaymentSuccess();
            }
            dispatch({ type: CLOSE_SENDING_MODAL });
            dispatch(push(`/brand/${brandId}/order/order-confirmation`));
        } else {
            dispatch(push('/order-confirmation'));
        }

        // if (!isPwa && response.data.data[0].orderType.id !== IN_STORE_ID) {
        //     const isIntegration = getQueryInfoFromUrl().brandId;
        //     if (isIntegration) {
        //         window.history.replaceState({}, document.title, `/?brandId=${brandId}&navigationType=${navigationType}#/checkout`);
        //     } else {
        //         window.history.replaceState({}, document.title, `/#/checkout`);
        //     }
        // } else if (!isPwa && response.data.data[0].orderType.id === IN_STORE_ID) {
        //     window.history.replaceState({}, document.title, `${queryParams}#/dine-pay`);
        //     setTimeout(() => {
        //         dispatch(logoutCustomer(getState().pwaAppRunning, queryParams));
        //     }, 2000);
        // } else if (isPwa && brandId) {
        //     window.history.replaceState({}, document.title, `/#/brand/${brandId}/order/checkout`);
        // }

        dispatch({ type: CLEAR_SHOPPING_CART });
        // dispatch({ type: CLEAR_CURRENT_PATH_AFTER_USE });
        dispatch({ type: CLEAR_PAYMENT_REDUCER });
    };
    // If response time was less than 3 seconds slow down the place order success message
    if (serviceResponseTime < 2) {
        setTimeout(() => dispatchResponseDataToReducers(), 1400);
    } else { // show the show order success message without timeout
        dispatchResponseDataToReducers();
    }

    if (closeBrowserWallet) {
        closeBrowserWallet('success');
    }

    dispatch({ type: VOID_PURCHASE })
    dispatch({ type: RESET_OLD_DISCOUNT_FLAG });

}

const placeOrderFailed = (dispatch, error, getState) => {

    const isPwa = getState().temporaryOrderTime.currentPagePath.includes('brand');
    const brandId = getState().storeLocalStorage.brandId;
    const urlHashForFailedOrder = getState().temporaryOrderTime.currentPagePath;
    const errUserMsg = i18n.t(`screens:shoppingCartActions.somethingWentWrong`);

    const sendingOrderModalData = {
        orderStatusMessage: (error ? error.response.data.userMessage === errUserMsg : false) && getState().payment.selectedPaymentType.id === 1 ? i18n.t(`screens:shoppingCartActions.notAuthorised`) : i18n.t(`screens:shoppingCartActions.cannotSend`),
        customerInfoMessage: (error ? error.response.data.userMessage === errUserMsg : false) ? i18n.t(`screens:shoppingCartActions.tryAgainPayment`) : i18n.t(`screens:shoppingCartActions.tryAgain`),
        orderSucceed: false
    }
    // if restaurant is paused
    if (error.response.data.code === 503) {
        sendingOrderModalData.orderStatusMessage = i18n.t(`screens:shoppingCartActions.pausedRestaurant`);
        sendingOrderModalData.customerInfoMessage = i18n.t(`screens:shoppingCartActions.orderingNotAvailable`);
    }

    dispatch({ type: OPEN_SENDING_ORDER_MODAL, payload: sendingOrderModalData });
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });

    if (!getState().pwaAppRunning && error.response.data.code !== 503) {
        // Replaces the current url without refreshing the page - no / after # because the variable has one
        window.history.replaceState({}, document.title, `?brandId=${brandId}&navigationType=1/#${urlHashForFailedOrder}`);
    }

    setTimeout(() => {
        if (error.response.data.code === 503) {
            if (isPwa) {
                dispatch({ type: CLEAR_STATE, fromPwa: isPwa });
                dispatch(push(`/brand/${brandId}/start-order/main-screen`));
            } else {
                dispatch({ type: CLEAR_STATE });
                dispatch(push(`/start-order`));
            }
        } else {
            dispatch({ type: CLOSE_SENDING_ORDER_MODAL });
        }
    }, 7500);
};

export const processingPayment = () => {
    return (dispatch) => {

        const sendingOrderModalData = {
            orderStatusMessage: i18n.t(`screens:shoppingCartActions.processingPayment`),
            customerInfoMessage: i18n.t(`screens:shoppingCartActions.pleaseWaitShortMoment`),
            orderSucceed: null
        };

        dispatch({ type: OPEN_SENDING_ORDER_MODAL, payload: sendingOrderModalData });
        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });
    };
};

export const errorProcessingPayment = (error, errOrigin, providerID) => {
    return (dispatch, getState) => {
        loadErrorProcessingPayment(error, dispatch, getState, errOrigin, providerID);
    };
};

export const loadErrorProcessingPayment = (error, dispatch, getState, errOrigin, providerID) => {

    let sendingOrderModalData = {};
    if (providerID === 1) {
        let errCodeSplit, errMsg, custInfoMsg;

        if (error && !errOrigin) {
            errCodeSplit = error.message.split('.');
            errMsg = error.message ? error.message : i18n.t(`screens:stripeForm.${error.code}`);

            if (errMsg.includes('stripeForm.')) {
                errMsg = errCodeSplit[0];
            }
        } else {
            if (errOrigin === 'OFFLINE') {
                errMsg = i18n.t(`screens:shoppingCartActions.connectionError`);
                custInfoMsg = i18n.t(`screens:shoppingCartActions.checkConnection`);
            }
            errMsg = error.message;
        }

        sendingOrderModalData = {
            orderStatusMessage: errMsg,
            customerInfoMessage: (custInfoMsg ? custInfoMsg : error.message === i18n.t(`screens:shoppingCartActions.selectAnotherTimeSlot`) ? '' : i18n.t(`screens:shoppingCartActions.tryAgainPayment`)),
            orderSucceed: false
        };
    } else if (providerID === 2) {
        let orderStatusMsg = i18n.t(`screens:shoppingCartActions.somethingWrong`);
        switch (errOrigin) {
            case 'authenticate_unable_to_authenticate'://'Card authentication failed.' = all
            case 'authenticate_failed':
            case 'authenticate_rejected':
            case 'authentication_unavailable':
            case 'lookup_error':
            case 'UNKNOWN':
                orderStatusMsg = i18n.t(`screens:shoppingCartActions.somethingWrongWithPaymentProvider`);
                break;
            case 'lookup_bypassed':
                orderStatusMsg = i18n.t(`screens:shoppingCartActions.bankBypassed`);
            default:
                break;
        }

        sendingOrderModalData = {
            orderStatusMessage: orderStatusMsg,
            customerInfoMessage: i18n.t(`screens:shoppingCartActions.somethingWentWrong`),
            orderSucceed: false
        }
    } else if (providerID === 3) {
        let orderStatusMessage = i18n.t(`screens:shoppingCartActions.somethingWrong`);

        if (error === 'FAILED' && error === 'CANCEL') {
            orderStatusMessage = i18n.t(`screens:shoppingCartActions.somethingWrongWithPaymentProvider`);
        } else if (error === 'EXPIRED') {
            orderStatusMessage = i18n.t(`screens:shoppingCartActions.sessionExpired`);
        } else if (error === 'timedOut') {
            orderStatusMessage = i18n.t(`screens:shoppingCartActions.transactionTimedOut`);
        } else if (errOrigin === 'error') {
            orderStatusMessage = error.message ? error.message : i18n.t(`screens:shoppingCartActions.somethingWrong`);
        }

        sendingOrderModalData = {
            orderStatusMessage: orderStatusMessage,
            customerInfoMessage: i18n.t(`screens:shoppingCartActions.tryAgainPayment`),
            orderSucceed: false
        };
    } else if (providerID === 4) {
        let orderStatusMessage = 'Something went wrong.';

        sendingOrderModalData = {
            orderStatusMessage: errOrigin ? errOrigin : orderStatusMessage,
            customerInfoMessage: 'Please try again or change your payment method.',
            orderSucceed: false
        };

        if (error === 'timedOut') {
            sendingOrderModalData = {
                ...sendingOrderModalData,
                orderStatusMessage: 'Your payment session has expired.'
            };
        } else if (error.userMessage) {
            sendingOrderModalData = {
                ...sendingOrderModalData,
                orderStatusMessage: error.userMessage
            };
        }

        const isIntegration = getQueryInfoFromUrl().brandId;
        let urlPath = `/#/checkout`
        if (isIntegration) {
            urlPath = `/?brandId=${getState().storeLocalStorage.brandId}#/checkout`
        }

        if (!getState().pwaAppRunning) {
            window.history.replaceState({}, document.title, urlPath);
        }
    } else if (providerID === 5) {
        let orderStatusMessage = '';

        if (error.length > 1) {
            orderStatusMessage = 'Something went wrong. Please check your card fields';
        } else if (error[0] === '943') {
            orderStatusMessage = 'Card data is invalid.';
        } else if (error[0] === '944') {
            orderStatusMessage = 'Invalid expiration date.';
        } else if (error[0] === '945') {
            orderStatusMessage = 'Invalid CVD data.';
        } else {
            orderStatusMessage = 'Something went wrong.';
        }

        sendingOrderModalData = {
            orderStatusMessage: orderStatusMessage,
            customerInfoMessage: errOrigin ? errOrigin : 'Please try again or change your payment method.',
            orderSucceed: false
        };
    } else if (providerID === 8) {
        let orderStatusMessage = errOrigin ? errOrigin : 'Something went wrong.';
        let customerInfoMessage = !errOrigin ? error.message : 'Please try again or change your payment method.';

        sendingOrderModalData = {
            orderStatusMessage,
            customerInfoMessage,
            orderSucceed: false
        };
    } else if (providerID === 7) {
        let orderStatusMessage = '';

        orderStatusMessage = 'Something went wrong.';

        sendingOrderModalData = {
            orderStatusMessage: errOrigin ? errOrigin : orderStatusMessage,
            customerInfoMessage: 'Please try again or change your payment method.',
            orderSucceed: false
        };
    }

    dispatch({ type: OPEN_SENDING_ORDER_MODAL, payload: sendingOrderModalData });
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });

    setTimeout(() => {
        dispatch({ type: CLOSE_SENDING_ORDER_MODAL });
        if (+providerID === 3) {
            dispatch({ type: MOLLIE_RESPONSE_STATUS, payload: null });
        } else if (+providerID === 4) {
            dispatch({ type: AUTHIPAY_RESPONSE_STATUS, payload: null });
        }
    }, 7500);
};

export const closeSendingOrderModal = () => {
    return (dispatch) => {

        dispatch({ type: CLOSE_SENDING_ORDER_MODAL });
    };
};

export const clearShoppingCartState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_SHOPPING_CART });
    }
}

export const sendEmailReport = (restaurantId, data, originMethod, objectSent, stripeSucceededCase) => {
    return (_, getState) => {

        const { customer, brand, orderTypes, selectedRestaurant, estimateOrderTime, shoppingCart, payment } = getState();
        const url = `/restaurant/${restaurantId}/email-report`;

        const loggedInCustomer = {
            id: Number(customer.id),
            name: customer.name,
            phone: customer.phone,
            formattedPhone: customer.formattedPhone,
            countryCode: brand.countryCode,
            email: customer.email,
            addresses: customer.addresses
        };

        const customerDeliveryAddress = customer.selectedDeliveryAddress;

        const customerConditioOne = (customerDeliveryAddress !== undefined && customerDeliveryAddress !== null && Object.keys(customerDeliveryAddress).length > 0) ? true : false;
        const customerConditioTwo = (customerConditioOne && customerDeliveryAddress.id !== undefined && customerDeliveryAddress.id !== null && customerDeliveryAddress.id !== -1) ? true : false;
        const orderAddressId = (customerConditioOne && customerConditioTwo) ? customerDeliveryAddress.id : null;
        const selectedDeliveryAddress = (customerDeliveryAddress == undefined || customerDeliveryAddress == null) ? '' : customerDeliveryAddress;
        const fullAddress = (customerConditioOne && customerDeliveryAddress.fullAddress !== undefined && customerDeliveryAddress.fullAddress !== null) ? customerDeliveryAddress.fullAddress : null;
        const street = (selectedDeliveryAddress.street !== undefined && selectedDeliveryAddress.street !== null) ? selectedDeliveryAddress.street : fullAddress;

        const orderAddress = (customerDeliveryAddress !== undefined && customerDeliveryAddress !== null && Object.keys(customerDeliveryAddress).length > 0) ? {
            id: orderAddressId,
            fullAddress: fullAddress,
            street: street,
            streetNumber: customerDeliveryAddress.streetNumber,
            floor: customerDeliveryAddress.floor,
            apartment: customerDeliveryAddress.apartment,
            city: customerDeliveryAddress.city,
            country: formatGeoLocatedCountryToCountryCode(customerDeliveryAddress.country),
            longitude: customerDeliveryAddress.longitude,
            latitude: customerDeliveryAddress.latitude,
            details: customerDeliveryAddress.details,
            zipCode: customerDeliveryAddress.zipCode
        } : null;

        const currentTime = moment().add(selectedRestaurant.restaurantTimeOffset, 'milliseconds');
        const customerStartOrderTime = moment(customer.startOrderTime);

        const timeSpentToOrder = currentTime.diff(customerStartOrderTime, 'minutes');


        const promotionsCopy = [];
        shoppingCart.appliedPromotions.map(promotion => {
            let promotionCopy = { ...promotion };
            delete promotionCopy.name;
            promotionsCopy.push(promotionCopy)
        });
        // promotions.map(promotion => {
        //     // delete promotion.name;
        // });

        let report = {
            'data': `${JSON.stringify(originMethod)}:${JSON.stringify(data)}__objectSent:${JSON.stringify(objectSent)}`,
            order: {
                orderType: orderTypes.selectedOrderType,
                serviceFee: shoppingCart.serviceFee,
                feeInFavorOfRestaurant: shoppingCart.feeObject.inFavorOfRestaurant,
                customer: loggedInCustomer,
                orderAddress: orderAddress,
                futureOrder: estimateOrderTime.futureOrder,
                wantedTime: (estimateOrderTime.wantedTime) ? estimateOrderTime.wantedTime : moment().add(selectedRestaurant.restaurantTimeOffset, 'milliseconds').format('DD-MM-YYYY HH:mm:ss'),
                estimatedDeliveryTime: estimateOrderTime.estimateOrderTime,
                timeSpentToOrder: timeSpentToOrder,
                deliveryZoneId: selectedRestaurant.deliveryZoneId,
                deliveryCharge: (orderTypes.selectedOrderType.id == DELIVERY_ID) ? shoppingCart.deliveryFee : null,
                freeDelivery: (orderTypes.selectedOrderType.id == DELIVERY_ID) ? shoppingCart.freeDelivery : false,
                promotions: promotionsCopy,
                tip: shoppingCart.tip,
                totalTax: shoppingCart.vat.total,
                tipInPercentage: selectedRestaurant.deliveryTipInPercentage,
                priceIncludeTax: false, // backend does another check in data base and does not consider what this is set, it just needs it
                priceBeforeDiscount: shoppingCart.subtotal,
                orderProducts: transformShoppingCartProducts(shoppingCart.orderProducts, selectedRestaurant.allowToppingSubstitution),
                tenderType: payment.selectedPaymentType,
                generalInstruction: {
                    notes: shoppingCart.generalInstruction
                }
            },
            stripeSucceededCase: stripeSucceededCase ? stripeSucceededCase : null
        };

        if (navigator.onLine) {
            axios(getState).post(url, report);
        } else if (!navigator.onLine) {
            setTimeout(function () {
                axios(getState).post(url, report);
            }, 5000);
        }
    };
};

export const setMenuOrOrderHistoryScreen = (isMenuOrOrderHistory) => {
    return (dispatch) => {

        dispatch({ type: TOGGLE_MENU_ORDER_HISTORY_SCREEN, payload: isMenuOrOrderHistory });
    };
};

export const setProductsQty = (productsQty) => {
    return (dispatch) => {
        dispatch({ type: PRODUCTS_QTY, payload: productsQty });
    }
}