import axios from '../../../utils/AxiosUtils';
import moment from "moment/moment";
import {
    LOAD_AVAILABLE_DISCOUNTS_SUCCESS,
    SHOW_ERROR_DIALOG,
    LOAD_LOYALTY_SUCCESS
} from '../../types';

export const loadAllAvailableDiscounts = (restId) => {
    return (dispatch, getState) => {

        if (getState().promotionPartners.promotionPartner && getState().promotionPartners.promotionPartner.id !== 1) {
            return null;
        }
        
        let { menuPreviewMode, estimateOrderTime, orderTypes, selectedRestaurant } = getState();
        let restaurantId = restId;
        const previewMode = menuPreviewMode.previewMode;
        const currentDateAndTime = moment().add(selectedRestaurant.restaurantTimeOffset, 'milliseconds').format('DD-MM-YYYY HH:mm:ss');
        let wantedTime = (estimateOrderTime.wantedTime) ? estimateOrderTime.wantedTime : currentDateAndTime;
        if (estimateOrderTime.timeSlotBased) {
            let fromTime = moment(`${estimateOrderTime.time.fromHour}:${estimateOrderTime.time.fromMinute}:00`, 'HH:mm:ss').format('HH:mm:ss');
            wantedTime = `${estimateOrderTime.time.date} ${fromTime}`;
        }
        let orderTypeId = orderTypes.selectedOrderType.id;
        if (previewMode == true || previewMode == 'true') {
            restaurantId = getState().menuPreviewMode.restaurantId;
            orderTypeId = 2;
        }

        const url = `/restaurant/${restaurantId}/promotions/active?wantedTime=${wantedTime}&orderTypeId=${orderTypeId}`;

        // console.log('loadAllAvailableDiscounts url',url);
        axios(getState).get(url)
            .then(response => loadAllAvailableDiscountsSuccess(dispatch, response))
            .catch((error) => {
                // console.log('loadAllAvailableDiscounts error ', error);
                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
            });
    }
}

const loadAllAvailableDiscountsSuccess = (dispatch, response) => {
    // Splitting the response in two reducers
    let sortedResponse = {
        discounts: [],
        loyalty: {}
    }
    response.data.map(promotion => {
        if (promotion.loyaltyPromotion) {
            sortedResponse.loyalty = promotion;
        } else {
            sortedResponse.discounts.push(promotion);
        }
    })

    dispatch({ type: LOAD_AVAILABLE_DISCOUNTS_SUCCESS, payload: sortedResponse.discounts });
    dispatch({ type: LOAD_LOYALTY_SUCCESS, payload: sortedResponse.loyalty });
}