import React, { Component } from 'react';
import {connect} from "react-redux";
import { Col, Modal, ModalBody } from 'reactstrap';
import { BeatLoader } from 'react-spinners';

import SuccessIconOutlineSVG from '../../pwa-app/components/common/svgComponents/SuccessIconOutlineSVG';
import SendingOrderSVG from '../../pwa-app/components/common/svgComponents/SendingOrderSVG';
import FailureSVG from '../../pwa-app/components/common/svgComponents/FailureSVG';

class SendingOrderModal extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    componentWillMount() {
        this.applyTheme()
    }

    toggle() {
        this.props.onToggle();
    }

    onConfirm() {
        this.toggle();
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingContainerTransparent, loadingIndicator } = styles;
        const { primaryColor } = this.props.customerThemes.selectedTheme;

        if (this.props.loading.transparentLoader) {
            return (
                <div style={loadingContainerTransparent}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <BeatLoader color={primaryColor} size={10} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryFontColor, mainTextColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.orderStatusMessageStyle.color = primaryFontColor;
        customizedStyles.loadingMessage.color = mainTextColor;
        styles = customizedStyles;
        return styles;
    }

    render() {

        const {orderStatusMessageStyle, loadingMessage, loadingIndicatorContainer} = styles;
        const { type, primaryColor, secondaryColor, menuItemsBackground } = this.props.customerThemes.selectedTheme;
        const imgSource =
            (this.props.orderSucceed == false)? <FailureSVG width={50} height={50} fill={'red'}/> :
            (this.props.orderSucceed == true) ? <SuccessIconOutlineSVG width={70} height={70} fill={type === 'DARK' ? secondaryColor : primaryColor} stroke={menuItemsBackground} strokeWidth={1} />
             : <SendingOrderSVG width={75} height={75} fill={type === 'DARK' ? secondaryColor : primaryColor} /> ;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggle} id="sendingOrderModal">
                    <ModalBody style={{backgroundColor: menuItemsBackground}}>
                        <Col xs={12} sm={12} style={{textAlign: 'center', marginTop: 40}}>
                            {imgSource}
                        </Col>
                        <p style={orderStatusMessageStyle}>{this.props.orderStatusMessage}</p>
                        <p style={loadingMessage}>{this.props.customerInfoMessage}</p>
                        <p style={loadingIndicatorContainer}>{this.renderTransparentBackgroundLoaderContainer()}</p>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        customerThemes: state.customerThemes
    };
};

export default connect(mapStateToProps, {})(SendingOrderModal);

let styles = {
    orderStatusMessageStyle: {
        fontSize: 30,
        fontFamily: 'Roboto',
        fontWeight: 300,
        margin: 'auto',
        textAlign: 'center',
    },
    loadingMessage: {
        color: 'rgb(167, 167, 167)',
        fontFamily: 'Roboto',
        fontWeight: 300,
        margin: 'auto',
        textAlign: 'center',
    },
    loadingIndicatorContainer: {
        position: 'relative',
        height: 20,
        paddingBottom: 35,
    },
    loadingIndicator: {
        position: 'absolute',
        top: 10
    }
};


