import { CLEAR_APP_STATE, CLEAR_STATE, CLOSE_SENDING_MODAL, CLOSE_SENDING_ORDER_MODAL, OPEN_SENDING_ORDER_MODAL, SEND_RESPONSE_TO_ORDER_MODAL } from "../../actions/types";

const INITIAL_STATE = {
    sendOrderModalOpened: false,
    orderStatusMessage: '',
    customerInfoMessage: '',
    orderSucceed: null,
    orderResponse: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_SENDING_ORDER_MODAL:
            return {
                ...state,
                sendOrderModalOpened: true,
                orderStatusMessage: action.payload.orderStatusMessage,
                customerInfoMessage: action.payload.customerInfoMessage,
                orderSucceed: action.payload.orderSucceed
            };
        case SEND_RESPONSE_TO_ORDER_MODAL:
            return {
                ...state,
                orderResponse: action.payload
            }
        case CLOSE_SENDING_ORDER_MODAL:
            return {
                ...state,
                sendOrderModalOpened: false,
                orderStatusMessage: '',
                customerInfoMessage: '',
                orderSucceed: null,
            }
        case CLEAR_STATE:
            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        case CLOSE_SENDING_MODAL:
            return {
                ...state,
                sendOrderModalOpened: false
            }
        default:
            return state;
    }
}