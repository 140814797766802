import React from 'react';
import { Row, CardBody } from 'reactstrap';
import i18n from '../../../i18next';

import { getPriceWithCurrencySymbol, transformProductPrice } from '../../../utils/CommonUtils';
import { FRONT_END_URL } from '../../../utils/Constants.js';
import { isMobileOnly } from 'mobile-device-detect';

const UpsellProducts = ({ currency, upsellProducts, upsellMessage, orderProducts, selectProduct, theme, isArabic, enableLargeImageDesign }) => {
    let upsellProductsCopy = upsellProducts.slice(0);
    
    upsellProducts.map(upsellProduct => {
        orderProducts.map(orderProduct => {
            let productFoundInCart = false;
            upsellProduct.productSizesIds.map(sizeId => {
                if (sizeId === orderProduct.id) {
                    productFoundInCart = true;
                }
            });

            if (productFoundInCart) {
                let removeItemIndex = 0;
                upsellProductsCopy.map((copyProduct, copyIndex) => {
                    if (copyProduct.productId === orderProduct.id) {
                        removeItemIndex = copyIndex;
                    }
                });
                upsellProductsCopy.splice([removeItemIndex], 1);
            }
        });
    });

    if (!upsellProductsCopy.length) return null;
    let currentUpsellProduct = upsellProductsCopy[0];
    const productPrice = transformProductPrice(currentUpsellProduct.productPrice);
    const priceWithCurrency = getPriceWithCurrencySymbol(productPrice, currency, isArabic);
    let hasThumbnail = !enableLargeImageDesign && currentUpsellProduct.thumbnailPath ? true : enableLargeImageDesign && currentUpsellProduct.rectImagePath ? true : false;
    const { upsellBackground, upsellPrimaryFont, upsellSecondaryFont, fontFamilyType, fontBold, fontItalic, uppercase } = theme

    const upsellMessageStyle = {
        display: 'flex', 
        fontSize: 18, 
        fontFamily: fontFamilyType, 
        fontWeight: fontBold, 
        paddingTop: 5, 
        color: upsellPrimaryFont,
        fontStyle: fontItalic,
        textTransform: uppercase
    }

    const viewProductButtonStyle = {
        fontFamily: fontFamilyType, 
        fontWeight: fontBold, 
        fontStyle: fontItalic, 
        textTransform: uppercase
    }

    const mobileUpsellStyles = {
        backgroundColor: upsellBackground,
        position: 'fixed',
        bottom: 65,
        zIndex: '1040',
        width: '100%'
    }

    return (
        <div className='upsell-product' style={{ backgroundColor: upsellBackground }}>
            <div className='upsell-product-overlay' style={{ display: 'none' }}>
                <div
                    onClick={() => selectProduct({ 
                        id: currentUpsellProduct.productId,
                        name: currentUpsellProduct.productName,
                        price: currentUpsellProduct.productPrice
                    })}
                    style={{
                        width: '65%',
                        height: 50,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0px 20px',
                        cursor: 'pointer',
                        color: theme.buttonsFont,
                        backgroundColor: theme.primaryColor
                    }}>
                    <div style={viewProductButtonStyle}>{i18n.t('screens:upsellScreen.addProduct')}</div>
                    <div style={viewProductButtonStyle}>{priceWithCurrency}</div>
                </div>
            </div>
            <CardBody
                className='upsell-product-content'
                onClick={() => selectProduct({ id: currentUpsellProduct.productId })}
                style={styles.cardBody}>

                <div style={{ height: 90, padding: isArabic ? '0px 15px 0px 18px' : '0px 18px 0px 15px', width: !hasThumbnail ? '100%' : enableLargeImageDesign ? 'calc(100% - 120px)' : 'calc(100% - 90px)', backgroundColor: upsellBackground }}>
                    <span style={upsellMessageStyle}>{upsellMessage}</span>
                    <Row style={isArabic ? { justifyContent: 'space-between', paddingTop: 5, marginRight: 0, color: upsellPrimaryFont } : { justifyContent: 'space-between', paddingTop: 5, marginLeft: 0, color: upsellPrimaryFont }}>
                        <p className="long-text" style={{ marginBottom: 0, fontFamily: fontFamilyType, fontWeight: fontBold, fontSize: '16px', padding: isArabic ? '0 0 0 5px' : '0 5px 0 0', fontStyle: fontItalic, textTransform: uppercase }}>{currentUpsellProduct.productName}</p>
                        <p hidden={currentUpsellProduct.productPrice === 0} style={isArabic ? { fontSize: '0.875rem', fontFamily: fontFamilyType, fontWeight: fontBold, padding: '0 0 0 5px', marginRight: 0, marginBottom: 0, fontStyle: fontItalic, textTransform: uppercase } : { fontSize: '0.875rem', fontFamily: fontFamilyType, fontWeight: fontBold, padding: '0 5px 0 0', marginLeft: 0, marginBottom: 0, fontStyle: fontItalic, textTransform: uppercase }}>{priceWithCurrency}</p>
                    </Row>
                    <Row hidden={currentUpsellProduct.productDescription ? false : true}>
                        <p style={isArabic ? { fontSize: '0.875rem', padding: '0px 15px 0px 0', color: '#6e7676', marginBottom: 0, marginRight: 0, maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', color: upsellSecondaryFont } : { fontSize: '0.875rem', padding: '0px 0px 0px 15px', color: '#6e7676', marginBottom: 0, marginLeft: 0, maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', color: upsellSecondaryFont }}>{currentUpsellProduct.productDescription}</p>
                    </Row>
                </div>
                {
                    !hasThumbnail ? null :
                        <div style={styles.thumbImgContainer}>
                            <img src={`${FRONT_END_URL}/${enableLargeImageDesign ? currentUpsellProduct.rectImagePath : currentUpsellProduct.thumbnailPath}`}
                                 // className='img-thumbnail'
                                 style={styles.thumbImg} alt='' />
                        </div>
                }
            </CardBody>
        </div>
    );
}

export default UpsellProducts;

let styles = {
    upsellBackground: {
        backgroundColor: '#B8C2C3'
    },
    overlayDefault: {
        display: 'none'
    },
    productCardOverlay: {
        width: '65%',
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 20px',
        cursor: 'pointer',
        color: '#fff',
    },
    fontFamily: {
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    cardBody: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
        cursor: 'pointer'
    },
    msg: {
        display: 'flex',
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 400,
        paddingTop: 5
    },
    productName: {
        marginBottom: 0,
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '16px',
        padding: 0
    },
    productPrice: {
        fontSize: '0.875rem',
        fontFamily: 'Roboto',
        fontWeight: 400,
        padding: '0 5px 0 0',
        marginLeft: 0,
        marginBottom: 0
    },
    productDescription: {
        fontSize: '0.875rem',
        padding: '0px 0px 0px 15px',
        color: '#6e7676',
        marginBottom: 0,
        marginLeft: 0,
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    thumbImgContainer: {
        height: 90,
        paddingRight: 0,
        paddingLeft: 0,
        // width: 90,
        objectFit: 'cover',
        backgroundColor: '#B8C2C3'
    },
    thumbImg: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    }
}