import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";

import '../../styles/ApplicationStep.css';
import BackButton from './buttons/BackButton';
import HamburgerButton from './buttons/HamburgerButton';

import Drawer, {
    DrawerContainer,
    MainContentContainer,
} from 'react-swipeable-drawer';

import DrawerContent from '../profile/DrawerContent';
import { ARABIC } from '../../../utils/Constants';

class ApplicationStep extends Component {

    constructor(props) {
        super(props);

        this.renderContent = this.renderContent.bind(this);
        this.handleToggleDrawer = this.handleToggleDrawer.bind(this);
    }

    renderHeader(toggleDrawer) {
        let headerId, textProp, drawerColor;
        const { buttonsFont, fontFamilyType } = this.props.customerThemes.selectedTheme;
        headerId = this.props.transparentHeader ? 'headerContainerTransparent' : 'headerContainer';
        textProp = this.props.text ? this.props.text : null;
        const isArabic = this.props.language === ARABIC;
        // drawerColor = this.props;
        // let isProductWizardOpen = this.props.match.params.productId;

        return (
            <div dir="ltr" id={headerId} style={this.props.isLoadedFromCheckout || window.location.hash.includes('/product/') ? { position: 'fixed', zIndex: 100 } : null}>
                {this.props.hideBackButton ? <div style={{ width: '10%' }} /> : <BackButton goBack={this.props.goBack} isShadowed={this.props.isHamburgerShadowed} color={buttonsFont} />}
                {
                    this.props.switchLanguageButtons ?
                        <div>{this.props.switchLanguageButtons}</div> : null
                }
                <div id="headerText">
                    <div dir={isArabic ? "rtl" : "ltr"} id="restAndBrandName" style={{ color: buttonsFont, fontFamily: fontFamilyType }}>
                        {textProp}
                    </div>
                </div>
                {/* <div id="drawerMenu" style={{width: '10%', height: '100%', alignItems: 'center', color: this.props.drawerColor}}
                     onClick={() => this.handleToggleDrawer(toggleDrawer)}> &#9776; </div>*/}
                <div id="drawerMenu" style={{ width: '10%', height: '100%', alignItems: 'center', color: this.props.drawerColor }}
                    onClick={() => this.handleToggleDrawer(toggleDrawer)}>
                    <HamburgerButton isShadowed={this.props.isHamburgerShadowed} isMainScreen={this.props.isMainScreen} color={buttonsFont} />
                </div>
            </div>
        );
    }

    handleToggleDrawer(toggleDrawer) {
        if (this.props.userOnline) toggleDrawer();
    }

    renderContent() {
        let contetnId = this.props.transparentHeader ? 'contentContainerTransparent' : 'contentContainer';
        const { menuItemsBackground } = this.props.customerThemes.selectedTheme;

        return (
            <div id={contetnId} style={this.props.isLoadedFromCheckout ? { overflow: 'unset', paddingTop: '60px', backgroundColor: menuItemsBackground } : { backgroundColor: menuItemsBackground }}>
                {this.props.content}
            </div>
        );
    }

    renderFooter() {
        return (
            <div id="footerContainer">
                {this.props.renderFooter()}
            </div>
        );
    }

    render() {
        const { type, menuBackground } = this.props.customerThemes.selectedTheme;

        let applicationStepEl = document.getElementById('applicationStep');

        // if (applicationStepEl) {
        //     applicationStepEl.style.backgroundColor = type === 'CUSTOM' || 'DARK' || 'LIGHT' ? menuBackground : '#fff';
        // }


        return (
            <Drawer position="right" size={80}>
                {({
                    position,
                    size,
                    swiping,
                    translation,
                    mainContentScroll,
                    toggleDrawer,
                    handleTouchStart,
                    handleTouchMove,
                    handleTouchEnd,
                }) => (
                    <Fragment>
                        <DrawerContainer
                            position={position}
                            size={size}
                            swiping={swiping}
                            translation={translation}
                            toggleDrawer={toggleDrawer}
                            handleTouchStart={handleTouchStart}
                            handleTouchMove={handleTouchMove}
                            handleTouchEnd={handleTouchEnd}
                            drawerContent={
                                <DrawerContent
                                    toggleDrawer={toggleDrawer}
                                    currentPath={window.location.href}
                                />}
                        />
                        <MainContentContainer
                            translation={translation}
                            mainContentScroll={mainContentScroll}
                        >
                            <div id="applicationStep">
                                {this.renderHeader(toggleDrawer)}
                                {this.props.content ? this.renderContent() : null}
                                {this.props.footer ? this.renderFooter() : null}
                            </div>
                        </MainContentContainer>
                    </Fragment>
                )}
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        userOnline: state.userOnline,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, null)(ApplicationStep);

