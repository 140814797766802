import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
    transformProductPrice,
    getPriceWithCurrencySymbol
} from '../../../utils/CommonUtils';
import { ARABIC } from '../../../utils/Constants';
import { getAnswerPrice } from '../../../utils/ProductWizardUtils';
import {isMobile} from 'mobile-device-detect';

class SingleAnswersStep extends Component {
    renderInList(group) {
        const { currentQuestion, currency } = this.props;
        const isArabic = this.props.language === ARABIC;
        let answersArray = currentQuestion.answers.slice(0);
        let footerSizedPadding = { paddingBottom: 15 };
        let mobileListView = { flexDirection: 'unset' };

        if (window.innerWidth <= 992) {
            footerSizedPadding = {
                paddingBottom: 111 // 111px is the Footer's fixed height
            };
        }

        if (isMobile) {
            mobileListView = {
                flexDirection: 'column'
            };
        }

        return (
            <ul className="singleAnswers" style={{ ...footerSizedPadding }}>
                {
                    answersArray.map(answer => {
                        const productPrice = transformProductPrice(answer.price);
                        const priceWithCurrency = getPriceWithCurrencySymbol(productPrice, currency, isArabic);

                        if (group) {
                            if (answer.answerGroup) {
                                if (answer.answerGroup.id !== group.id) return null;

                                return this.renderListItem(answer, priceWithCurrency);

                            } else if (!answer.answerGroup && group.id === -1) {

                                return this.renderListItem(answer, priceWithCurrency);
                            } else {
                                return null;
                            }
                        } else {
                            return this.renderListItem(answer, priceWithCurrency);
                        }

                    })
                }
            </ul>
        );
    }

    renderListItem(item, priceWithCurrency) {
        const { primaryFontColor } = this.props.selectedTheme;
        const { restaurant, product, productMainQuestion, selectedTheme, currentQuestion, step, innerStep, useInnerStep } = this.props;
        const isSelected = this.isSelected(item);
        let itemCopy = { ...item };
        let selectedColor = currentQuestion.id === -111 ? '#c8c8c8' : selectedTheme.primaryColor;
        let itemPrice = itemCopy.price;

        if (isSelected) {
            itemPrice = getAnswerPrice({
                item: itemCopy,
                allowToppingSubstitution: restaurant.allowToppingSubstitution,
                product,
                productMainQuestion,
                step,
                innerStep,
                useInnerStep
            });
        }

        return (
            <li key={`${item.name}${item.id}`} style={{ pointerEvents: isSelected ? 'none' : 'initial' }}
                onChange={(event) => this.props.selectItem(event, item)} >
                    <label className='custom-radio-btn singleAnswersListLabel' style={{ color: primaryFontColor }}>
                        <input type="radio" name={item.name} value={item.name} readOnly={true} checked={isSelected}/>
                        <span>{item.name}</span>
                        <span className="checkmark"></span>
                     </label>
                <span hidden={!isSelected || itemPrice <= 0} style={{ right: 5, color: selectedColor }}>{priceWithCurrency}</span>
            </li>
        );
    }

    isSelected(item) {
        const { product, currentQuestion, useInnerStep } = this.props;
        let isChecked = false;

        if (product.mealDeal && !useInnerStep) {
            isChecked = currentQuestion.selectedProduct ? currentQuestion.selectedProduct.id === item.id : false;
        } else {
            let hasAnswers = currentQuestion.selectedAnswers;
            if (hasAnswers && hasAnswers.length) {
                hasAnswers = true;
            } else {
                hasAnswers = false;
            }

            isChecked = hasAnswers ? currentQuestion.selectedAnswers[0].id === item.id : false;
        }

        return isChecked;
    };

    render() {
        return (
            <Fragment>
                {this.renderInList(this.props.group)}
            </Fragment>
        );
    }
}

const mapStateToProp = state => {
    return {
        language: state.storeLocalStorage.language
    }
}

export default connect(mapStateToProp)(SingleAnswersStep);