import axios from '../../utils/AxiosUtils';

import {
    GET_CUSTOMER_SAVED_ADDRESSES_SUCCESS,
    GET_CUSTOMER_SAVED_PAYMENT_CARDS_SUCCESS,
    GET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS,
    LOAD_MARKETING_PREFERENCES_SUCCESS,
    SHOW_ERROR_ALERT_MESSAGE,
    SHOW_SUCCESS_ALERT_MESSAGE,
    UPDATE_CUSTOMER_SUCCESS,
    SAVE_PROFILE_BACK_BUTTON_PATH
} from '../types';
import {
    saveCustomerToLocalStorage,
    doClearState
} from '../../actions/login/CustomerActions';

export const updateCustomer = (customerId, state) => {
    return (dispatch, getState) => {

        const dispatchSuccessMessage = true;
        const url = `/customer/${customerId}/update`;

        axios(getState).put(url, state)
            .then(response => state.active === true ? updateCustomerSuccess(dispatch, response) : doClearState(dispatch, dispatchSuccessMessage, true))
            .catch((error) => {
                console.log(error);
                dispatch({ type: SHOW_ERROR_ALERT_MESSAGE, payload: true });
            });
    };
};

const updateCustomerSuccess = (dispatch, response) => {

    saveCustomerToLocalStorage(response.data);
    dispatch({ type: SHOW_SUCCESS_ALERT_MESSAGE, payload: true });
    dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: response.data });
};

export const loadCustomerDeliveryAddresses = () => {
    return (dispatch, getState) => {

        getCustomerDeliveryAddresses(dispatch, getState);
    };
};

export const getCustomerDeliveryAddresses = (dispatch, getState) => {

    const customerId = getState().customer.id;
    const url = `/customer/${customerId}/restaurants/delivery`;

    axios(getState).get(url)
        .then(response => getCustomerDeliveryAddressesSuccess(dispatch, response))
        .catch(_ => {});
};

const getCustomerDeliveryAddressesSuccess = (dispatch, response) => {

    dispatch({ type: GET_CUSTOMER_SAVED_ADDRESSES_SUCCESS, payload: response.data });
};

export const updateCustomerDeliveryAddresses = (addressId, address, callback) => {
    return (dispatch, getState) => {

        const customerId = getState().customer.id;

        const url = `/customer/${customerId}/address/${addressId}`;

        axios(getState).put(url, address)
            .then(_ => updateCustomerDeliveryAddressesSuccess(dispatch, getState, callback))
            .catch(_ => {

                callback();
                dispatch({ type: SHOW_ERROR_ALERT_MESSAGE, payload: true });
            });
    };
};

const updateCustomerDeliveryAddressesSuccess = (dispatch, getState, callback) => {

    dispatch({ type: SHOW_SUCCESS_ALERT_MESSAGE, payload: true });
    getCustomerDeliveryAddresses(dispatch, getState);
    callback();
};

export const loadCustomerCollectionAddresses = () => {
    return (dispatch, getState) => {

        getCustomerCollectionAddresses(dispatch, getState);
    };
};

export const getCustomerCollectionAddresses = (dispatch, getState) => {

    const customerId = getState().customer.id;
    const brandId = getState().brand.id;

    const url = `/customer/${customerId}/restaurants/collection?restaurantChainId=${brandId}`;

    axios(getState).get(url)
        .then(response => getCustomerCollectionAddressesSuccess(dispatch, response))
        .catch(_ => {});
};

const getCustomerCollectionAddressesSuccess = (dispatch, response) => {

    dispatch({ type: GET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS, payload: response.data });
};

export const deleteCustomerDeliveryAddress = (addressId, callback) => {
    return (dispatch, getState) => {

        const customerId = getState().customer.id;
        const url = `/customer/${customerId}/address/${addressId}`;

        axios(getState).delete(url)
            .then(response => deleteCustomerDeliveryAddressSuccess(dispatch, response, callback))
            .catch(_ => {

                dispatch({ type: SHOW_ERROR_ALERT_MESSAGE, payload: true });
            });
    }
};

const deleteCustomerDeliveryAddressSuccess = (dispatch, response, callback) => {

    dispatch({ type: SHOW_SUCCESS_ALERT_MESSAGE, payload: true });
    dispatch({ type: GET_CUSTOMER_SAVED_ADDRESSES_SUCCESS, payload: response.data });

    if (callback) {
        callback();
    }
};

export const deleteCustomerSavedRestaurant = (restaurantId, callback) => {
    return (dispatch, getState) => {

        const customerId = getState().customer.id;
        const url = `/customer/${customerId}/collection-restaurant/${restaurantId}`;

        axios(getState).delete(url)
            .then(response => deleteCustomerSavedRestaurantSuccess(dispatch, response, getState, callback))
            .catch(_ => {});
    };
};

const deleteCustomerSavedRestaurantSuccess = (dispatch, response, getState, callback) => {

    dispatch({ type: SHOW_SUCCESS_ALERT_MESSAGE, payload: true });
    dispatch({ type: GET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS, payload: response.data });
    getCustomerCollectionAddresses(dispatch, getState);

    if (callback) {
        callback();
    }
};

export const deleteCustomerCard = (paymentCardId) => {
    return (dispatch, getState) => {

        const customerId = getState().customer.id;
        const restaurantId = getState().selectedRestaurant.restaurantId;

        const url = `/payment/${customerId}/delete-card?cardId=${paymentCardId}&restaurantId=${restaurantId}`;

        axios(getState).delete(url)
            .then(response => deleteCustomerCardSuccess(dispatch, response))
            .catch(_ => {

                dispatch({ type: SHOW_ERROR_ALERT_MESSAGE, payload: true });
            });
    };
};

const deleteCustomerCardSuccess = (dispatch, response) => {

    dispatch({ type: SHOW_SUCCESS_ALERT_MESSAGE, payload: true });
    dispatch({ type: GET_CUSTOMER_SAVED_PAYMENT_CARDS_SUCCESS, payload: response.data });
}

export const loadMarketingPreferences = () => {
    return (dispatch, getState) => {

        getMarketingPreferences(dispatch, getState);
    };
};

export const getMarketingPreferences = (dispatch, getState) => {

    const customerId = getState().customer.id;
    const brandId = getState().brand.id;
    const url = `/customer/${customerId}/marketing-preferences?restaurantChainId=${brandId}`;

    axios(getState).get(url)
        .then(response => getMarketingPreferencesSuccess(dispatch, response))
        .catch(_ => {});
}

const getMarketingPreferencesSuccess = (dispatch, response, callback) => {

    dispatch({ type: LOAD_MARKETING_PREFERENCES_SUCCESS, payload: response.data });
    if (callback) {
        callback();
    }
};

export const updateMarketingPreferences = (allowReceivingMarketingMaterials) => {
    return (dispatch, getState) => {

        const customerId = getState().customer.id;
        const brandId = getState().brand.id;

        const marketingPreferencesData = {
            restaurantChainId: brandId,
            allowReceivingMarketingMaterials: allowReceivingMarketingMaterials
        };

        const url = `/customer/${customerId}/marketing-preferences`;

        axios(getState).put(url, marketingPreferencesData)
            .then(response => getMarketingPreferencesSuccess(dispatch, response))
            .catch(_ => {

                dispatch({ type: SHOW_ERROR_ALERT_MESSAGE, payload: true });
            });
    }
};

export const updateProfileBackBtnPath = (path) => {
    return (dispatch) => {

        dispatch({ type: SAVE_PROFILE_BACK_BUTTON_PATH, payload: path })
    };
};

export const unsubscribeCustomerToChain = (customerId, brandId, callback) => {
    return (dispatch, getState) => {

        const marketingPreferencesData = {
            restaurantChainId: brandId,
            allowReceivingMarketingMaterials: false
        };

        const url = `/customer/${customerId}/marketing-preferences`;

        axios(getState).put(url, marketingPreferencesData)
            .then(response => getMarketingPreferencesSuccess(dispatch, response, callback))
            .catch(_ => {

                dispatch({ type: SHOW_ERROR_ALERT_MESSAGE, payload: true });
            });
    }
};