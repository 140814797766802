import React, { Component, Fragment } from "react";
import { Button, Col, Row, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import { AvForm } from 'availity-reactstrap-validation';
import { GridLoader } from 'react-spinners';
import MaterialIcon from 'material-icons-react';
import i18n from "../../../i18next";

import {
    loadRestaurantsInZone,
    saveSelectedRestaurantData,
    saveCustomerDeliveryAddress,
    activateLoader,
    stopLoader
} from '../../../actions/index';
import {
    getLoggedInUserFirstName,
    getRestaurantStatus,
    getRestaurantStatusBadge,
    getRestaurantStylesBasedOnStatusBadge,
    setOpenedRestCount
} from "../../../utils/CommonUtils";

import ModalStep from '../../common/ModalStep';

import LocationIconGreen from '../../../images/icons/icon-location-green-map.png';
import LocationIconDarkGrey from '../../../images/icons/icon-location-dark-grey.png';

import { GOOGLE_API_KEY, COLLECTION_ID, ARABIC } from '../../../utils/Constants';
import { MAIN_GREEN_COLOR, MAIN_LIGHT_GRAY_COLOR } from '../../../utils/StylesConstants';
import UserIconSVG from '../../../pwa-app/components/common/svgComponents/UserIconSVG';
import LocationIconSVG from '../../../pwa-app/components/common/svgComponents/LocationIconSVG';
import ShoppingCartSVG from '../../../pwa-app/components/common/svgComponents/ShoppingCartSVG';
import CurbsideSVG from '../../../pwa-app/components/common/svgComponents/CurbsideSVG';

class RestaurantSelectionScreen extends Component {
    constructor(props) {
        super(props);

        let isRedirectedFromDelivery = false;
        if (this.props.location && this.props.location.state && this.props.location.state.isRedirectedFromDelivery) {
            isRedirectedFromDelivery = true;
        }

        this.state = {
            selectedRestaurantId: null,
            isRedirectedFromDelivery
        };

        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    componentWillMount() {
        this.applyTheme();
    }

    componentDidMount() {
        let brandId = this.props.brand.id;

        let queryParams = {
            latitude: this.props.userAddress.latitude,
            longitude: this.props.userAddress.longitude,
            address: this.props.userAddress.fullAddress,
            orderType: this.props.selectedOrderType
        };
        if (this.state.isRedirectedFromDelivery || this.props.isRedirectedFromDelivery) {
            //Restaurants in this delivery area already loaded in redux from ConfirmAddressScreen
            this.state.selectedRestaurantId = this.props.restaurantsInZone[0].restaurantId;
            this.loadGoogleLibrary();
            this.props.stopLoader();
        } else {
            this.props.loadRestaurantsInZone(brandId, queryParams, () => {
                // Preselect the first restaurant
                this.state.selectedRestaurantId = this.props.restaurantsInZone[0].restaurantId;
                this.loadGoogleLibrary();
                this.props.stopLoader();
            });
        }


        // Allow vertical scroll in mobile view to show the restaurants and the map
        let modalContent = document.getElementsByClassName('modal-content')[0];
        if (modalContent) {
            window.addEventListener('resize', () => {
                const isMobileView = window.outerWidth <= 800;

                if (isMobileView) {
                    modalContent.style.height = '98%';
                    modalContent.style.overflowY = 'auto';
                    modalContent.style.overflowX = 'hidden';
                } else {
                    modalContent.style.height = '480px';
                    modalContent.style.overflowY = 'hidden';
                    modalContent.style.overflowX = 'hidden';
                }
            });
        }
    }

    loadGoogleLibrary() {

        if (!window.google) {
            let s = document.createElement('script');
            s.type = 'text/javascript';
            s.defer = 'defer';
            s.src = `https://maps.google.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places,geometry&language=en`;
            let x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            // Below is important.
            //We cannot access google.maps until it's finished loading
            s.addEventListener('load', e => {
                this.initMapService();
            });
        } else {
            this.initMapService();
        }

    }

    handleMapZoom(map, level) {
        if (level >= 20) map.setZoom(18);
    }

    initMapService() {

        const map = new window.google.maps.Map(document.getElementById('restaurantsMap'), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 1,
            disableDefaultUI: true
        });

        let bounds = new window.google.maps.LatLngBounds();

        this.props.restaurantsInZone.map((restaurant, index) => {

            const isRestaurantOpenStatus = getRestaurantStatus(restaurant);

            let marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(restaurant.address.latitude, restaurant.address.longitude),
                map: map,
                icon: isRestaurantOpenStatus ? LocationIconGreen : LocationIconDarkGrey
            });

            // Zoom the map on the closest open restaurant based on the user location -> Server always resturns the closest open restaurant at position 0 in the list
            if (index === 0) {
                bounds.extend(marker.position);
            }
        });

        map.fitBounds(bounds);

        // If the map is zoomed too close to a restaurant handle the zoom
        setTimeout(() => {
            let mapZoom = map.getZoom();
            // Handle the zoom levels if the map is too zoomed
            this.handleMapZoom(map, mapZoom);
        }, 250);

    }

    renderCustomer() {
        const { profileNameStyle, profileNameStyleRTL, imageStyle, imageStyleRTL } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        const userFirstName = getLoggedInUserFirstName(this.props.customer.name);

        let customerNameClass = '';
        if (window.outerWidth <= 500) {
            customerNameClass += 'customer-long-text-back-button';
        }

        return (
            <div className='customer-name-mobile-style'>
                <UserIconSVG width={23} height={23} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ?  imageStyleRTL : imageStyle} />
                <span style={isArabic ? profileNameStyleRTL : profileNameStyle} className={customerNameClass}>{userFirstName}</span>
            </div>
        )
    }

    renderModalHeader(noRestaurantSelection) {
        const customerLoggedIn = this.props.customer !== undefined && this.props.customer !== null && this.props.customer.id !== undefined
            && this.props.customer.id !== undefined && this.props.customer.id !== null && this.props.customer.id !== -1 && this.props.customer.email !== undefined
            && this.props.customer.email !== null && this.props.customer.email !== "null" && noRestaurantSelection == true && this.props.customer.email !== 'undefined';

        let modalBodyContainerStyle = {
            position: 'absolute',
            width: '100%',
            top: '0',
            left: '0',
            zIndex: 1000
        };

        return (
            <Fragment>
                <div style={modalBodyContainerStyle} className='start-order-header-container restaurantSelectionScreen'>
                    <Button className="start-order-custom-back-button" onClick={() => this.goBack()}>
                        <span className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                        <span style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    {(customerLoggedIn) ? this.renderCustomer() : ''}
                    <div style={{ width: 90, textAlign: 'right' }}>
                        <Button style={{ backgroundColor: '#ffffff', opacity: '0.6' }} className='start-order-custom-close-button' onClick={() => { this.props.toggle() }}>
                            <MaterialIcon icon="close" color="#000" size={18} />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }

    prepareAddress(restaurant) {

        const { address } = restaurant;

        let floor = '';
        let streetNumber = '';
        let streetName = '';
        let city = '';
        let country = '';

        if (address.floor) floor = 'Floor ' + address.floor + ',';
        if (address.street) streetName = address.street;
        if (address.city) city = address.city;
        if (address.country) country = address.country;

        return `${floor} ${streetName}, ${city}, ${country}`;
    }

    selectRestaurant(restaurant) {
        this.setState({ ...this.state, selectedRestaurantId: restaurant.restaurantId });

        // If component not rendered from pwa
        if (!this.props.renderInPwa) {
            this.props.saveSelectedRestaurantData(restaurant, () => {
                //TODO programatically open the new screen menu screen
                this.props.history.push('/start-order/order-time');
                // this.props.history.push('/menu-screen');
            });
        } else {
            let brandId = this.props.brand.id;

            this.props.saveSelectedRestaurantData(restaurant, () => {
                this.props.history.push('/brand/' + brandId + '/start-order/order-time');
            });
        }

    }

    renderRestaurantsList() {
        const { nameDistanceContainer, nameDistanceContainerRTL, restaurantDistanceStyle, restaurantDistanceStyleRTL, restaurantDistanceStyleInactive, restaurantDistanceStyleInactiveRTL, restaurantName,restaurantNameRTL, restaurantAddressStyle, pointerEvents, locationIcon, locationIconRTL, closedRestaurantNoFutureOrders } = styles;
        const { primaryColor, mainTextColor, inactiveColor, fontWeight } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        // Sets working restaurants counter
        setOpenedRestCount(this.props.restaurantsInZone);
        return (
            <ul className="restaurantsSelectionScreenUl">
                {
                    this.props.restaurantsInZone.map((restaurant, i) => {

                        let restaurantAddress = restaurant.displayAddress;//this.prepareAddress(restaurant);
                        let restaurantDistance = `${restaurant.distance}`;

                        // Restaurant is open/working for the current date and time
                        const restaurantIsOpen = getRestaurantStatus(restaurant);

                        const noOnlineOrdersText = restaurant.openingHourText === i18n.t('screens:savedCustomerAddressesScreen.closed')

                        const restaurantStatusColor = (restaurantIsOpen && !noOnlineOrdersText) ? primaryColor : mainTextColor;
                        const closedRestaurantDistance = !restaurant.posOnline ? (restaurant.futureOrdersDisabled ? isArabic ? restaurantDistanceStyleInactiveRTL : restaurantDistanceStyleInactive : isArabic ? restaurantDistanceStyleRTL : restaurantDistanceStyle) : (!restaurantIsOpen ? isArabic ? restaurantDistanceStyleInactiveRTL : restaurantDistanceStyleInactive : isArabic ? restaurantDistanceStyleRTL : restaurantDistanceStyle);
                        const isNameLonger = (restaurant.name.length > 34) ? 'restaurantLongerName' : '';

                        let restaurantBadge = getRestaurantStatusBadge(restaurant.badge);

                        // Styles based on restaurant status badge
                        const colors = { primaryColor: primaryColor, inactiveColor: inactiveColor };
                        const badgeStyles = getRestaurantStylesBasedOnStatusBadge(restaurantBadge, colors);

                        const openingHourText = restaurant.openingHourText;
                        const locationIconColor = badgeStyles.locationIconColor;
                        const liClassName = badgeStyles.liClassName;
                        const closedRestaurant = badgeStyles.closedRestaurant;

                        return (
                            <li key={`restaurantItem${i}`} className={liClassName} onClick={() => this.selectRestaurant(restaurant)} style={closedRestaurant}>
                                <div style={isArabic ? nameDistanceContainerRTL : nameDistanceContainer}>
                                    <span style={isArabic ? { position: 'relative', left: 25 } : { position: 'relative', right: 25 }}>
                                        <LocationIconSVG width={24} height={24} fill={locationIconColor} style={isArabic ? locationIconRTL: locationIcon} />
                                    </span>
                                    <span style={isArabic ? restaurantNameRTL : restaurantName} className={isNameLonger}>{restaurant.name}</span>
                                    <span hidden={!restaurant.distance} style={closedRestaurantDistance}>{restaurantDistance}</span>
                                </div>
                                <div style={{ textAlign: isArabic ? 'right' : 'left' }}>
                                    <span style={restaurantAddressStyle}>{restaurantAddress}</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ color: restaurantStatusColor, fontSize: 14, fontWeight }}>
                                        {openingHourText}
                                    </span>
                                    <span className="futureOrdersBadge">
                                        {restaurantBadge}
                                    </span>
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        );
    }

    renderModalBody() {
        const { mapStyle, header } = styles;

        let loader = this.renderTransparentBackgroundLoaderContainer();

        let restaurantsList = null;
        if (this.props.restaurantsInZone) restaurantsList = this.renderRestaurantsList();

        let mapContainerStyle = mapStyle;

        const isMobileView = window.outerWidth <= 800;
        if (isMobileView) {
            mapContainerStyle = {
                height: 477,
                width: '97%',
                margin: '0 auto',
                position: 'relative',
                // left: 15,
                overflow: 'hidden',
                border: '5px solid #ffff'
            }
        }

        return (
            <React.Fragment>
                {this.renderModalHeader(false)}
                <Row className='startOrderSelectRestaurantContainer'>
                    <Col xs={12} sm={12} md={6} style={{ marginTop: 60, paddingLeft: 25, zIndex: 100 }} className="restaurantsListContainer">
                        <h5 className="text-center start-order-body-header-title restaurantSelectionScreen" style={header}>
                            {i18n.t('screens:restaurantSelectionScreen.header')}
                        </h5>
                        {restaurantsList}
                        {loader}
                    </Col>
                    <Col id="restaurantSelectionMap" xs={12} sm={12} md={6} className="mapContainer">
                        <div id="restaurantsMap" style={mapContainerStyle}></div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingContainerTransparent, loadingIndicator } = styles;
        const { primaryColor } = this.props.customerThemes.selectedTheme;

        if (this.props.loading.transparentLoader) {
            return (
                <div style={loadingContainerTransparent}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={primaryColor} size={10} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderNoRestaurantsMessage() {

        const { backButton, closeButton, spanStyle, noContentMessage } = styles;
        const isMobileView = window.outerWidth <= 800;
        const { type, secondaryColor, primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase, primaryColor } = this.props.customerThemes.selectedTheme;
        const { selectedOrderType } = this.props;

        let margin = 0;
        if (isMobileView) margin = 60;

        const titleFontStyle = {
            fontSize: 22,
            paddingLeft: 18,
            paddingTop: 20,
            width: 'auto',
            color: primaryFontColor,
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        return (
            <React.Fragment>
                {
                    this.renderModalHeader(true)
                }
                <div className='modal-body-custom' style={{ marginTop: 57 }}>
                    <Col sm={12} className='modal-custom-body-title' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        {
                            selectedOrderType.id === COLLECTION_ID ?
                                <ShoppingCartSVG width={27} height={27} fill={primaryColor} style={{ marginBottom: 2 }} /> :
                                <CurbsideSVG width={60} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} />
                        }
                        <p className="text-center start-order-body-header-title" style={titleFontStyle}>
                            {selectedOrderType.id === COLLECTION_ID ? i18n.t('screens:selectOrderTypeScreen.collection') : i18n.t('screens:selectOrderTypeScreen.curbside')}
                        </p>
                    </Col>
                    <Col xs={12} className='modal-custom-body-inner-content'>
                        <p className="text-center" style={noContentMessage}>
                            {selectedOrderType.id === COLLECTION_ID ? i18n.t('screens:restaurantSelectionScreen.noContentMessage') : i18n.t('screens:restaurantSelectionScreen.noContentMessageCurbside')}
                        </p>
                    </Col>
                </div>
            </React.Fragment>
        );
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, secondaryColor, startOrderFontSize, mainTextColor, primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        customizedStyles.header.fontSize = startOrderFontSize;
        customizedStyles.restaurantDistanceStyleInactive.color = secondaryColor;
        customizedStyles.restaurantDistanceStyleInactiveRTL.color = secondaryColor;
        customizedStyles.restaurantDistanceStyle.color = primaryColor;
        customizedStyles.restaurantDistanceStyleRTL.color = primaryColor;
        customizedStyles.restaurantAddressStyle.color = mainTextColor;
        customizedStyles.header.color = primaryFontColor;
        customizedStyles.restaurantName.color = primaryFontColor;
        customizedStyles.header.fontFamily = fontFamilyType;
        customizedStyles.header.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.header.fontStyle = fontItalic;
        customizedStyles.header.textTransform = uppercase;
        customizedStyles.restaurantName.fontFamily = fontFamilyType;
        customizedStyles.restaurantName.fontWeight = fontBold === 'bold' ? fontBold : 300;;
        customizedStyles.restaurantName.fontStyle = fontItalic;
        customizedStyles.restaurantName.textTransform = uppercase;
        customizedStyles.profileNameStyle.color = primaryFontColor;
        customizedStyles.profileNameStyleRTL.color = primaryFontColor;
        customizedStyles.noContentMessage.color = primaryFontColor;
        styles = customizedStyles;

        return styles;
    }

    render() {

        const { formStyle } = styles;

        if (this.props.restaurantsInZone && !this.props.restaurantsInZone.length && !this.props.loading.transparentLoader) {

            return (
                <Fragment>
                    <ModalStep
                        isOpen={this.props.isOpen} onToggle={this.props.toggle}
                        // renderHeader={this.renderModalHeader()}
                        renderBody={this.renderNoRestaurantsMessage()}
                        renderFooter={null}
                        styles={styles}
                        customerThemes={this.props.customerThemes}
                    />
                </Fragment>
            )
        }

        return (
            <AvForm fluid style={formStyle} className="selectRestaurantForm">
                <ModalStep
                    isOpen={this.props.isOpen} onToggle={this.props.toggle}
                    // renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    renderFooter={null}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </AvForm>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        customer: state.customer,
        brand: state.brand,
        userAddress: state.customer.selectedDeliveryAddress,
        restaurantsInZone: state.restaurantsInZone,
        customerThemes: state.customerThemes,
        selectedOrderType: state.orderTypes.selectedOrderType,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, { loadRestaurantsInZone, saveSelectedRestaurantData, saveCustomerDeliveryAddress, activateLoader, stopLoader })(RestaurantSelectionScreen);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    profileNameStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    noContentMessage: {
        paddingTop: 12,
        paddingBottom: 30
    },
    formStyle: {
        height: 400
    },
    mapStyle: {
        height: 477
    },
    modalStyle: {
        display: 'none'
    },
    backButton: {
        marginTop: 10,
        marginBottom: 10,
        position: 'relative',
        top: 6,
        left: 1
    },
    closeButton: {
        lineHeight: 36,
        zIndex: 10,
        height: 40,
        width: 40,
        borderRadius: 50,
        border: '1px solid #000',
        position: 'absolute',
        top: 17,
        right: 12,
        backgroundColor: 'rgba(255, 255, 255, 0.53)',
        opacity: '0.5'
    },
    closedRestaurantNoFutureOrders: {
        border: '1px solid #9a9a9a',
        pointerEvents: 'none'
    },
    spanStyle: {
        top: '-950%',
        position: 'absolute',
        right: 13,
        color: '#000',
        zIndex: 10,
        fontSize: 20,
        // fontWeight: 100,
        fontFamily: 'sans-serif'
    },
    nameDistanceContainer: {
        clear: 'right',
        textAlign: 'left'
    },
    nameDistanceContainerRTL: {
        clear: 'right',
        textAlign: 'right'
    },
    restaurantDistanceStyle: {
        float: 'right',
        color: MAIN_GREEN_COLOR
    },
    restaurantDistanceStyleRTL: {
        float: 'left',
        color: MAIN_GREEN_COLOR
    },
    restaurantAddressStyle: {
        color: MAIN_LIGHT_GRAY_COLOR,
        fontSize: 14,
        // fontWeight: 100
    },
    restaurantName: {
        position: 'relative',
        right: '27px',
        color: 'rgb(255, 167, 0)',
        fontFamily: 'Anton',
        fontWeight: 300,
        fontStyle: 'normal',
        textTransform: 'none'
    },
    restaurantNameRTL: {
        position: 'relative',
        left: '27px',
        color: 'rgb(255, 167, 0)',
        fontFamily: 'Anton',
        fontWeight: 300,
        fontStyle: 'normal',
        textTransform: 'none'
    },
    restaurantDistanceStyleInactive: {
        float: 'right',
        color: '#9a9a9a'
    },
    restaurantDistanceStyleInactiveRTL: {
        float: 'left',
        color: '#9a9a9a'
    },
    pointerEvents: {
        border: '1px solid #9a9a9a'
    },
    locationIcon: {
        position: 'relative',
        marginRight: 10
    },
    locationIconRTL: {
        position: 'relative',
        marginLeft: 10
    },
    loadingContainerTransparent: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        // height: '100%', // The modal dialog becomes really big for a second, before loading the restaurants for collection, if the value is 100%
        // color: '#ffffff',
        textAlign: 'center',
        verticalAlign: 'middle',
        // background: `url(${backgroundImgSrc})`,
        background: '#FFFFFF',
        zIndex: 100000000000000000000000000000000000000000000000000000000000 // TODO Ask Vasko T. why this index is with that value
    },
    header: {},
    imageStyle: {
        marginRight: 16
    },
    imageStyleRTL: {
        marginLeft: 16
    }
};