import axios from '../../../utils/AxiosUtils';

import {
    GET_ALL_PAYMENT_PROVIDERS_SUCCESS,
    SAVE_TRANSACTION_ID,
    MOLLIE_RESPONSE_STATUS,
    AUTHIPAY_RESPONSE_STATUS,
    AUTHIPAY_DATA,
    CLICK_APPLE_GOOGLE_PAY,
    BAMBORA_DATA,
    CLEAR_BAMBORA_DATA,
    WORLDPAY_DATA,
    CLEAR_WORLDPAY_DATA,
    MONERIS_DATA,
    CLEAR_MONERIS_DATA
} from '../../types';
import { placeOrder, errorProcessingPayment, sendEmailReport } from '../ShoppingCartActions';
import { changeAuthipayBackBtnFlag } from '../../StoreLocalStorageActions';

export const getPaymentProvider = (restaurantId) => {
    return (dispatch, getState) => {

        const url = `/restaurant/${restaurantId}/payment-provider`;

        axios(getState).get(url)
            .then(res => getPaymentProviderSuccess(res, dispatch))
            .catch(err => console.log('err ', err));
    }
};

const getPaymentProviderSuccess = (res, dispatch) => {

    dispatch({ type: GET_ALL_PAYMENT_PROVIDERS_SUCCESS, payload: res.data });
};

export const dispatchTransactionId = (transactionId, providerId, providerData, timeOutInterval) => {
    return (dispatch, getState) => {

        let providerStatus = MOLLIE_RESPONSE_STATUS;
        let urlPath = 'payment/status';

        if (providerId === 4) {
            providerStatus = AUTHIPAY_RESPONSE_STATUS;
            urlPath = 'payment-status';
        }

        paymentStatusPoll(() => {
            const url = `/${urlPath}?paymentId=${transactionId}`;

            axios(getState).get(url)
                .then(res => {
                    dispatch({ type: providerStatus, payload: providerId === 4 ? res.data : res.data.status });
                })
                .catch(err => sendEmailReport(`${getState().selectedRestaurant.restaurantId}-paymentProvider-${providerId}`, err, 'statusPolling'));

        }, getState, 3000, dispatch, providerId, providerData, timeOutInterval);
        if (providerId === 3) {
            dispatch({ type: SAVE_TRANSACTION_ID, payload: transactionId });
        }
    }
};

const paymentStatusPoll = (fn, getState, interval, dispatch, providerId, providerData, timeOutInterval) => {
    let endCondition = getState().paymentProvider.molliePaymentStatus//Number(new Date()) + (timeout || 2000);
    if (providerId === 4) {
        endCondition = getState().paymentProvider.authipayPaymentStatus;
    }
    const intervalMins = timeOutInterval ? timeOutInterval : 60;
    const timedOutConditon = Number(new Date()) + (1000 * intervalMins * 1);
    interval = interval || 100;

    const checkCondition = function (resolve, reject) {
        endCondition = getState().paymentProvider.molliePaymentStatus;
        const conditionData = checkConditionsAccordingToProvider(providerId, getState, providerData);

        if (providerId === 4) {

            endCondition = getState().paymentProvider.authipayPaymentStatus ? getState().paymentProvider.authipayPaymentStatus.status : null;
        }
        // If the condition is met, we're done! 
        const result = fn();

        if (result) {
            // TODO this will always be undefined maybe there is a better way to do it - should research!!
            resolve(result);
        } else if (new Date() > timedOutConditon) {
            dispatch(errorProcessingPayment('timedOut', conditionData.errOrigin, providerId));
            if (providerId === 4) {
                dispatch(sendEmailReport(getState().selectedRestaurant.restaurantId, endCondition, `TIMEDOUT-paymentProvider-${providerId}`));
                dispatch(changeAuthipayBackBtnFlag(true));
            }
            return resolve();
        }
        // If the condition isn't met but the timeout hasn't elapsed, go again
        else if (endCondition === 'OPEN' || endCondition === 'PENDING' || endCondition === 'WAITING' || !endCondition) {

            setTimeout(checkCondition, interval, resolve, reject);
        } else if (endCondition === conditionData.successCondition) {
            if (providerId === 4) {
                dispatch(changeAuthipayBackBtnFlag(true));
            }
            let secondPlaceOrderParam = providerId === 4 ? providerData : { paymentId: conditionData.paymentId };
            dispatch(placeOrder(false, secondPlaceOrderParam));

            return resolve();
        } else if (endCondition === 'FAILED' || endCondition === 'CANCELED' || endCondition === 'EXPIRED' || endCondition === 'DECLINED'
            || endCondition === 'VALIDATION_FAILED' || endCondition === 'PROCESSING_FAILED') {

            if (providerId === 4) {
                dispatch(changeAuthipayBackBtnFlag(true));
            }
            let secondParam = providerId === 4 ? getState().paymentProvider.authipayPaymentStatus.reason : conditionData.errOrigin;
            dispatch(errorProcessingPayment(endCondition, secondParam, providerId));
            dispatch(sendEmailReport(getState().selectedRestaurant.restaurantId, endCondition, `FAILED-paymentProvider-${providerId}`));
            return resolve();
        }
        // Didn't match and too much time, reject!
        else {

            reject(new Error('Poll rejected ' + fn + ': ' + arguments));
        }
    };

    return new Promise(checkCondition);
};

const checkConditionsAccordingToProvider = (providerId, getState) => {

    let returnData = null;
    if (providerId === 3) {
        returnData = {
            successCondition: 'PAID',
            paymentId: getState().paymentProvider.mollieTransactionId,
            errOrigin: 'mollieCheckoutResponse'
        }
    } else if (providerId === 4) {
        returnData = {
            successCondition: 'APPROVED',
            paymentId: getState().paymentProvider.authipayData.orderId,
            errOrigin: 'authipayCheckoutResponse'
        }
    }

    return returnData;
};

export const saveAuthipayData = (providerData) => {
    return (dispatch) => {

        dispatch({ type: AUTHIPAY_DATA, payload: providerData });
    }
};

export const saveWorldPayData = (providerData) => {
    return (dispatch) => {
        dispatch({ type: WORLDPAY_DATA, payload: providerData });
    }
}

export const clearWorldPayData = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_WORLDPAY_DATA });
    }
}

export const saveBamboraData = (providerData) => {
    return (dispatch) => {
        dispatch({ type: BAMBORA_DATA, payload: providerData })
    }
}

export const clearBamboraData = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_BAMBORA_DATA })
    }
}

export const saveMonerisData = (providerData) => {
    return (dispatch) => {
        dispatch({ type: MONERIS_DATA, payload: providerData });
    }
}

export const clearMonerisData = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_MONERIS_DATA });
    }
}

export const clickAppleGooglePay = () => {
    return (dispatch) => {

        dispatch({ type: CLICK_APPLE_GOOGLE_PAY });
    }
};