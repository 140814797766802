import _ from 'lodash';

import {
    ADD_OR_SUBSTRACT_QTY_TO_CART,
    REMOVE_ITEM_FROM_CART,
    INCREASE_OR_DECREASE_TIP,
    ADD_PRODUCT_TO_CART,
    SAVE_GENERAL_INSTRUCTIONS,
    CLEAR_SHOPPING_CART, CLEAR_STATE,
    APPLY_PROMOTION_SUCCESS,
    SHOW_ERROR_MESSAGE,
    DISPATCH_PROMO_CODE,
    DISPATCH_SERVICE_FEE,
    CLEAR_APP_STATE,
    INCREASE_OR_DECREASE_PROMO_SERVICE_CALLS_COUNT,
    APPLY_PROMO_CODE,
    RESET_OLD_DISCOUNT_FLAG
} from '../../actions/types';

const INITIAL_STATE = {
    orderProducts: new Array,
    subtotal: 0,
    deliveryFee: 0,
    generalInstruction: '',
    tip: 0,
    bonusTip: 0,
    total: 0,
    orderType: null,
    promoCode: null,
    appliedPromotions: new Array,
    freeDelivery: false,
    errorMessage: null,
    discount: 0,
    vat: 0,
    serviceFee: 0,
    feeObject: {},
    deposits: 0,
    promoCallsCount: 0
};

export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case DISPATCH_PROMO_CODE:

            return { ...state, promoCode: action.payload };
        case APPLY_PROMOTION_SUCCESS:
            
            const { payload } = action;
            return {
                ...state,
                freeDelivery: payload.freeDelivery,
                appliedPromotions: payload.appliedPromotions,
                promoCode: payload.promoCode,
                orderProducts: payload.orderProducts
            }
        case SHOW_ERROR_MESSAGE:

            return { ...state, errorMessage: action.payload };
        case ADD_OR_SUBSTRACT_QTY_TO_CART:

            return action.payload;
        case REMOVE_ITEM_FROM_CART:

            return action.payload;
        case INCREASE_OR_DECREASE_TIP:

            return { ...state, bonusTip: action.payload };
        case SAVE_GENERAL_INSTRUCTIONS:

            return { ...state, generalInstruction: action.payload };
        case ADD_PRODUCT_TO_CART:

            return { ...state, orderProducts: action.payload };
        case DISPATCH_SERVICE_FEE:

            return { ...state, serviceFee: action.payload.amount, feeObject: action.payload };
        case INCREASE_OR_DECREASE_PROMO_SERVICE_CALLS_COUNT:

            return {
                ...state,
                promoCallsCount: action.payload
            };
        case APPLY_PROMO_CODE:

            return {
                ...state,
                promoCode: action.payload
            };
        case RESET_OLD_DISCOUNT_FLAG:
            
            return {
                ...state,
                discount: 0
            };
        case CLEAR_SHOPPING_CART:

            return INITIAL_STATE;
        case CLEAR_STATE:

            return INITIAL_STATE;
        case CLEAR_APP_STATE:

            return INITIAL_STATE;
        default:
            return state;
    }
}