import {
    CLEAR_APP_STATE, CLEAR_STATE,
    GET_ALL_PAYMENT_PROVIDERS_SUCCESS,
    SAVE_TRANSACTION_ID,
    MOLLIE_RESPONSE_STATUS,
    AUTHIPAY_RESPONSE_STATUS,
    AUTHIPAY_DATA,
    CLICK_APPLE_GOOGLE_PAY,
    BAMBORA_DATA,
    CLEAR_BAMBORA_DATA,
    WORLDPAY_DATA,
    CLEAR_WORLDPAY_DATA,
    MONERIS_DATA,
    CLEAR_MONERIS_DATA
} from '../../../actions/types';

const INITIAL_STATE = {
    providerType: {},
    stripePremenuData: {}, // if Stripe
    monerisPremenuData: {},
    mollieTransactionId: null,
    molliePaymentStatus: null,
    authipayPaymentStatus: null,
    authipayData: {
        cardId: null,
        hash: null,
        orderId: null,
        storeId: null,
        textDate: null,
        timeZone: null
    },
    bamboraData: null,
    worldPayData: {
        verificationCode: null,
        redirectUrl: null
    },
    monerisData: null,
    isAppleGooglePayClicked: false
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_PAYMENT_PROVIDERS_SUCCESS:
            return {
                ...state,
                providerType: payload.providerType,
                stripePremenuData: payload.stripePremenuData,
                monerisPremenuData: payload.monerisPremenuData
            };
        case SAVE_TRANSACTION_ID:
            return {
                ...state,
                mollieTransactionId: payload
            };
        case MOLLIE_RESPONSE_STATUS:
            return {
                ...state,
                molliePaymentStatus: payload
            };
        case AUTHIPAY_DATA:
            return {
                ...state,
                authipayData: payload
            };
        case AUTHIPAY_RESPONSE_STATUS:
            return {
                ...state,
                authipayPaymentStatus: payload
            };
        case BAMBORA_DATA:
            return {
                ...state,
                bamboraData: payload
            };
        case CLEAR_BAMBORA_DATA:
            return {
                ...state,
                bamboraData: null
            };
        case MONERIS_DATA:
            return {
                ...state,
                monerisData: payload
            }
        case CLEAR_MONERIS_DATA:
            return {
                ...state,
                monerisData: null
            }
        case CLICK_APPLE_GOOGLE_PAY:
            return {
                ...state,
                isAppleGooglePayClicked: !state.isAppleGooglePayClicked
            }
        case WORLDPAY_DATA:
            return {
                ...state,
                worldPayData: payload
            };
        case CLEAR_WORLDPAY_DATA:
            return {
                ...state,
                worldPayData: {
                    verificationCode: null,
                    redirectUrl: null
                }
            }
        case CLEAR_APP_STATE:
        case CLEAR_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}