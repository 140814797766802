import {
    CLEAR_ERROR_DIALOG,
    LOADING_NO_BACKGROUND,
    SHOW_SUCCESS_ALERT_MESSAGE,
    CLEAR_SUCCESS_ALERT_MESSAGE,
    SHOW_ERROR_ALERT_MESSAGE,
    CLEAR_ERROR_ALERT_MESSAGE,
    RESET_ALERTS_STATE,
    SAVE_CURRENT_PATH_TO_STORE,
    CLEAR_CURRENT_PATH_AFTER_USE,
    GET_CURRENT_THEME_SUCCESS,
    CLOSE_SENDING_ORDER_MODAL,
    ALLOW_TIME_WARNING,
    DISABLE_TIME_WARNING,
    CHANGE_WANTED_TIME,
    TOGGLE_UNAVAILABLE_PRODUCT_SCREEN,
    TOGGLE_SEARCH_PRODUCT_FOCUS,
    TOGGLE_PROMOTIONS_MODAL,
    TOGGLE_SHOPPING_CART_MODAL,
    CLEAR_CART_AND_PROMO_MODALS
} from "./types";

export const showMessageAlert = (type) => {
    return (dispatch) => {

        if (type === 'success') {
            dispatch({ type: SHOW_SUCCESS_ALERT_MESSAGE, payload: true });
        } else if (type === 'error') {
            dispatch({ type: SHOW_ERROR_ALERT_MESSAGE, payload: true });
        }
    };
};

export const clearMessageAlert = (type) => {
    return (dispatch) => {

        if (type === 'success') {
            dispatch({ type: CLEAR_SUCCESS_ALERT_MESSAGE, payload: true });
        } else if (type === 'error') {
            dispatch({ type: CLEAR_ERROR_ALERT_MESSAGE, payload: true });
        }
    };
};

export const resetMessageAlert = () => {
    return (dispatch) => {

        dispatch({ type: RESET_ALERTS_STATE, payload: true });
    };
};

export const clearErrorMessage = () => {
    return (dispatch) => {

        dispatch({ type: CLEAR_ERROR_DIALOG, payload: false });
    };
};

export const activateLoader = () => {
    return (dispatch) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });
    };
};

export const stopLoader = () => {
    return (dispatch) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
    };
};

export const saveCurrentPathToStore = (currentScreen) => {
    return (dispatch) => {

        dispatch({ type: SAVE_CURRENT_PATH_TO_STORE, payload: currentScreen });
    };
};

export const clearCurrentPathAfterUse = () => {
    return (dispatch) => {

        dispatch({ type: CLEAR_CURRENT_PATH_AFTER_USE });
    };
};

export const clearLastUsedPagePathAfterTimeStamp = () => {
    return (dispatch) => {
        // clears the store property path for refreshing the page after 30 min - multiplied by 1000 because it needs milliseconds value
        setInterval(function () {
            dispatch({ type: CLEAR_CURRENT_PATH_AFTER_USE });
        }, (60 * 60 * 3 * 1000));
    };
};

export const saveThemePreviewMode = (currentTheme) => {
    return (dispatch) => {

        dispatch({ type: GET_CURRENT_THEME_SUCCESS, payload: currentTheme });
    };
};

export const resetSendingOrderModal = () => {
    return (dispatch) => {
        dispatch({ type: CLOSE_SENDING_ORDER_MODAL });
    };
};

export const allowTimeWarningPopup = (isTimeWarningAllowed) => {
    return (dispatch) => {
        if (isTimeWarningAllowed) {
            dispatch({ type: ALLOW_TIME_WARNING });
        } else {
            dispatch({ type: DISABLE_TIME_WARNING });
        }
    };
};

export const changeWantedTime = (newWantedTime) => {
    return (dispatch) => {
        dispatch({ type: CHANGE_WANTED_TIME, payload: newWantedTime })
    };
};

export const toggleUnavailableProductsScreenOpen = () => {
    return (dispatch) => {
        dispatch({ type: TOGGLE_UNAVAILABLE_PRODUCT_SCREEN })
    };
};

export const toggleSearchProductFocus = (focusFlag) => {
    return (dispatch) => {
        dispatch({ type: TOGGLE_SEARCH_PRODUCT_FOCUS, payload: focusFlag })
    };
};

export const togglePromotionsModal = () => {
    return (dispatch) => {
        dispatch({ type: TOGGLE_PROMOTIONS_MODAL })
    };
};

export const toggleShoppingCartModal = () => {
    return (dispatch) => {
        dispatch({ type: TOGGLE_SHOPPING_CART_MODAL })
    };
};

export const clearCartAndPromoModals = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_CART_AND_PROMO_MODALS })
    };
};
